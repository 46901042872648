import React from 'react'

import {
  Text,
  VStack,
  HStack,
  Divider,
  useDisclosure,
  Collapse,
  Button,
  Stack,
  Skeleton,
  Box,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { IStoreState } from 'src/bootstrap/store/types'
import { Panel, PanelBody } from 'src/core/components'

import DashboardHeader from './DashboardHeader'
import { useFetch } from 'src/core/hooks'
import {
  salesOfficerService,
  SalesOfficerServiceProps,
} from 'src/applets/salesOfficer'
import { FiChevronRight } from 'react-icons/fi'
import { formatCurrency } from 'src/core/helpers'
import { BuyersList } from 'src/applets/buyer/components'
import { colors } from 'src/bootstrap/theme/colors'

enum MetricType {
  GMV = 'gmv',
  ACTIVE_RETAILERS = 'active_retailers',
  NEW_ACTIVE_RETAILERS = 'new_active_retailers',
  MONIES_EARNED = 'monies_earned',
}
enum MetricUnit {
  COUNT = 'count',
  VALUE = 'value',
}

const BrandAmbassadorDashboard: React.FC = () => {
  const { user } = useSelector((state: IStoreState) => ({
    user: state.user,
    pendingOrders: state.global.pendingOrders,
  }))

  const { isOpen: showMetric, onToggle: toggleMetric } = useDisclosure({
    defaultIsOpen: true,
  })

  const [details, isLoading] = useFetch<any, SalesOfficerServiceProps>(
    salesOfficerService,
    'fetchDetails',
    [user?._id]
  )

  const formatMetric = React.useCallback(
    (metricKey: MetricType, metricUnit: MetricUnit): any => {
      return formatCurrency(
        details?.ba_mtd?.summary?.[metricKey]?.[metricUnit],
        {
          notation: 'compact',
          decimals: 0,
          showCurrency: false,
        }
      )
    },
    [details]
  )

  const MetricItem: React.FC<{
    label: string
    metric: MetricType
    unit: MetricUnit
  }> = ({ label, metric, unit }) => {
    return (
      <>
        <HStack justify="space-between">
          <Text size="14px" color="gray.600">
            {label}
          </Text>
          <Text size="14px">{formatMetric(metric, unit)}</Text>
        </HStack>
      </>
    )
  }

  return (
    <>
      <DashboardHeader user={user} />

      <Panel border={0} mb="8px">
        <PanelBody p="16px">
          <Panel borderRadius="md">
            <PanelBody>
              <Button
                px={0}
                fontSize="15px"
                isFullWidth
                variant="ghost"
                iconSpacing="auto"
                onClick={toggleMetric}
                rightIcon={
                  <StatToggleButton
                    isOpen={showMetric}
                    onClick={toggleMetric}
                  />
                }
                _focus={{ outline: 'none', boxShadow: 'none' }}
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
              >
                This Month
              </Button>

              <Collapse in={showMetric} animateOpacity>
                <Box pt={3}>
                  {isLoading ? (
                    <Stack>
                      <Skeleton height="16px" />
                      <Skeleton height="16px" />
                      <Skeleton height="16px" />
                    </Stack>
                  ) : (
                    <VStack align="stretch" divider={<Divider />}>
                      <MetricItem
                        label="GMV (NGN)"
                        metric={MetricType.GMV}
                        unit={MetricUnit.VALUE}
                      />
                      <MetricItem
                        label="Active Retailers"
                        metric={MetricType.ACTIVE_RETAILERS}
                        unit={MetricUnit.COUNT}
                      />
                      <MetricItem
                        label="New Active Retailers"
                        metric={MetricType.NEW_ACTIVE_RETAILERS}
                        unit={MetricUnit.COUNT}
                      />
                      {details?.ba_mtd?.summary?.[MetricType.MONIES_EARNED]
                        ?.value > 0 && (
                        <MetricItem
                          label="My Earnings (NGN)"
                          metric={MetricType.MONIES_EARNED}
                          unit={MetricUnit.VALUE}
                        />
                      )}
                    </VStack>
                  )}
                </Box>
              </Collapse>
            </PanelBody>
          </Panel>
        </PanelBody>
      </Panel>

      <BuyersList routeId="dashboard" />
    </>
  )
}

const StatToggleButton: React.FC<{ isOpen: boolean; onClick: VoidFunction }> = (
  props
) => {
  return (
    <Box
      transform={props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
      transition="transform 300ms"
    >
      <FiChevronRight size={18} color={colors.gray[400]} />
    </Box>
  )
}

export default BrandAmbassadorDashboard
