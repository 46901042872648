import React from 'react'
import { useDispatch } from 'react-redux'

import { useFetch, usePermission } from 'src/core/hooks'
import { OrderTag } from '../order.type'
import { OrderGroupService } from '../services'
import { SET_GLOBAL } from 'src/bootstrap/store/actionTypes'
import { basicSearch } from 'src/core/helpers/search.helper'
import { beautifyOrderGroup } from 'src/applets/order/order.helper'

const orderGroupService = new OrderGroupService()

const usePendingOrders = (
  type: 'all' | 'sales_officer',
  condition = true,
  id?: string,
  /** Filter by search string */
  search?: string
): any[] => {
  const dispatch = useDispatch()
  const { userCan } = usePermission()

  const method = type === 'all' ? 'fetchByTag' : 'fetchBySalesOfficerTag'

  // fetchBySalesOfficerTag

  const params: any[] =
    type === 'all' ? [OrderTag.Pending] : [id, OrderTag.Pending]

  const [orders, isLoading] = useFetch(
    orderGroupService,
    method,
    params,
    condition && userCan('view_orders')
  )

  React.useEffect(() => {
    if (orders) {
      dispatch({
        type: SET_GLOBAL,
        payload: {
          pendingOrders: orders,
        },
      })
    }
  }, [orders])

  const filteredOrders = React.useMemo(() => {
    let filtered = orders || []

    if (filtered?.length) {
      filtered = filtered.map((order) => {
        return beautifyOrderGroup(order)
      })

      if (search) {
        filtered = filtered.filter((order) => {
          return (
            basicSearch(order?._id, search) ||
            basicSearch(order?.buyer?.name, search) ||
            basicSearch(order?.buyer?.phone, search)
          )
        })
      }
    }

    return filtered
  }, [orders, search])

  return [filteredOrders, isLoading]
}

export default usePendingOrders
