import React from 'react'

import { FormLabel, FormControl } from '@chakra-ui/form-control'
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/input'
import { Select } from '@chakra-ui/select'
import { Textarea } from '@chakra-ui/textarea'

import { Autocomplete } from './Autocomplete'

export interface FormFieldProps {
  as?: 'select' | 'autocomplete' | 'textarea'
  label?: string
  prepend?: string | JSX.Element
  append?: string | JSX.Element
  [value: string]: any
}

export const FormField: React.FC<FormFieldProps> = ({
  as,
  label,
  prepend,
  append,
  children,
  isRequired,
  ...props
}) => {
  const Tag: any =
    as === 'textarea'
      ? Textarea
      : as === 'select'
      ? Select
      : as === 'autocomplete'
      ? Autocomplete
      : Input

  return (
    <>
      {!!label && (
        <FormControl mb={0} isRequired={isRequired}>
          <FormLabel mb={0}>{label}</FormLabel>
        </FormControl>
      )}

      <InputGroup size={props?.size}>
        {!!prepend && <InputLeftAddon>{prepend}</InputLeftAddon>}

        <Tag {...props}>{children}</Tag>

        {!!append && <InputRightAddon>{prepend}</InputRightAddon>}
      </InputGroup>
    </>
  )
}

export default FormField
