import cache from '../../core/helpers/cache.helper'
import { http } from '../../core/helpers/http.helper'
import { formatCurrency } from '../../core/helpers/money.helper'

import { IBuyer } from './buyer.type'
import { IRoutePlan } from 'src/applets/routePlan/routePlan.type'

export class BuyerService {
  /**
   * Compute buyers order: completed (pending + colpleted),
   * cancelled and cart
   *
   * @param buyer
   */
  _computeBuyerOrders(buyer: IBuyer): any {
    const cacheKey = `order_summary_${buyer._id}`
    const cachedData = cache.get(cacheKey)

    if (cachedData) {
      return cachedData
    } else {
      let orderSummary: any = {
        order_count: 0,
        order_value: 0,
        cancelled_count: 0,
        cancelled_value: 0,
        cart_value: 0,
        last_ordered_on: 0,
      }

      if (buyer.order_count) {
        orderSummary.last_ordered_on = buyer.order_count.count.total
          ? buyer.order_count.latest.total
          : 0

        /** Orders (Completed + Pending) */
        orderSummary.order_count =
          buyer.order_group_count.count.completed +
          buyer.order_group_count.count.pending
        orderSummary.order_value =
          buyer.order_count.value.completed + buyer.order_count.value.pending
        orderSummary.order_value =
          buyer.order_count.value.completed + buyer.order_count.value.pending

        if (buyer.order_count.count.cancelled) {
          /** Cancelled orders */
          orderSummary.cancelled_count = buyer.order_count.count.cancelled
          orderSummary.cancelled_value = buyer.order_count.value.cancelled
        }
      }

      if (buyer.order_group_count && buyer.order_group_count.value.draft) {
        orderSummary.cart_value = buyer.order_group_count.value.draft
      }

      orderSummary = {
        ...orderSummary,
        order_value: formatCurrency(orderSummary.order_value),
        cancelled_value: formatCurrency(orderSummary.cancelled_value),
        cart_value: formatCurrency(orderSummary.cart_value),
      }

      cache.set(cacheKey, orderSummary)
      return orderSummary
    }
  }

  fetch(): Promise<IBuyer[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('buyers')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('buyer/read_all').then(({ data }) => {
            if (data.code === 200) {
              const tempBuyers = data.data.map((buyer) => {
                const orderSummary = this._computeBuyerOrders(buyer)

                return {
                  ...buyer,
                  ...orderSummary,
                }
              })

              cache.set('buyers', tempBuyers)
              resolve(tempBuyers)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchBySalesOfficer(salesOfficerId: string): Promise<any> {
    const formData = new FormData()
    formData.append('sales_officer_id', salesOfficerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `buyers_${salesOfficerId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post('buyer/read_by_sales_officer', formData)
            .then(({ data }: any) => {
              if (data.code === 200) {
                let allBuyers: IBuyer[] = data.data.all
                const buyersByDay: any =
                  typeof data.data.routes === 'object' ? data.data.routes : null

                allBuyers = allBuyers.map((buyer) => {
                  return {
                    ...buyer,
                    ...this._computeBuyerOrders(buyer),
                  }
                })

                if (buyersByDay) {
                  Object.entries(buyersByDay).forEach(
                    ([day, routePlans]: [string, IRoutePlan[]]) => {
                      buyersByDay[day] = routePlans.map((routePlan) => {
                        return {
                          ...routePlan.buyer,
                          ...this._computeBuyerOrders(routePlan.buyer),
                        }
                      })
                    }
                  )
                }

                const finalData = { allBuyers, buyersByDay }

                cache.set(cacheKey, finalData)
                resolve(finalData)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchById(buyerId: string): Promise<IBuyer> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get(`buyer_${buyerId}`)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`buyer/read/?_id=${buyerId}`).then(({ data }) => {
            if (data.code === 200 && data.data.length) {
              const tempBuyers = data.data.map((buyer) => {
                const orderSummary = this._computeBuyerOrders(buyer)
                return {
                  ...buyer,
                  ...orderSummary,
                }
              })

              cache.set(`buyer_${buyerId}`, tempBuyers[0])
              resolve(tempBuyers[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  signup(buyer: IBuyer): Promise<IBuyer> {
    const formData = new FormData()
    formData.append('status', 'active') // default
    formData.append('hash', 'password') // default

    Object.keys(buyer).forEach((key) => {
      formData.append(key, buyer[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('buyer/signup', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  update(buyer: IBuyer): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'active') // default

    Object.keys(buyer).forEach((key) => {
      formData.append(key, buyer[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('buyer/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve({})
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }
}

export const buyerService = new BuyerService()
