import React from 'react'

import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { useHistory } from 'react-router'

import { ImageBox } from 'src/core/components'

import { asset } from 'src/core/helpers/file.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'

import { route } from 'src/core/helpers/route.helper'
import { IProduct } from 'src/applets/product/product.type'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { sellerStaff } from 'src/bootstrap/permissions/roles'

interface IProps {
  product: IProduct
  /** Is last item on list */
  isLast?: boolean
}

export const ProductsListProduct: React.FC<IProps> = ({ product }) => {
  const history = useHistory()

  const user = useSelector((state: IStoreState) => state.user)

  const sellerProduct = React.useMemo(() => {
    let tempProduct

    if (sellerStaff.includes(user.role)) {
      tempProduct = product?.sellers?.[user.seller_id]
    }

    return tempProduct || null
  }, [product.sellers, user.seller_id])

  const compoundStatus = React.useMemo(() => {
    let sellerActive = false

    if (sellerStaff.includes(user.role)) {
      /**
       * If user is a seller staff, lets check if the
       * seller's product is active.
       */
      sellerActive = sellerProduct ? sellerProduct?.status === 'active' : false
    } else {
      /**
       * Else for higher users, we just need to check
       * that at least one seller product is active.
       */
      const sellerProducts: any[] = product.sellers
        ? Object.values(product.sellers)
        : []

      sellerActive = sellerProducts.length
        ? sellerProducts.some(({ status }) => status === 'active')
        : false
    }

    const mftrActive = product.manufacturer.status === 'active'
    const brandActive = product.brand.status === 'active'
    const productActive = product.status === 'active'

    return mftrActive && brandActive && sellerActive && productActive
      ? 'active'
      : 'inactive'
  }, [product])

  const openProduct = (): void => {
    history.push(
      route('product', {
        id: product._id,
        tab: 'update',
      })
    )
  }

  return (
    <Flex cursor="default" justifyContent="space-between" onClick={openProduct}>
      <Flex alignItems="center">
        <ImageBox
          src={product?.upload ? asset(product.upload.path) : ''}
          borderWidth={1}
          borderRadius={99999}
        />
        <Box ml={3}>
          <Text fontWeight="medium">{product.name}</Text>
          <Text fontSize="sm" color="gray.600">
            {product.case_count} {pluralize('unit', product.case_count)} / case
          </Text>

          <Text mt={2}>
            NGN {formatCurrency(sellerProduct?.price || product.price)}
          </Text>
        </Box>
      </Flex>

      <Flex alignItems="center">
        <Badge
          ml={2}
          colorScheme={compoundStatus === 'active' ? 'success' : 'critical'}
        >
          {compoundStatus === 'active' ? 'A' : 'I'}
        </Badge>
      </Flex>
    </Flex>
  )
}
