import pluralize from 'pluralize'

import CoreService, {
  CoreServiceProps,
} from '../../../core/services/core.service'
import { IOrder, IOrderItem, OrderTag } from 'src/applets/order/order.type'

export class OrderService<T = IOrder, S = IOrderItem> extends CoreService<T> {
  constructor(model: 'order' | 'order_group' = 'order') {
    super(model)
  }

  /**
   * Add buyer_name and location_name accessors
   * to root, for easier table sorting by column
   **/
  private refineOrders(orders): any[] {
    orders = orders.map((order) => {
      let meta_obj = {}

      try {
        meta_obj = JSON.parse(order.meta)
      } catch (error) {
        meta_obj = {}
      }

      return {
        ...order,
        meta_obj,
        buyer_name: order?.buyer?.name || '',
        location_name: order?.buyer?.location?.name || '',
        amount:
          this.model === 'order_group'
            ? order?.orders?.value?.total || 0
            : order?.amount,
      }
    })

    return orders
  }

  fetch(): Promise<T[]> {
    return new Promise((resolve, reject) => {
      super
        .fetch()
        .then((orders: any) => {
          orders = this.refineOrders(orders)
          resolve(orders)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * Fetch using custom params like timestamp,
   * search and tags.
   */
  fetchCustom(params): Promise<T[]> {
    const formData = new FormData()
    formData.append('timestamp_min', params.start_date)
    formData.append('timestamp_max', params.end_date)
    formData.append('query', params?.query || '*')
    formData.append('tags[]', params?.tags || '*')

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${params.start_date}_${
        params.end_date
      }`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_all_custom`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                const orders = this.refineOrders(data.data)
                this.cache.set(cacheKey, orders)
                resolve(orders)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchItems(orderId: string): Promise<S[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_items_${orderId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .get(
              `${this.model}_item/read_by_${this.model}/?${this.model}_id=${orderId}`
            )
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById = (orderId: string): Promise<T> => {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${orderId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .get(`${this.model}/read/?_id=${orderId}`)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByTag = (tag: OrderTag, updatedAt?: Date): Promise<T[]> => {
    const formData = new FormData()
    formData.append('tag', tag)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${tag}_${updatedAt}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_tag`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByRef = (ref: string): Promise<T> => {
    const formData = new FormData()
    formData.append('ref', ref)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${ref}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_ref/`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByBuyer = (buyerId: string): Promise<T[]> => {
    const formData = new FormData()
    formData.append('buyer_id', buyerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${buyerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`${this.model}/read_by_buyer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByBuyerTag = (buyerId: string, tag: OrderTag): Promise<T[]> => {
    const formData = new FormData()
    formData.append('buyer_id', buyerId)
    formData.append('tag', tag)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${buyerId}_${tag}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_buyer_tag`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchBySalesOfficerTag = (
    salesOfficerId: string,
    tag: OrderTag
  ): Promise<any[]> => {
    const formData = new FormData()
    formData.append('from_timestamp', '0')
    formData.append('sales_officer_id', salesOfficerId)
    formData.append('tag', tag)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${salesOfficerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`${this.model}/read_by_sales_officer_tag`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                /** Orders grouped by buyers */
                let order_groups: any[] = []

                if (typeof data.data === 'object') {
                  order_groups = Object.values(data.data)
                }

                this.cache.set(cacheKey, order_groups)
                resolve(order_groups)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  historyBySalesOfficer = (salesOfficerId: string): Promise<any[]> => {
    const formData = new FormData()
    formData.append('sales_officer_id', salesOfficerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `order_history_${salesOfficerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post('order/history_by_sales_officer', formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  createItem(payload: S): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      // formData.append('amount', item.amount.toString())
      // formData.append('quantity', item.quantity.toString())
      // formData.append('product_id', item.product_id)
      // formData.append('order_id', item.order_id)

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key])
      })

      try {
        this.http
          .post(`${this.model}_item/create`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve(data.data) // data.data is item._id
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  updateProp<T = any>(
    prop: 'tag' | 'meta' | 'amount' | 'sales_officer',
    payload: T
  ): Promise<void> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}/update_${prop}`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Loop over an array of orders and update
   * their tag
   *
   * @param {array} orders
   * @param {string} newTag
   */
  updateTagBulk(orders: IOrder[], newTag: OrderTag): Promise<void> {
    return new Promise((resolve, reject) => {
      orders.forEach((order) => {
        Promise.all([
          this.updateProp('tag', {
            _id: order._id,
            old_tag: order.tag,
            new_tag: newTag,
            sales_officer_id: order.sales_officer_id,
            meta: order.meta,
          }),
        ])
          .then(() => resolve())
          .catch(() => reject({ message: 'An unexpected error occured!' }))
      })
    })
  }

  updateItem(payload: S): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key])
      })

      try {
        this.http
          .post(`${this.model}_item/update`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  deleteItem(details: { _id: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('_id', details._id)

      try {
        this.http
          .post(`${this.model}_item/delete`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  upsertItem(payload: S): Promise<any> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}_item/upsert`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  removeItem(details: { _id: string }): Promise<void> {
    const formData = new FormData()
    formData.append('_id', details._id)

    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(`${this.model}_item/remove`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              this.cache.reset()
              resolve()
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Fetch order fulfilment [manufacturer ==> seller]
   * by buyer location
   */
  fetchRouteFulfilment(locationId: string): Promise<any> {
    const formData = new FormData()
    formData.append('location_id', locationId)

    return new Promise((resolve, reject) => {
      const cacheKey = `fulfilment_${locationId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post('order/route_fulfilment_all', formData)
            .then(({ data }) => {
              if (data.code === 200) {
                const fulfilment_array: string[] = Object.values(data.data)
                const fulfilment_obj = {}

                if (fulfilment_array.length) {
                  fulfilment_array
                    .filter((item: any) => item.found)
                    .forEach((item: any) => {
                      // return an array of just maunfacturer_id
                      // return fulfilment.manufacturer_id
                      fulfilment_obj[item.manufacturer_id] = item
                    })
                  this.cache.set(cacheKey, fulfilment_obj)
                  resolve(fulfilment_obj)
                }

                reject({ message: 'Route fulfilment unavailable' })
              } else reject({ message: 'Route fulfilment unavailable' })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  private refineMatrix(data): any {
    const matrix_fulfilments = data
    const fulfilments_array = []

    Object.entries(matrix_fulfilments).forEach((item) => {
      const key = item[0]
      const fulfilment: any = item[1]

      fulfilment.key = key

      const manufacturers_array = []
      Object.entries(fulfilment.manufacturers).forEach((item) => {
        const key = item[0]
        const manufacturer: any = item[1]

        manufacturer.key = key
        manufacturers_array.push(manufacturer)
      })

      fulfilment.manufacturers = manufacturers_array
      fulfilments_array.push(fulfilment)
    })

    return {
      columns: fulfilments_array[0].manufacturers,
      data: fulfilments_array,
    }
  }

  fetchMatrixFulfilment(buyers?: boolean): Promise<any> {
    const endpoint = !buyers ? 'matrix_fulfilment' : 'matrix_fulfilment_buyers'
    return new Promise((resolve, reject) => {
      const cacheKey = endpoint
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http.get(`order/${endpoint}`).then(({ data }) => {
            if (data.code === 200) {
              const matrix_data = Object.keys(data.data)

              if (matrix_data.length) {
                const refinedData = this.refineMatrix(data.data)
                this.cache.set(cacheKey, refinedData)
                resolve(refinedData)
              } else reject()
            } else reject(data)
          })
        } catch (error) {
          reject(error)
          throw error
        }
      }
    })
  }
}

export default OrderService
export const orderService = new OrderService()
export type OrderServiceProps = CoreServiceProps | keyof OrderService
