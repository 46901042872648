import { Box, BoxProps } from '@chakra-ui/layout'
import {
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/system'
import React from 'react'

export const Panel: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props

  const styles = useMultiStyleConfig('Panel', {})
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles.panel} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

export const PanelHeader: React.FC<BoxProps> = (props) => {
  const styles = useStyles()
  return <Box __css={styles.header} {...props} />
}

export const PanelBody: React.FC<BoxProps> = (props) => {
  const styles = useStyles()
  return <Box __css={styles.body} {...props} />
}
