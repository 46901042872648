import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ChakraProvider } from '@chakra-ui/react'

import { store, persistor } from './bootstrap/store'
import { theme } from './bootstrap/theme'
import { routes } from './bootstrap/routes'
import * as serviceWorker from './serviceWorker'
import App from './bootstrap/App'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading="Loading..." persistor={persistor}>
      <ChakraProvider theme={theme}>
        <App routes={routes} />
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register({
  onSuccess: () => {
    console.log('Service worker registered.')
    // store.dispatch({ type: SW_INIT })
  },
  onUpdate: () => {
    window.location.reload()
    // store.dispatch({ type: SW_UPDATE, payload: registration })
  },
})
