import React from 'react'

const defaultOptions = {
  preventDefault: true,
  delay: 300,
}

export const useLongPress = (
  onLongPress,
  onClick,
  options = defaultOptions
): any => {
  const [longPressTriggered, setLongPressTriggered] = React.useState(false)
  const target = React.useRef<any>()
  const timeout = React.useRef<any>()

  const start = React.useCallback(
    (event) => {
      if (options.preventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        setLongPressTriggered(true)
      }, options.delay)
    },
    [onLongPress, options]
  )

  const clear = React.useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick()
      setLongPressTriggered(false)
      if (options.preventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault)
      }
    },
    [options.preventDefault, onClick, longPressTriggered]
  )

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  }
}

const isTouchEvent = (event): boolean => {
  return 'touches' in event
}

const preventDefault = (event): void => {
  if (!isTouchEvent(event)) return

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}
