import React, { useState } from 'react'

import { Button, Spinner, Avatar, Flex, Box, Text } from '@chakra-ui/react'
import { Tabs, TabList, Tab } from '@chakra-ui/tabs'
import { FiPhoneCall } from 'react-icons/fi'
import { useParams, useHistory } from 'react-router-dom'

import {
  Layout,
  Header,
  ErrorBox,
  Panel,
  PanelHeader,
  PanelBody,
} from 'src/core/components'
import { useFetch, usePermission } from 'src/core/hooks'
import { buyerService } from 'src/applets/buyer'
import { RoutePlans } from 'src/applets/routePlan'
import { UserGeoFinder } from 'src/applets/user'

import BuyerCart from '../components/BuyerCart'
import BuyerOrders from '../components/BuyerOrders'
import BuyerForm from '../components/BuyerForm'

enum TabType {
  Cart,
  Orders,
  Profile,
  RoutePlans,
}

const Buyer: React.FC = () => {
  const history: any = useHistory()
  const { userCan } = usePermission()
  const { id: buyer_id, tab }: any = useParams()

  const [buyer, isLoading, , setBuyer] = useFetch(buyerService, 'fetchById', [
    buyer_id,
  ])

  const paramTabIndex =
    tab.charAt(0)?.toUpperCase() + tab?.substring(1, tab?.length)

  const [tabIndex, setTabIndex] = useState<any>(TabType[paramTabIndex])
  const [orderCount, setOrderCount] = useState<number>(0)
  const [cartItemCount, setCartItemCount] = useState<number>(0)

  return (
    <Layout>
      <Header title="Buyer Profile" onBack={history.goBack} />

      <Panel borderTop={0}>
        <PanelBody>
          <Box textAlign="center">
            <Flex justifyContent="center">
              <Avatar name={buyer?.name.replaceAll(/[^a-zA-Z0-9\s]+/g, '')} />
            </Flex>

            <Box mt={2}>
              <Text
                color={buyer?.name ? 'body' : 'gray.600'}
                fontWeight="medium"
                fontSize="xl"
              >
                {isLoading ? 'Buyer' : buyer?.name || 'No name'}
              </Text>
              <Text>{buyer?.phone || '08000000000'}</Text>
              <Text color="gray.600">
                {buyer?.address || 'No address'} - {buyer?.location?.name}
              </Text>
            </Box>

            <Box mt={2}>
              <a href={`tel:+234${buyer?.phone.substr(1)}`}>
                <Button
                  size="sm"
                  variant="outline"
                  leftIcon={<FiPhoneCall size={14} />}
                  ml={2}
                >
                  Call
                </Button>
              </a>
            </Box>
          </Box>
        </PanelBody>
      </Panel>

      <Panel mt={2}>
        <PanelHeader pb="1px">
          <Tabs
            id="buyer-tabs"
            tabIndex={tabIndex}
            onChange={(tabIndex: TabType) => {
              setTabIndex(tabIndex)
            }}
          >
            <TabList border={0}>
              <Tab>{`Cart ${cartItemCount ? `(${cartItemCount})` : ''}`}</Tab>
              <Tab>{`Orders ${orderCount > 0 ? `(${orderCount})` : ''}`}</Tab>
              <Tab display={userCan('update_buyer') ? 'block' : 'none'}>
                Edit Profile
              </Tab>
              {userCan('view_route_plans') && <Tab>Route Plans</Tab>}
            </TabList>
          </Tabs>
        </PanelHeader>

        {!isLoading && buyer ? (
          <BuyerPageContext.Provider
            value={{
              setOrderCount,
              setCartItemCount,
            }}
          >
            {tabIndex === TabType.Cart && <BuyerCart buyer={buyer} />}
            {tabIndex === TabType.Orders && <BuyerOrders buyer={buyer} />}
            {tabIndex === TabType.Profile && (
              <PanelBody>
                {userCan('update_buyer') ? (
                  <BuyerForm
                    type="update"
                    buyer={buyer}
                    onUpdate={(updated) => {
                      setBuyer({ ...buyer, ...updated })
                    }}
                  />
                ) : (
                  <ErrorBox type="authorization" my={50} />
                )}
              </PanelBody>
            )}
            {tabIndex === TabType.RoutePlans && <RoutePlans buyer={buyer} />}
          </BuyerPageContext.Provider>
        ) : null}
      </Panel>

      {!isLoading && tabIndex === TabType.Profile ? (
        <Panel my={2}>
          <PanelBody>
            <UserGeoFinder userId={buyer?._id} />
          </PanelBody>
        </Panel>
      ) : null}

      {!isLoading && !buyer ? (
        <ErrorBox summary="Buyer does not exist" my={50} />
      ) : null}

      {isLoading && (
        <Panel as={Flex} justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Panel>
      )}
    </Layout>
  )
}

export const BuyerPageContext = React.createContext(null)
export default Buyer
