import { CoreService, CoreServiceProps } from 'src/core/services'
import {
  ILedgerLog,
  ILedgerTag,
  LedgerHead,
  LedgerSubhead,
} from './ledger.type'

export class LedgerService extends CoreService {
  constructor() {
    super('ledger')
  }

  fetchByOwnerSpecific(payload: {
    head: LedgerHead
    subhead: LedgerSubhead
    owner: string
  }): Promise<ILedgerLog> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      const cacheKey = `ledger_${payload.head}_${payload.subhead}_${payload.owner}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`ledger/read_by_owner_specific`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data?.data?.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchLogs(ledgerId): Promise<ILedgerLog> {
    return new Promise((resolve, reject) => {
      const cacheKey = `ledger_logs_${ledgerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .get(`ledger_log/read_by_ledger/?ledger_id=${ledgerId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchTags(payload: {
    parent_id: string
    parent_type: LedgerHead
  }): Promise<ILedgerTag[]> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      const cacheKey = `ledger_tags_${payload.parent_id}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`ledger_tag/read_by_parent/`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default LedgerService
export const ledgerService = new LedgerService()
export type LedgerServiceProps = CoreServiceProps | keyof LedgerService
