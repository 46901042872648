import { CoreService, CoreServiceProps } from 'src/core/services'

export class SalesOfficerService extends CoreService {
  constructor() {
    super('sales_officer')
  }

  fetchDetails(_id: string): Promise<any> {
    const formData = new FormData()
    formData.append('_id', _id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${_id}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_details`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data?.data) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  ordersHistory = (salesOfficerId: string): Promise<any[]> => {
    const formData = new FormData()
    formData.append('sales_officer_id', salesOfficerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `orders_history_${salesOfficerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post('order_group/history_by_sales_officer', formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default SalesOfficerService
export const salesOfficerService = new SalesOfficerService()
export type SalesOfficerServiceProps =
  | CoreServiceProps
  | keyof SalesOfficerService
