import React from 'react'

import { Flex, Text, IconButton, IconButtonProps } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { useLongPress } from 'src/core/hooks'

interface IProps {
  mpu: number
  caseCount: number
  value: number
  onChange: (value: number) => void
}

export const FancyQuantityInput: React.FC<IProps> = (props) => {
  const { value, mpu, caseCount, onChange } = props

  const increment = (): void => {
    // event.preventDefault()
    const newValue = value + mpu
    onChange(newValue)
  }

  const decrement = (): void => {
    // event.preventDefault()
    if (value !== mpu && value > mpu) {
      const newValue = value - mpu
      onChange(newValue)
    }
  }

  const onIncrement = useLongPress(increment, increment)
  const onDecrement = useLongPress(decrement, decrement)

  const buttonStyleProps: Omit<IconButtonProps, 'aria-label'> = {
    size: 'sm',
    width: '34px',
    height: '34px',
    colorScheme: 'primary',
    borderRadius: 99999,
  }

  const quantity = React.useMemo(() => {
    const _quantity = []
    const cases = Math.floor(value / caseCount)
    const units = value % caseCount

    if (cases) _quantity.push(`${cases} ${pluralize('case', cases)}`)
    if (units) _quantity.push(`${units} ${pluralize('unit', units)}`)

    return _quantity
  }, [caseCount, value])

  return (
    <Flex
      padding="6px"
      bg="primary.50"
      borderRadius="6px"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton
        {...buttonStyleProps}
        aria-label="Decrement"
        icon={<FiMinus size={20} />}
        isDisabled={value === mpu || value < mpu}
        {...onDecrement}
      />
      <Text px={3} fontWeight="medium" noOfLines={1}>
        {quantity.join(', ')}
      </Text>
      <IconButton
        {...buttonStyleProps}
        aria-label="Increment"
        icon={<FiPlus size={20} />}
        {...onIncrement}
      />
    </Flex>
  )
}
