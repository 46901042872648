import React from 'react'
import _ from 'lodash'

import useMounted from './useMounted'

export const useFetch = <T = any, S = string>(
  service: any,
  method: S,
  params: any[] = null,
  condition = true
): [T, boolean, any, any] => {
  const isMounted = useMounted()

  params = !params?.length ? null : params

  const [data, setData] = React.useState<any>()
  const [error, setError] = React.useState<any>()
  const [isLoading, setIsLoading] = React.useState<boolean>(condition)

  const paramsRef = React.useRef<any[]>()

  React.useEffect(() => {
    setError(null)

    const fetchData = (): void => {
      setIsLoading(true)

      const call = params?.length
        ? service[method](...params)
        : service[method]()

      call
        .then((data) => {
          isMounted.current && setData(data)
        })
        .catch((error) => {
          isMounted.current && setError(error)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    /**
     * Deep check to see if params are different
     */
    if (params) {
      const paramsDifference = _(params)
        .differenceWith(paramsRef.current, _.isEqual)
        .isEmpty()

      if (!paramsDifference) {
        paramsRef.current = params
        condition && fetchData()
      }
    } else {
      condition && fetchData()
    }
  }, [isMounted, condition, method, service, params])

  return [data, isLoading, error, setData]
}

export default useFetch
