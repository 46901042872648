import React from 'react'

import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Flex, Button } from '@chakra-ui/react'
import { LedgerBalanceCard, LedgerBalanceCardProps } from './LedgerBalanceCard'
import { FiX } from 'react-icons/fi'

interface LedgerBalanceModalProps extends LedgerBalanceCardProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const LedgerBalanceModal: React.FC<LedgerBalanceModalProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalHeader>
          asas
          <ModalCloseButton />
        </ModalHeader>
        <ModalContent>
          <ModalBody>
            <LedgerBalanceCard {...props} disableClick />
            <Flex mt={5} justifyContent="center">
              <Button
                siz="sm"
                variant="ghost"
                leftIcon={<FiX size={16} />}
                onClick={onClose}
              >
                Close
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
