import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/layout'
import { useTheme, Avatar, Spinner } from '@chakra-ui/react'

import { ErrorBox, Pagination } from 'src/core/components'
import { Table, Td, Tbody, Tr } from 'src/core/components/Table'
import { formatCurrency, paginate, timestampToDate } from 'src/core/helpers'
import useFetch from 'src/core/hooks/useFetch'

import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi'

import { ledgerService, LedgerServiceProps } from '../ledger.service'
import { ILedger, ILedgerLog } from '../ledger.type'

interface IProps {
  ledger: ILedger
}

export const LedgerLogs: React.FC<IProps> = (props) => {
  const { sizes, colors } = useTheme()
  const [page, setPage] = React.useState<number>(1)

  const [ledgerLogs, isLoading] = useFetch<ILedgerLog[], LedgerServiceProps>(
    ledgerService,
    'fetchLogs',
    [props.ledger?._id],
    !!props.ledger?._id
  )

  const paginatedLedgerLogs = React.useMemo(() => {
    return ledgerLogs?.length ? paginate(ledgerLogs, page) : []
  }, [page, ledgerLogs])

  const colorCode = (value): string => {
    return value < 0 ? 'critical.600' : value > 0 ? 'success.600' : 'body'
  }

  return (
    <>
      {isLoading && (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      )}

      {!isLoading && !ledgerLogs?.length ? (
        <ErrorBox summary="No logs found" my={50} />
      ) : null}

      {!isLoading && ledgerLogs?.length ? (
        <>
          <Flex px={sizes.gutter.md} py={sizes.gutter.sm} bgColor="white">
            <Text
              fontSize="md"
              fontWeight="medium"
              color="gray.600"
              textTransform="uppercase"
            >
              Transactions
            </Text>
          </Flex>

          <Table>
            <Tbody>
              {paginatedLedgerLogs?.map((log) => (
                <Tr key={log?._id}>
                  <Td>
                    <Flex alignItems="center">
                      <Box mr={2}>
                        <Avatar
                          size="sm"
                          bg={
                            log.value < 0
                              ? colors.critical[50]
                              : colors.success[50]
                          }
                          icon={
                            log.value < 0 ? (
                              <FiArrowUpRight
                                color={colors.critical[600]}
                                size={16}
                              />
                            ) : (
                              <FiArrowDownLeft
                                color={colors.success[600]}
                                size={16}
                              />
                            )
                          }
                        />
                      </Box>
                      <Box wordBreak="break-word">
                        <Text fontWeight="medium" textTransform="capitalize">
                          {log.tag}
                        </Text>
                        <Text color="gray.600">
                          {log.description.split('|').join(' | ')}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>

                  <Td isNumeric>
                    <Text color={colorCode(log.value)}>
                      {formatCurrency(log.value)}
                    </Text>
                    <Text color="gray.600" fontSize="sm">
                      {timestampToDate(log.created)}
                    </Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : null}

      {ledgerLogs?.length && (
        <Pagination
          my={5}
          totalItems={ledgerLogs?.length}
          currentPage={page}
          onPageChange={setPage}
        />
      )}
    </>
  )
}
