import React from 'react'

import { VStack, Button } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { IStoreState } from 'src/bootstrap/store/types'
import { UserService } from '../user.service'

const userService = new UserService()

const ChangePasswordForm: React.FC = () => {
  const { addToast } = useToast()

  const user = useSelector((state: IStoreState) => state.user)

  const formConfig = {
    initialValues: {
      hash_old: '',
      hash_new: '',
    },
    validationSchema: Yup.object({
      hash_old: Yup.string().required('Old password is required.'),
      hash_new: Yup.string().required('New password is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      values.username = user.user_id

      userService
        .updatePassword('change', values)
        .then(() => {
          addToast('Profile successfully updated.', { appearance: 'success' })
          setSubmitting(false)
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack spacing={3} align="stretch" my={5}>
            {/* Old Password */}
            <FormStack label="Old Password" isRequired>
              <FormikField name="hash_old" type="text" />
            </FormStack>

            {/* New Password */}
            <FormStack label="New Password" isRequired>
              <FormikField name="hash_new" type="text" />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="success"
            mt={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            width="100%"
            isLoading={formik.isSubmitting}
            loadingText="Updating"
          >
            Update Password
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
