/**
 * Helper functions for date
 */

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

/**
 *
 * @param type - Period Type
 * @param quantity - Period Quantity
 */
export const computeTTL = (type: string, quantity = 1): string => {
  let ttl = 0
  switch (type) {
    case 'week':
      ttl = 604800 * quantity
      break
    case 'bi-week':
      ttl = 1209600 * quantity
      break
    case 'month':
      ttl = 2592000 * quantity
      break
    case 'quarter':
      ttl = 7776000 * quantity
      break
    default:
      ttl = 86400 * quantity
      break
  }
  return ttl.toString()
}

/**
 *
 * @param period - Period
 */
export const undoComputeTTL = (period: number): string => {
  let type: string

  switch (period) {
    case 604800:
      type = 'week'
      break
    case 1209600:
      type = 'bi-week'
      break
    case 2592000:
      type = 'month'
      break
    case 7776000:
      type = 'quarter'
      break
    default:
      type = 'day'
      break
  }
  return type
}

export const timestampToDate = (timestamp: any, monthYear = false): string => {
  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()
  const time = `${!monthYear ? `${day} ` : ''}${month} ${year}`
  return time
}

/**
 * Return date in format DDMMYYYY for excel
 * export filename
 *
 * @param date
 */
export const dateForFilename = (date): string => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  return `${day}${month}${year}`
}
