import { useLocation } from 'react-router-dom'
import { routes, IRouteId } from 'src/bootstrap/routes'

/**
 * Search URL params
 */
export const useQuery = (): any => {
  return new URLSearchParams(useLocation().search)
}

/**
 * Build query string from object keys & values
 *
 * @param params
 */
export const buildQueryString = (params: any): any => {
  const params_array = []
  if (params && typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      params[key] && params_array.push(`${key}=${params[key]}`)
    })
  }

  return params_array.join('&')
}

/**
 * Replace params (:param) in routes,
 * build query string from object
 *
 * @param route
 * @param params
 * @param query
 */
export const route = (
  routeId: IRouteId,
  params: any = {},
  query?: any
): string => {
  const found = routes.find(({ id }: any) => id === routeId)
  let routeString = found ? found.path : 'undefined'

  if (routeString && params && Object.keys(params).length) {
    Object.keys(params).forEach((key) => {
      routeString = routeString.replace(new RegExp(`:${key}`, 'g'), params[key])
    })
  }

  if (query) routeString = `${routeString}?${buildQueryString(query)}`

  return routeString
}
