import React from 'react'
import { IconButton, IconButtonProps } from '@chakra-ui/button'
import { FiPlus } from 'react-icons/fi'
import { Button } from '@chakra-ui/react'

interface IFloatingButtonProps extends IconButtonProps {
  label?: string
  icon?: JSX.Element
}

export const FloatingButton: React.FC<IFloatingButtonProps> = (props) => {
  const { icon, children, label, ...rest } = props
  const buttonText = children || label

  return buttonText ? (
    <Button
      boxShadow="lg"
      position="fixed"
      borderRadius={99999}
      zIndex={9}
      leftIcon={icon || <FiPlus size={22} />}
      {...rest}
    >
      {buttonText}
    </Button>
  ) : (
    <IconButton
      boxShadow="lg"
      position="fixed"
      borderRadius={99999}
      zIndex={9}
      icon={icon || <FiPlus size={22} />}
      {...rest}
    />
  )
}
