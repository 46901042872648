import {
  DayOfWeek as Day,
  WeekOfMonth as Week,
} from 'src/applets/routePlan/routePlan.type'

export const renderDayText = (day: any): string => {
  switch (day) {
    case Day.Monday:
      return 'Monday'
    case Day.Tuesday:
      return 'Tuesday'
    case Day.Wednesday:
      return 'Wednesday'
    case Day.Thursday:
      return 'Thursday'
    case Day.Friday:
      return 'Friday'
    case Day.Saturday:
      return 'Saturday'
  }
}

export const renderWeekText = (week: Week): string => {
  switch (week) {
    case Week.Every:
      return 'Every Week'
    case Week.One:
      return 'Week 1'
    case Week.Two:
      return 'Week 2'
    case Week.Three:
      return 'Week 3'
    case Week.Four:
      return 'Week 4'
  }
}
