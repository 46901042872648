import React from 'react'

import Layout from 'src/core/components/Layout/Layout'
import Header from 'src/core/components/Layout/Header/Header'
import { BuyersList } from 'src/applets/buyer/components'

const BuyersScreen: React.FC = () => {
  return (
    <Layout>
      <Header title={`Retailers`} />
      <BuyersList />
    </Layout>
  )
}

export default BuyersScreen
