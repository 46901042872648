import React, { FC } from 'react'
import { Avatar } from '@chakra-ui/avatar'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { useTheme } from '@chakra-ui/system'
import {
  FiCreditCard,
  FiDatabase,
  FiDownload,
  FiToggleRight,
} from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { doLogout } from 'src/bootstrap/store/auth/authActions'
import { IStoreState } from 'src/bootstrap/store/types'
import { Layout } from 'src/core/components'
import { formatCurrency, route } from 'src/core/helpers'
import { LedgerHead, LedgerSubhead, useLedger } from 'src/applets/ledger'
import { UserInfoCard } from 'src/applets/user'
import { Tag } from '@chakra-ui/tag'
import { TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { Role } from 'src/bootstrap/permissions'

const Settings: FC = () => {
  const history = useHistory()
  const { sizes, colors } = useTheme()

  const { user, appVersion, appEnv } = useSelector((state: IStoreState) => ({
    user: state.user,
    salesOfficer: state.salesOfficer,
    appVersion: state.global.version,
    appEnv: state.global.env,
  }))

  const [ledger, isLedgerLoading] = useLedger({
    head: LedgerHead.SalesOfficer,
    subhead: LedgerSubhead.Wallet,
    owner: user?._id,
  })

  const dispatch = useDispatch()

  const itemStyleProps = {
    cursor: 'default',
    justifyContent: 'space-between',
    alignItems: 'center',
    mx: sizes.gutter.md,
    px: sizes.gutter.md,
    mt: '10px',
    py: '12px',
    borderRadius: sizes.gutter.sm,
    borderColor: 'gray.100',
    borderWidth: 1,
  }

  return (
    <>
      <Layout>
        {/* <Header title="Settings" onBack={history.goBack} /> */}
        <Flex flex={1} flexDirection="column" bgColor="white">
          <Text
            fontSize="20px"
            fontWeight="bold"
            pt="26px"
            px={sizes.gutter.md}
          >
            More
          </Text>

          <UserInfoCard />

          {user.role === Role.SellerRep && (
            <Flex
              {...itemStyleProps}
              onClick={() => history.push(route('balance'))}
            >
              <Flex alignItems="center">
                <Avatar
                  size="sm"
                  bg="#E9FAFF"
                  color="primary.600"
                  icon={<FiCreditCard />}
                />
                <Text ml={sizes.gutter.sm} fontSize="md" fontWeight="medium">
                  Balance
                </Text>
              </Flex>
              <Text
                fontSize="md"
                fontWeight="medium"
                color={
                  isLedgerLoading
                    ? colors.green[600]
                    : ledger?.balance > 0
                    ? colors.success[600]
                    : colors.critical[600]
                }
              >
                {isLedgerLoading ? '...' : formatCurrency(ledger?.balance || 0)}
              </Text>
            </Flex>
          )}

          <Flex
            {...itemStyleProps}
            onClick={(event) => {
              event.preventDefault()
              window.location.reload()
            }}
          >
            <Flex alignItems="center">
              <Avatar
                size="sm"
                bg="#E9FAFF"
                color="primary.600"
                icon={<FiDownload />}
              />
              <Text ml={sizes.gutter.sm} fontSize="md" fontWeight="medium">
                Update App
              </Text>
            </Flex>
            <Text fontSize="md" fontWeight="medium" color="gray.600">
              v{appVersion}
            </Text>
          </Flex>

          <Flex {...itemStyleProps} onClick={() => dispatch(doLogout())}>
            <Flex alignItems="center">
              <Avatar
                size="sm"
                bg="critical.50"
                color="critical.600"
                icon={<FiToggleRight />}
              />
              <Text
                ml={sizes.gutter.sm}
                fontSize="md"
                fontWeight="medium"
                color={colors.critical[600]}
              >
                Log out
              </Text>
            </Flex>
          </Flex>

          <Box mt={20} mb={5} textAlign="center">
            <Tag
              size="sm"
              variant="subtle"
              colorScheme={
                appEnv === 'development'
                  ? 'critical'
                  : appEnv === 'staging'
                  ? 'warning'
                  : appEnv === 'production'
                  ? 'success'
                  : 'gray'
              }
            >
              <TagLeftIcon boxSize="12px" as={FiDatabase} />
              <TagLabel>
                Suplias Go - {appEnv} v{appVersion}
              </TagLabel>
            </Tag>
          </Box>
        </Flex>
      </Layout>
    </>
  )
}

export default Settings
