import Order from 'src/applets/order/screens/OrderGroup.screen'
import PendingOrders from 'src/applets/order/screens/PendingOrders.screen'

import { IRoute } from 'src/bootstrap/routes'

export const orderRoutes: IRoute[] = [
  {
    id: 'pending_orders',
    path: '/pending',
    component: PendingOrders,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'order',
    path: '/orders/:id',
    component: Order,
    requiresAuth: true,
    exact: true,
  },
]

export default orderRoutes
