import { IOrderGroup, OrderTag } from 'src/applets/order/order.type'

/**
 * We always need to map order items to
 * the right seller prices.
 *
 * @param {array} items
 * @param {object} fulfilments
 * @returns {array} items
 */
export const mapOrderItemsToSellerPrices = (items, fulfilments): any[] => {
  return items.map(({ product, ...item }) => {
    let seller: any = false

    // get the seller for this manufacturer
    const sellerId = fulfilments[product.manufacturer._id]?.seller_id

    // check if the seller set price and mpu for this product
    if (sellerId && product.sellers && product.sellers[sellerId]) {
      seller = product.sellers[sellerId]
    }

    return {
      ...item,
      product: {
        ...product,
        mpu: seller ? seller.mpu : product.mpu,
        price: seller ? seller.price : product.price,
        status: seller ? seller.status : product.status,
      },
    }
  })
}

/**
 * Add some beauty to Stevochi's order object,
 * for better sorting and filtering
 *
 * @param {object} order
 * @returns {object} order
 */
export const beautifyOrderGroup = (order: IOrderGroup): IOrderGroup => {
  if (order) {
    const { orders, charges, meta_obj } = order

    // order.ref === 'T6EJSSQK' && console.log(order)

    order.buyer_name = order?.buyer?.name || '-'
    order.location_name = order?.buyer?.location?.name || '-'
    order.buyer_is_new = order.buyer?.order_group_count?.tags?.is_new

    order.sales_officer_name = order?.sales_officer?.name || '-'

    order.source = meta_obj?.source?.value || meta_obj?.source || '-'
    order.cases =
      meta_obj?.item_case_count?.value || meta_obj?.item_case_count || '-'
    order.manufacturers = order?.orders?.manufacturers || '-'

    order.discount = Number(meta_obj?.discount?.value) || 0
    order.discount_reason = meta_obj?.discount?.meta || '-'
    order.returns = Number(meta_obj?.returns?.value) || 0
    order.returns_reason = meta_obj?.returns?.meta || '-'
    order.payment_method = meta_obj?.payment_method?.value || '-'

    order.delivery_fee = charges?.DELIVERY_FEE?.value || 0

    order.subtotal = orders?.value?.completed || order.amount || 0
    order.total = order.subtotal + order.delivery_fee + order.discount
    order.total = Number(order.total.toFixed(2))

    order.cash = Number(meta_obj?.payment_cash?.value || 0)
    order.transfer = Number(meta_obj?.payment_transfer?.value || 0)
    order.paid = order.cash + order.transfer
    order.outstanding =
      order.tag === OrderTag.Cancelled ? 0 : order.paid - order.total

    order.comment = meta_obj?.comment?.value || '-'
  }

  return order
}
