import React from 'react'

import { Spinner, Flex } from '@chakra-ui/react'

import { ErrorBox } from 'src/core/components'
import {
  OrderGroupContext,
  OrderGroupItems,
  orderGroupService,
  OrderTag,
} from 'src/applets/order'

import usePermission from 'src/core/hooks/usePermission'
import useOrderGroup from 'src/applets/order/hooks/useOrderGroup'
import useFetch from 'src/core/hooks/useFetch'
import { generateRefId } from 'src/core/helpers/rand.helper'

import { BuyerPageContext } from '../screens/Buyer.screen'
import { IBuyer } from '../buyer.type'

interface IProps {
  buyer: IBuyer
}

const BuyerCart: React.FC<IProps> = ({ buyer }) => {
  const { setCartItemCount } = React.useContext(BuyerPageContext)

  const { userCan } = usePermission()

  const params = React.useMemo(() => {
    return {
      ref: generateRefId(8),
      tag: OrderTag.Draft,
      meta: '',
      amount: 0,
      buyer_id: buyer._id,
    }
  }, [buyer._id])

  /**
   * Things you should know:
   * + The buyer cart is just an order group tagged 'draft'
   * + The create method check if one exists and creates one
   * if it doesn't. Either ways, you get an order_group_id. Solid!
   */

  const [orderGroupId] = useFetch(
    orderGroupService,
    'create',
    [params, { resetCache: false }],
    userCan('view_buyer_cart')
  )

  const orderGroupHook = useOrderGroup(orderGroupId)

  React.useEffect(() => {
    orderGroupHook.orderGroupItems?.length &&
      setCartItemCount(orderGroupHook.orderGroupItems.length)
  }, [orderGroupHook.orderGroupItems])

  return userCan('view_buyer_cart') ? (
    <OrderGroupContext.Provider
      value={{
        ...orderGroupHook,
        setOrderGroup: (values) => {
          orderGroupHook.setOrderGroup(values)
          orderGroupHook.triggerRefresh()
        },
      }}
    >
      {orderGroupHook.isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : orderGroupHook.orderGroup ? (
        <OrderGroupItems />
      ) : (
        <ErrorBox
          summary="Buyer cart not found. This is an unusal error. Please contact support."
          my={50}
        />
      )}
    </OrderGroupContext.Provider>
  ) : (
    <ErrorBox type="authorization" my={50} />
  )
}

export default BuyerCart
