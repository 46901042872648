import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal'
import { Flex, IconButton, Text, Box } from '@chakra-ui/react'
import { FiChevronLeft, FiX } from 'react-icons/fi'

import { SearchBar } from 'src/core/components'

import { useVisualProductPicker } from '../../hooks/useVisualProductPicker'
import {
  ParentGrid,
  ProductList,
  ProductView,
} from './VisualProductPickerParts'
import { IOrderItem } from 'src/applets/order'
import { basicSearch } from 'src/core/helpers'

interface IProps {
  isOpen?: boolean
  /** Edit mode? */
  cartItem?: IOrderItem
  cartItems: any[]
  /**
   * We'll use the location to fetch order fulfilment data
   * so we know which products to shoe.
   */
  locationId?: string
  /**
   * We may need to show only brands and products from
   * just one manufacturer.
   */
  manufacturerId?: string
  addItem: (item: any) => Promise<any>
  onClose: () => void
}
export const VisualProductPicker: React.FC<IProps> = (props) => {
  const { isOpen, onClose, locationId } = props

  const [search, setSearch] = React.useState<string>()

  const {
    available,
    selected,
    setSelected,
    productQuantity,
    setProductQuantity,
  } = useVisualProductPicker(locationId)

  /**
   * If there's a cart item, this is edit mode.
   * Do the needful.
   */
  React.useEffect(() => {
    if (props?.cartItem) {
      setSelected({
        manufacturer: props.cartItem.product.manufacturer,
        brand: props.cartItem.product.brand,
        product: { ...props.cartItem.product, in_cart: true },
      })
      setProductQuantity(props.cartItem.quantity)
    }
  }, [props?.cartItem])

  /**
   * Reset search when selected changed
   */
  React.useEffect(() => {
    setSearch('')
  }, [selected?.manufacturer, selected?.brand])

  const filtered = React.useMemo(() => {
    let manufacturers = available?.manufacturers
    let brands = available?.brands
    let products = available?.products

    if (search) {
      manufacturers = manufacturers.filter((manufacturer) => {
        return basicSearch(manufacturer.name, search)
      })
      brands = brands.filter((brand) => {
        return basicSearch(brand.name, search)
      })
      products = products.filter((product) => {
        return basicSearch(product.name, search)
      })
    }

    return { manufacturers, brands, products }
  }, [search, available])

  const goBack = (): void => {
    if (selected?.product) {
      setSelected({ ...selected, product: null })
    } else if (selected?.brand) {
      setSelected({ ...selected, brand: null })
    } else if (selected?.manufacturer) {
      setSelected({ ...selected, manufacturer: null })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent overflow="clip" alignSelf="center">
        <ModalBody padding={0}>
          {/* Header */}
          <Box as="header">
            <Flex
              padding="6px 16px"
              borderBottom="1px solid"
              borderColor="gray.100"
              justifyContent="space-between"
              alignItems="center"
            >
              {selected?.manufacturer ? (
                <IconButton
                  size="sm"
                  variant="ghost"
                  aria-label="Back"
                  icon={<FiChevronLeft size={18} />}
                  onClick={goBack}
                />
              ) : null}
              <Text mx="10px" noOfLines={1} fontWeight="medium">
                {selected?.product
                  ? selected.product.name
                  : selected?.brand
                  ? selected.brand.name
                  : selected?.manufacturer
                  ? selected.manufacturer.name
                  : 'Place Order'}
              </Text>
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="Close"
                icon={<FiX size={18} />}
                onClick={onClose}
              />
            </Flex>

            {/* Search */}
            {!selected?.product && (
              <Flex
                padding="10px 16px"
                borderBottom="1px solid"
                borderColor="gray.100"
              >
                <SearchBar
                  value={search}
                  placeholder={`Search ${
                    selected?.brand
                      ? `${selected.brand.name} products`
                      : selected?.manufacturer
                      ? `${selected.manufacturer.name} brands`
                      : 'manufacturers'
                  }`}
                  onChange={setSearch}
                />
              </Flex>
            )}
          </Box>

          {/* Content */}
          <Box
            overflowY="auto"
            height={
              selected?.product ? 'calc(100vh - 120px)' : 'calc(100vh - 150px)'
            }
          >
            {selected?.product ? (
              <ProductView
                product={selected?.product}
                quantity={productQuantity}
                setQuantity={setProductQuantity}
                addProduct={props.addItem}
                closePicker={() => {
                  setSelected({ ...selected, product: null })
                  onClose()
                }}
              />
            ) : selected?.brand ? (
              <ProductList
                products={filtered?.products}
                cartItems={props.cartItems || []}
                onSelect={(product) => {
                  setSelected({ ...selected, product })
                }}
              />
            ) : selected?.manufacturer ? (
              <ParentGrid
                label="brand"
                items={filtered?.brands}
                onSelect={(brand) => {
                  setSelected({ ...selected, brand })
                }}
              />
            ) : (
              <ParentGrid
                label="manufacturer"
                items={filtered?.manufacturers}
                onSelect={(manufacturer) => {
                  setSelected({ ...selected, manufacturer })
                }}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
