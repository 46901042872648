import React, { useState } from 'react'

import { Flex, Badge, Button, IconButton, Box, Text } from '@chakra-ui/react'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { FiEdit2 } from 'react-icons/fi'

import usePermission from 'src/core/hooks/usePermission'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { OrderTag } from 'src/applets/order/order.type'
import { UserGeoFinder } from 'src/applets/user'
import { FormField, Panel, PanelBody } from 'src/core/components'

import { OrderGroupContext } from '../../contexts'

/**
 * Used in OrderGroupBuyer.
 * For updating an order group tag or payment method.
 */
const OrderGroupUpdateModal: React.FC = () => {
  const { userCan } = usePermission()

  const { orderGroup, setOrderGroup, updateTag, isUpdating } =
    React.useContext(OrderGroupContext)

  const [tag, setTag] = useState<OrderTag>(orderGroup.tag)
  const [editMode, setEditMode] = useState<boolean>(false)

  const [payment, setPayment] = useState<{
    method: 'cash' | 'transfer'
    cash: number
    transfer: number
  }>({
    method: null,
    cash: 0,
    transfer: 0,
  })

  React.useEffect(() => {
    setTag(orderGroup.tag)
  }, [orderGroup])

  return (
    <>
      {userCan('complete_order') && (
        <Flex alignItems="center">
          {orderGroup.tag !== OrderTag.Pending ? (
            <>
              <Badge
                colorScheme={
                  tag === 'completed'
                    ? 'success'
                    : ['pending', 'delivered'].includes(tag)
                    ? 'warning'
                    : 'critical'
                }
                onClick={() => userCan('update_order') && setEditMode(true)}
              >
                {tag}
              </Badge>
              {userCan('update_order') && (
                <IconButton
                  ml={2}
                  size="sm"
                  variant="ghost"
                  aria-label="Edit"
                  icon={<FiEdit2 size={16} />}
                  onClick={() => setEditMode(true)}
                />
              )}
            </>
          ) : (
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => {
                orderGroup.tag === OrderTag.Pending &&
                  setTag(OrderTag.Completed)
                setEditMode(true)
              }}
            >
              Complete
            </Button>
          )}
        </Flex>
      )}

      <Modal
        isOpen={editMode}
        onClose={() => {
          setTag(orderGroup.tag)
          setEditMode(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>
            {orderGroup.tag !== OrderTag.Pending
              ? 'Update Order'
              : 'Complete Order'}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            {orderGroup.tag !== OrderTag.Pending ? (
              <Box mt={4}>
                <FormField
                  size="lg"
                  as="select"
                  value={tag}
                  onChange={({ target }: any) => setTag(target.value)}
                >
                  <option value={OrderTag.Pending}>Pending</option>
                  <option value={OrderTag.Delivered}>Delivered</option>
                  <option value={OrderTag.Completed}>Completed</option>
                  <option value={OrderTag.Cancelled}>Cancelled</option>
                </FormField>

                <Button
                  mt={2}
                  size="lg"
                  colorScheme="primary"
                  onClick={() => {
                    updateTag(tag).then(() => {
                      setEditMode(false)
                      setOrderGroup({ tag })
                    })
                  }}
                  isDisabled={isUpdating || orderGroup.tag === tag}
                  isLoading={isUpdating}
                  isFullWidth
                >
                  Done
                </Button>
              </Box>
            ) : (
              <>
                <Box mt={4} textAlign="center">
                  <Text
                    variant="tinyTitle"
                    textTransform="uppercase"
                    letterSpacing="1px"
                  >
                    Total Amount Due
                  </Text>
                  <Text fontWeight="bold" fontSize="3xl">
                    NGN {formatCurrency(orderGroup.total)}
                  </Text>
                </Box>

                <FormField
                  as="select"
                  size="lg"
                  name="method"
                  mt={5}
                  value={payment.method || ''}
                  onChange={({ target }: any) => {
                    setPayment({
                      ...payment,
                      method: target.value,
                      cash: target.value === 'cash' ? orderGroup.total : 0,
                      transfer: 0,
                    })
                  }}
                >
                  <option value="">Select payment method</option>
                  <option value="cash">Cash</option>
                  <option value="transfer">Transfer</option>
                </FormField>

                {payment.method && payment.method !== 'transfer' ? (
                  <Box mt={2}>
                    {['cash'].map((type) => (
                      <FormField
                        key={type}
                        name={type}
                        size="lg"
                        mb={2}
                        type="number"
                        prepend={type == 'cash' ? 'Cash' : 'Transfer'}
                        value={payment[type]}
                        placeholder={
                          type == 'cash' ? 'Cash amount' : 'Transfer amount'
                        }
                        onChange={({ target }: any) => {
                          setPayment({
                            ...payment,
                            [type]: Number(target.value),
                          })
                        }}
                      />
                    ))}
                  </Box>
                ) : null}

                <Button
                  mt={4}
                  size="lg"
                  colorScheme="primary"
                  onClick={() => {
                    updateTag(payment?.method !== 'cash' ? tag : tag, {
                      payment,
                    }).then(() => {
                      setEditMode(false)
                      setOrderGroup({ tag })
                    })
                  }}
                  isDisabled={
                    isUpdating ||
                    orderGroup.tag === tag ||
                    !payment?.method ||
                    (payment?.method !== 'transfer' && !payment.cash)
                  }
                  width="100%"
                  isLoading={isUpdating}
                >
                  {payment?.method !== 'cash'
                    ? 'Mark Delivered'
                    : 'Complete Order'}
                </Button>
              </>
            )}

            <Panel bg="gray.50" borderRadius="md" mt={50}>
              <PanelBody>
                <UserGeoFinder userId={orderGroup?.buyer?._id} />
              </PanelBody>
            </Panel>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default OrderGroupUpdateModal
