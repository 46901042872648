import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import Header from 'src/core/components/Layout/Header/Header'
import BuyerForm from 'src/applets/buyer/components/BuyerForm'

interface IProps extends RouteComponentProps {
  history: any
}

const BuyerSignup: React.FC<IProps> = ({ history }) => {
  return (
    <>
      <Header title="Create Buyer" onBack={history.goBack} />
      <Card>
        <Card.Body>
          <BuyerForm />
        </Card.Body>
      </Card>
    </>
  )
}

export default withRouter(BuyerSignup)
