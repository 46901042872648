import React from 'react'

import { Flex, Box } from '@chakra-ui/layout'
import { Text, Spinner, IconButton, Button, Divider } from '@chakra-ui/react'
import pluralize from 'pluralize'

import { FiCheck, FiEdit3, FiMoreVertical, FiTrash2, FiX } from 'react-icons/fi'

import {
  QuantityInput,
  FancyOptions,
  ImageBox as ProductImage,
} from 'src/core/components'

import { asset, formatCurrency } from 'src/core/helpers'
import { usePermission } from 'src/core/hooks'

import { OrderTag } from 'src/applets/order'

interface OrderProductProps {
  item: any
  parent: any
  updateItem: (item: any) => Promise<any>
  deleteItem?: (item: any) => Promise<any>
  setParent?: (order: any) => void
  forceUpdate?: boolean
}

export const OrderProduct: React.FC<OrderProductProps> = (props) => {
  const { item, parent } = props
  const { userCan } = usePermission()

  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [showOptions, setShowOptions] = React.useState<boolean>(false)

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)

  const [localItem, setLocalItem] = React.useState<any>(item)
  const [updatedQuantity, setUpdatedQuantity] = React.useState<number>()

  // Sync props.item with localItem
  React.useEffect(() => {
    setLocalItem(item)
  }, [item])

  const computed = React.useMemo(() => {
    const _oldRate =
      localItem.amount / (localItem.quantity / localItem.product.case_count)
    const _newRate = localItem.product.price

    const _quantity = editMode ? updatedQuantity : localItem?.quantity
    const _amount = editMode
      ? _oldRate * (_quantity / localItem.product.case_count)
      : localItem.amount

    return {
      oldRate: isFinite(_oldRate) ? _oldRate : 0,
      newRate: isFinite(_newRate) ? _newRate : 0,
      cases: Math.floor(_quantity / localItem.product.case_count),
      units: _quantity % localItem.product.case_count,
      amount: _amount,
    }
  }, [updatedQuantity, localItem, editMode])

  const handleUpdate = (): void => {
    setIsUpdating(true)

    const updatedParentAmount =
      parent.amount - localItem.amount + computed.amount

    const updatedItem = {
      ...item,
      quantity: updatedQuantity, // only updated value
      amount: computed.amount,
    }

    props
      .updateItem(updatedItem)
      .then(() => {
        setEditMode(false) // Close edit form

        setLocalItem(updatedItem)
        props.setParent({ amount: updatedParentAmount }) // Update order amount
      })
      .finally(() => setIsUpdating(false))
  }

  const handleDelete = (): void => {
    setIsDeleting(true)
    props
      .deleteItem(item)
      .then((parent) => {
        props.setParent(parent)
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  const quantityText = React.useMemo(() => {
    const _quantity = []
    const { cases, units } = computed

    if (cases) _quantity.push(`${cases} ${pluralize('case', cases)}`)
    if (units) _quantity.push(`${units} ${pluralize('unit', units)}`)

    return _quantity?.length ? _quantity : ['-']
  }, [computed])

  const canUpdateOrder =
    (userCan('update_order') && localItem.tag !== OrderTag.Completed) ||
    props.forceUpdate

  return (
    <Box position="relative" overflow="hidden">
      <Box
        padding="8px 20px"
        overflow="hidden"
        borderBottom="1px solid"
        borderColor="gray.100"
        transform={showOptions ? 'translate(-48%,0)' : 'translate(0,0)'}
        transition="transform 500ms ease-in-out"
      >
        <Flex justifyContent="space-between" width="100%">
          {!editMode && (
            <Box mr={2} flexBasis="20px">
              <ProductImage src={asset(localItem?.product?.upload?.path)} />
            </Box>
          )}

          <Flex
            title={localItem.product.name}
            flexBasis="100%"
            flexDir="column"
            justifyContent="center"
          >
            <Flex justifyContent="space-between">
              <Box>
                <Text>{localItem.product.name}</Text>

                <Text fontSize="sm" color="gray.600">
                  {quantityText.join(', ')}
                </Text>

                {/* Item total */}
                <Text mt={2}>
                  NGN{` `}
                  {editMode
                    ? formatCurrency(updatedQuantity * computed.oldRate)
                    : formatCurrency(localItem.amount)}
                  {` `}
                  {computed.oldRate !== computed.newRate && (
                    <Text as="b" color="critical.600">
                      *{' '}
                    </Text>
                  )}
                </Text>
              </Box>

              {canUpdateOrder && !showOptions && !editMode ? (
                <IconButton
                  aria-label="Show option"
                  size="sm"
                  variant="ghost"
                  icon={<FiMoreVertical size={16} />}
                  onClick={() => setShowOptions(!showOptions)}
                />
              ) : null}
            </Flex>

            {editMode && (
              <>
                <Divider width="100%" margin="14px 0 10px" />
                <Flex alignItems="center" justifyContent="space-between" my={2}>
                  {/* Item quantity */}
                  <QuantityInput
                    value={updatedQuantity}
                    mpu={localItem.product.mpu}
                    onChange={(value: number) => setUpdatedQuantity(value)}
                  />

                  <Flex alignItems="center">
                    {isUpdating ? (
                      <Spinner size="sm" color="primary.600" />
                    ) : (
                      <>
                        <Button
                          size="sm"
                          colorScheme="primary"
                          leftIcon={<FiCheck size={16} />}
                          onClick={handleUpdate}
                          isDisabled={
                            updatedQuantity < 1 ||
                            updatedQuantity * computed.oldRate ===
                              localItem.amount ||
                            isUpdating
                          }
                        >
                          Save
                        </Button>

                        {!isUpdating && (
                          <IconButton
                            aria-label="Cancel"
                            size="sm"
                            ml={3}
                            icon={<FiX size={20} />}
                            variant="ghost"
                            onClick={() => {
                              setUpdatedQuantity(null)
                              setEditMode(false)
                            }}
                          />
                        )}
                      </>
                    )}
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Box>

      {canUpdateOrder && (
        <FancyOptions
          isOpen={showOptions}
          onClose={() => setShowOptions(false)}
        >
          <FancyOptions.Option
            color="white"
            bg="primary.600"
            icon={<FiEdit3 size={16} />}
            onClick={() => {
              setShowOptions(false)
              setTimeout(() => {
                setUpdatedQuantity(localItem.quantity)
                setEditMode(true)
              }, 1)
            }}
          />

          {canUpdateOrder && props?.deleteItem ? (
            <FancyOptions.Option
              color="white"
              bg="critical.600"
              isLoading={isDeleting}
              icon={<FiTrash2 size={16} />}
              onClick={handleDelete}
            />
          ) : null}
        </FancyOptions>
      )}
    </Box>
  )
}
