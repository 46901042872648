import React from 'react'
import { Box } from '@chakra-ui/layout'
import { ErrorBox } from 'src/core/components'

const Error404: React.FC<any> = () => {
  return (
    <Box my={50} px={30}>
      <ErrorBox
        title="Page not found!"
        summary="The page you're trying to access was not found. That's all we know."
      />
    </Box>
  )
}

export default Error404
