import React from 'react'

import { Avatar, Badge, Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { FiMoreVertical, FiPhoneCall, FiUser } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { FancyOptions } from 'src/core/components'
import { IBuyer } from 'src/applets/buyer/buyer.type'
import { route } from 'src/core/helpers/route.helper'

interface IProps {
  buyer: IBuyer
  isLast?: boolean
}

const BuyersListBuyer: React.FC<IProps> = ({ buyer }) => {
  const history = useHistory()

  const [showOptions, setShowOptions] = React.useState<boolean>(false)

  const openBuyer = (): void => {
    history.push(
      route('buyer', {
        id: buyer._id,
        tab: 'cart',
      })
    )
  }

  return (
    <Box
      cursor="default"
      position="relative"
      overflow="hidden"
      px="16px"
      py="16px"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        transform={showOptions ? 'translateX(-48%)' : 'translateX(0)'}
        transition="transform 500ms ease-in-out"
      >
        <Flex alignItems="center" justifyContent="center" onClick={openBuyer}>
          <Avatar
            size="sm"
            name={buyer.name.replaceAll(/[^a-zA-Z0-9\s]+/g, '')}
          />
          <Box ml={2}>
            <Text fontWeight="medium">
              {buyer.name || 'N/A'}{' '}
              {buyer?.order_group_count?.tags?.is_active_this_month && (
                <Badge colorScheme="success">Active</Badge>
              )}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {buyer.phone} / {buyer?.location?.name || '-'}
            </Text>
          </Box>
        </Flex>

        {!showOptions && (
          <IconButton
            aria-label="More"
            size="sm"
            variant="ghost"
            icon={<FiMoreVertical size={16} />}
            onClick={() => setShowOptions(!showOptions)}
          />
        )}
      </Flex>

      <FancyOptions isOpen={showOptions} onClose={() => setShowOptions(false)}>
        <FancyOptions.Option
          className="bg-primary text-light"
          icon={<FiPhoneCall size={16} />}
          onClick={() => {
            window?.location?.replace(`tel:+234${buyer.phone.substr(1)}`)
          }}
        />

        <FancyOptions.Option
          className="bg-danger text-light"
          icon={<FiUser size={16} />}
          onClick={() => {
            history.push(
              route('buyer', {
                id: buyer._id,
                tab: 'update',
              })
            )
          }}
        />
      </FancyOptions>
    </Box>
  )
}

export default BuyersListBuyer
