import CoreService, { CoreServiceProps } from '../../core/services/core.service'
import { ICoordinate } from './coordinate.type'

export class CoordinateService extends CoreService<ICoordinate> {
  constructor() {
    super('coordinate')
  }

  fetchByTypeParent(payload): Promise<ICoordinate> {
    const formData = new FormData()

    formData.append('parent_type', payload.parent_type)
    formData.append('parent_id', payload.parent_id)

    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${payload.parent_type}_${payload.parent_id}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_by_type_parent`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else {
                resolve(null)
              }
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const coordinateService = new CoordinateService()
export type CoordinateServiceProps = CoreServiceProps & 'fetchByTypeParent'
