import React from 'react'
import { AUTH, DEAUTH } from '../actionTypes'
import { IAuth as IStoreState } from '../../../applets/user/types/auth.type'

// enum ActionType {
//   Auth = 'increment',
//   Decrement = 'decrement'
// }

interface IAction {
  type: string
  payload: {
    token: string
  }
}

const initialState: IStoreState = { token: null, user: {} }

export const authReducer: React.Reducer<IStoreState, IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AUTH:
      return { ...state, ...action.payload }
    case DEAUTH:
      return initialState
    default:
      return state
  }
}
