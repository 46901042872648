import React from 'react'

import classNames from 'classnames/bind'
import { Nav } from 'react-bootstrap'
import {
  FiHome,
  FiGrid,
  FiShoppingBag,
  FiUsers,
  FiMoreHorizontal,
} from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Role } from 'src/bootstrap/permissions/roles'
import { IStoreState } from 'src/bootstrap/store/types'
import { route } from 'src/core/helpers/route.helper'

import usePendingOrders from 'src/applets/order/hooks/usePendingOrders'

import styles from './BottomNav.module.scss'
import { usePermission } from 'src/core/hooks'
import { Badge } from '@chakra-ui/react'

const cn = classNames.bind(styles)

export const BottomNav: React.FC = () => {
  const { userCan } = usePermission()
  const { user, isAuthenticated, pendingOrders } = useSelector(
    (state: IStoreState) => ({
      user: state.user,
      isAuthenticated: !!state.auth.token,
      pendingOrders: state.global.pendingOrders,
    })
  )

  usePendingOrders(
    user.role === Role.SellerRep ? 'sales_officer' : 'all',
    isAuthenticated,
    user._id
  )

  return (
    <>
      <nav className={cn('bottomNav')}>
        <Nav className={cn('links')}>
          <Nav.Link as={NavLink} to={route('dashboard')} exact>
            <FiHome />
            <span>Home</span>
          </Nav.Link>

          {userCan('view_orders') && (
            <Nav.Link as={NavLink} to={route('pending_orders')}>
              <FiShoppingBag />
              <span>Orders</span>
              {pendingOrders?.length ? (
                <Badge
                  bg="critical.500"
                  color="white"
                  position="absolute"
                  top="3px"
                  right="-15px"
                  borderRadius={99999}
                >
                  {pendingOrders?.length || '0'}
                </Badge>
              ) : null}
            </Nav.Link>
          )}

          {userCan('view_buyers') && (
            <Nav.Link as={NavLink} to={route('buyers')}>
              <FiUsers />
              <span>Retailers</span>
            </Nav.Link>
          )}

          {userCan('view_products') && (
            <Nav.Link as={NavLink} to={route('products')}>
              <FiGrid />
              <span>Products</span>
            </Nav.Link>
          )}

          <Nav.Link as={NavLink} to={route('settings')}>
            <FiMoreHorizontal />
            <span>More</span>
          </Nav.Link>
        </Nav>
      </nav>

      <div className={cn('pad')}></div>
    </>
  )
}

export default BottomNav
