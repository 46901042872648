import React, { useState } from 'react'

import { useMounted, useToast } from 'src/core/hooks'

import { IOrderGroup, IOrderGroupItem } from '../order.type'
import { orderGroupService } from '../services'
import { mapOrderItemsToSellerPrices } from '../order.helper'

type Action = (item: IOrderGroupItem) => Promise<any>

export interface IUseOrderGroupItems {
  orderGroupItems: IOrderGroupItem[]
  addOrderGroupItem: Action
  updateOrderGroupItem: Action
  deleteOrderGroupItem: Action
  isLoadingItems: boolean
  isUpdatingItems: boolean
}

export const useOrderGroupItems = (
  orderGroup: IOrderGroup,
  fulfilmentMap
): IUseOrderGroupItems => {
  const isMounted = useMounted()
  const { toast } = useToast()

  const [isLoadingItems, setIsLoadingItems] = useState<boolean>(true)
  const [isUpdatingItems, setIsUpdatingItems] = useState<boolean>(true)

  const [orderGroupItems, setOrderGroupItems] = useState<IOrderGroupItem[]>()

  React.useEffect(() => {
    const fetchOrderGroupItems = (): void => {
      setIsUpdatingItems(true)

      orderGroupService
        .fetchItems(orderGroup?._id)
        .then((items) => {
          items = mapOrderItemsToSellerPrices(items, fulfilmentMap)
          isMounted.current && setOrderGroupItems(items)
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          isMounted.current && setIsLoadingItems(false)
          isMounted.current && setIsUpdatingItems(false)
        })
    }

    orderGroup?._id && fulfilmentMap && fetchOrderGroupItems()
  }, [isMounted, toast, orderGroup, fulfilmentMap])

  const addOrderGroupItem: Action = (item: IOrderGroupItem) => {
    item = {
      ...item,
      product_id: item.product._id,
      order_group_id: orderGroup._id,
    }

    return new Promise((resolve, reject) => {
      orderGroupService
        .upsertItem(item)
        .then(() => {
          toast({ description: 'Product added to order!', status: 'success' })
          resolve({ amount: orderGroup.amount + item.amount })
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  const updateOrderGroupItem: Action = (item) => {
    return new Promise((resolve, reject) => {
      orderGroupService
        .upsertItem(item)
        .then(() => {
          toast({ description: 'Order product updated', status: 'success' })
          resolve(item)
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  const deleteOrderGroupItem: Action = (item: IOrderGroupItem) => {
    const isLastItem = orderGroupItems?.length || false
    const newAmount = isLastItem ? 0 : orderGroup.amount - item.amount

    return new Promise((resolve, reject) => {
      orderGroupService
        .removeItem({ _id: item._id })
        .then(() => {
          toast({
            description: 'Product removed from order.',
            status: 'success',
          })
          resolve({ amount: newAmount })
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  return {
    orderGroupItems,
    addOrderGroupItem,
    updateOrderGroupItem,
    deleteOrderGroupItem,
    isLoadingItems,
    isUpdatingItems,
  }
}

export default useOrderGroupItems
