import React from 'react'

import { Spinner, Box, Button, Flex, Text, useTheme } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { FiPlus } from 'react-icons/fi'

import OrderGroupTotal from './OrderGroupTotal'
import {
  ErrorBox,
  FloatingButton,
  Panel,
  PanelBody,
  PanelHeader,
} from 'src/core/components'
import { OrderProduct, VisualProductPicker } from 'src/applets/product'
import { OrderGroupContext } from '../contexts'

export const OrderGroupItems: React.FC<any> = () => {
  const { sizes } = useTheme()
  const [pickerModal, setPickerModal] = React.useState<boolean>(false)

  const {
    orderGroup,
    setOrderGroup,
    updateAmount,
    isUpdating,
    orderGroupItems,
    completeOrder,
    addOrderGroupItem,
    updateOrderGroupItem,
    deleteOrderGroupItem,
    isLoadingItems,
    isUpdatingItems,
    isCompleting,
  } = React.useContext(OrderGroupContext)

  return (
    <>
      <Panel borderTop={0}>
        <PanelHeader as={Flex} alignItems="center">
          <Text>
            {orderGroupItems?.length || 0}{' '}
            {pluralize('item', orderGroupItems?.length || 0)}
          </Text>

          {/* Add item */}
          <FloatingButton
            aria-label="Add product"
            colorScheme="success"
            bottom="80px"
            right="24px"
            onClick={() => setPickerModal(true)}
            isDisabled={isLoadingItems || isCompleting}
          />
        </PanelHeader>

        <PanelBody
          px={0}
          py={0}
          opacity={(!isLoadingItems && isUpdatingItems) || isUpdating ? 0.5 : 1}
        >
          {!isLoadingItems && orderGroupItems && orderGroupItems.length ? (
            <>
              {orderGroupItems.map((item, index) => (
                <OrderProduct
                  key={index}
                  item={item}
                  parent={orderGroup}
                  setParent={setOrderGroup}
                  updateItem={updateOrderGroupItem}
                  deleteItem={deleteOrderGroupItem}
                  forceUpdate
                />
              ))}

              <OrderGroupTotal
                orderGroup={orderGroup}
                setOrderGroup={setOrderGroup}
                updateAmount={updateAmount}
                canUpdate={false}
              />

              <Box pb={4} px={sizes.gutter.md}>
                {/* Complete order button */}
                <Button
                  colorScheme="primary"
                  onClick={completeOrder}
                  disabled={isCompleting}
                  isLoading={isCompleting}
                  loadingText="Completing"
                  isFullWidth
                >
                  Complete Order
                </Button>
              </Box>
            </>
          ) : null}

          {!isLoadingItems && (!orderGroupItems || !orderGroupItems.length) ? (
            <Box textAlign="center" my={50}>
              <ErrorBox summary="Cart is empty" />

              <Button
                mt={5}
                size="sm"
                colorScheme="primary"
                leftIcon={<FiPlus size={16} />}
                onClick={() => setPickerModal(true)}
                isDisabled={isLoadingItems || isCompleting}
              >
                Add Product
              </Button>
            </Box>
          ) : null}

          {isLoadingItems && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}
        </PanelBody>
      </Panel>

      {/* {orderGroup.buyer ? (
        <VisualProductPicker
          isOpen={pickerModal}
          location={orderGroup.buyer.location_id}
          selectedItems={orderGroupItems || []}
          addItem={(item) =>
            addOrderGroupItem(item).then((values) => {
              setOrderGroup(values)
            })
          }
          closeModal={() => setPickerModal(false)}
        />
      ) : null} */}

      {orderGroup.buyer ? (
        <VisualProductPicker
          isOpen={pickerModal}
          locationId={orderGroup.buyer.location_id}
          cartItems={orderGroupItems || []}
          addItem={(item) =>
            addOrderGroupItem(item).then((values) => {
              setOrderGroup(values)
            })
          }
          onClose={() => setPickerModal(false)}
        />
      ) : null}
    </>
  )
}

export default OrderGroupItems
