import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import useMounted from 'src/core/hooks/useMounted'
import { useQueryParams } from 'src/core/hooks/useQueryParams'

import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import { sellerStaff } from 'src/bootstrap/permissions/roles'

function useFilterProducts(products: any, user): any {
  const isMounted = useMounted()
  const history = useHistory()

  const [filters, setFilters] = useState<any>({
    status: useQueryParams('status', '*'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 50)),
    search: useQueryParams('search'),
    mftr: {
      value: useQueryParams('mftr_id', '*') || '*',
      label: useQueryParams('mftr_name', 'All Manufacturers'),
    },
    brand: {
      value: useQueryParams('brand_id', '*') || '*',
      label: useQueryParams('brand_name', 'All Brands'),
    },
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        status: query.get('status') || '*',
        page: Number(query.get('page')) || 1,
        per_page: Number(query.get('per_page')) || 50,
        search: query.get('search') || null,
        mftr: {
          value: query.get('mftr_id') || '*',
          label: query.get('mftr_name') || 'All Manufacturers',
        },
        brand: {
          value: query.get('brand_id') || '*',
          label: query.get('brand_name') || 'All Brands',
        },
      })
    })

    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ mftr, brand, ...params }) => {
      history.push(
        route('products', null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          mftr_id: mftr?.value !== '*' ? mftr?.value : null,
          mftr_name: mftr?.value !== '*' ? mftr?.label : null,
          brand_id: brand?.value !== '*' ? brand?.value : null,
          brand_name: brand?.value !== '*' ? brand?.label : null,
        })
      )
    },
    [history]
  )

  const filteredProducts = React.useMemo(() => {
    let filtered = products || []

    if (filtered.length) {
      /**
       * Add sellers [array] and compound_status
       */
      filtered = filtered.map((product) => {
        let sellerActive = false

        if (sellerStaff.includes(user.role)) {
          /**
           * If user is a seller staff, lets check if the
           * seller's product is active.
           */
          sellerActive = product.sellers
            ? product?.sellers?.[user.seller_id]?.status === 'active'
            : false
        } else {
          /**
           * Else for higher users, we just need to check
           * that at least one seller product is active.
           */
          const sellerProducts: any[] = product.sellers
            ? Object.values(product.sellers)
            : []

          sellerActive = sellerProducts.length
            ? sellerProducts.some(({ status }) => status === 'active')
            : false
        }

        const mftrActive = product.manufacturer.status === 'active'
        const brandActive = product.brand.status === 'active'
        const productActive = product.status === 'active'

        return {
          ...product,
          compound_status:
            mftrActive && brandActive && sellerActive && productActive
              ? 'active'
              : 'inactive',
        }
      })

      if (filters.status && filters.status !== '*') {
        filtered = filtered.filter(
          (product) => product.compound_status === filters.status
        )
      }

      const { brand, mftr } = filters
      if (brand && brand.value !== '*') {
        filtered = filtered.filter(
          (product) => product.brand._id === brand.value
        )
      } else if (mftr && mftr.value !== '*') {
        filtered = filtered.filter(
          (product) => product.manufacturer._id === mftr.value
        )
      }

      if (filters.search) {
        filtered = filtered.filter((product) => {
          return (
            basicSearch(product.name, filters.search) ||
            basicSearch(product.status, filters.search)
          )
        })
      }
    }

    return filtered
  }, [products, filters])

  const activeFilters = React.useMemo(() => {
    const activeFilters: string[] = []

    if (filters.status && filters.status !== '*') {
      activeFilters.push(`Status (${filters.status})`)
    }

    const { brand } = filters
    if (brand && brand.value !== '*') {
      activeFilters.push(`Brand (${brand.label})`)
    }

    const { mftr } = filters
    if (mftr && mftr.value !== '*') {
      activeFilters.push(`Mftr. (${mftr.label})`)
    }

    return activeFilters
  }, [filters])

  return {
    filters,
    activeFilters,
    filteredProducts,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useFilterProducts
