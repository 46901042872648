import React from 'react'

import { HStack, Button, StackProps } from '@chakra-ui/react'
import {
  Pagination as CorePagination,
  PaginationProps as CorePaginationProps,
  PaginationItemProps,
} from 'react-stitches-paginator'

interface PaginationProps extends CorePaginationProps, StackProps {}
export const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <CorePagination
      as={HStack}
      spacing="10px"
      justifyContent="center"
      labels={{ first: false, last: false }}
      itemComponent={PaginationItem}
      {...props}
    />
  )
}

const PaginationItem: React.FC<PaginationItemProps> = (props) => {
  return (
    <Button
      size="sm"
      variant={props.isActive ? 'solid' : 'outline'}
      colorScheme={props.isActive ? 'primary' : 'gray'}
      isDisabled={props.isDisabled}
      onClick={props.onClick}
      transition="none"
    >
      {props.children}
    </Button>
  )
}

Pagination.defaultProps = {
  itemsPerPage: 10,
  delta: 1,
}

export default Pagination
