import React from 'react'
import useFetch from 'src/core/hooks/useFetch'
import {
  ILedger,
  LedgerHead,
  LedgerSubhead,
  ledgerService,
  LedgerServiceProps,
} from 'src/applets/ledger'

interface IProps {
  /** User ID */
  owner: string
  head: LedgerHead
  subhead: LedgerSubhead
}

export const useLedger = (
  props: IProps,
  condition?: boolean
): [ILedger, boolean, any, React.Dispatch<ILedger>] => {
  const ledgerPayload = {
    head: props.head,
    subhead: props.subhead,
    owner: props.owner,
  }

  const [ledger, isLoadingLedger, error, setLedger] = useFetch<
    ILedger,
    LedgerServiceProps
  >(ledgerService, 'fetchByOwnerSpecific', [ledgerPayload], condition)

  return [ledger, isLoadingLedger, error, setLedger]
}
