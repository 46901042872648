import React from 'react'

import useUpdateOrder from './useUpdateOrder'
import useMounted from 'src/core/hooks/useMounted'
import useToast from 'src/core/hooks/useToast'

import { IOrder, IOrderItem, OrderTag } from '../order.type'
import { mapOrderItemsToSellerPrices } from '../order.helper'
import { orderService } from '../services'

type Action = (item: IOrderItem) => Promise<any>

export interface IUseOrderItems {
  orderItems?: IOrderItem[]
  addOrderItem?: Action
  updateOrderItem?: Action
  deleteOrderItem?: Action
  isLoadingItems?: boolean
  isUpdatingItems?: boolean
}

export const useOrderItems = (order: IOrder, fulfilmentMap): IUseOrderItems => {
  const isMounted = useMounted()
  const { toast } = useToast()

  const [isLoadingItems, setIsLoadingItems] = React.useState<boolean>(true)
  const [isUpdatingItems, setIsUpdatingItems] = React.useState<boolean>(true)

  const { updateTag } = useUpdateOrder(order)

  const [orderItems, setOrderItems] = React.useState<IOrderItem[]>()

  React.useEffect(() => {
    const fetchOrderGroupItems = (): void => {
      setIsUpdatingItems(true)

      orderService
        .fetchItems(order._id)
        .then((items) => {
          items = mapOrderItemsToSellerPrices(items, fulfilmentMap)
          isMounted.current && setOrderItems(items)
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
        })
        .finally(() => {
          isMounted.current && setIsLoadingItems(false)
          isMounted.current && setIsUpdatingItems(false)
        })
    }

    order?._id && fulfilmentMap && fetchOrderGroupItems()
  }, [isMounted, toast, order, fulfilmentMap])

  const addOrderItem: Action = (item: IOrderItem) => {
    item = {
      ...item,
      product_id: item.product._id,
      order_id: order._id,
    }

    return new Promise((resolve, reject) => {
      orderService
        .upsertItem(item)
        .then(() => {
          if (order.tag !== OrderTag.Completed) {
            updateTag(OrderTag.Completed).finally(() => {
              toast({
                description: 'Product added to order!',
                status: 'success',
              })
              resolve({
                tag: OrderTag.Completed,
                amount: order.amount + item.amount,
              })
            })
          } else {
            toast({ description: 'Product added to order!', status: 'success' })
            resolve({ amount: order.amount + item.amount })
          }
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  const updateOrderItem: Action = (item) => {
    return new Promise((resolve, reject) => {
      orderService
        .upsertItem(item)
        .then(() => {
          toast({ description: 'Order item updated', status: 'success' })
          resolve(item)
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  const deleteOrderItem: Action = (item: IOrderItem) => {
    const isLastItem = orderItems ? orderItems.length === 1 : false
    const newAmount = isLastItem ? 0 : order.amount - item.amount

    return new Promise((resolve, reject) => {
      // Delete item
      orderService
        .removeItem({ _id: item._id })
        .then(() => {
          if (isLastItem) {
            /**
             * When the last item in an order is deleted,
             * we need to change the tag to 'deleted' and also
             * set the amount to 0, that's why arg2 is 'true'
             */
            updateTag(OrderTag.Cancelled).finally(() => {
              resolve({ tag: OrderTag.Cancelled, amount: newAmount })
            })
          } else {
            toast({
              description: 'Product removed from order.',
              status: 'success',
            })
            resolve({ amount: newAmount })
          }
        })
        .catch(({ message }) => {
          toast({ description: message, status: 'error' })
          reject()
        })
    })
  }

  return {
    orderItems,
    addOrderItem,
    updateOrderItem,
    deleteOrderItem,
    isLoadingItems,
    isUpdatingItems,
  }
}

export default useOrderItems
