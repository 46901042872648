import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UserService } from '../user.service'
import { AUTH, SET_GLOBAL, SET_USER } from 'src/bootstrap/store/actionTypes'
import { IStoreState } from 'src/bootstrap/store/types'
import { setToken } from 'src/core/helpers/http.helper'

const userService = new UserService()

const useAuth = (isOnline): void => {
  const dispatch = useDispatch()
  const { user, token } = useSelector((state: IStoreState) => {
    return {
      user: state.user,
      token: state.auth.token,
    }
  })

  React.useEffect(() => {
    const fetchUser = (): void => {
      setToken(token)

      // Set global loader
      dispatch({ type: SET_GLOBAL, payload: { isLoading: true } })

      userService
        .fetchUser(token)
        .then((user) => {
          dispatch({
            type: SET_USER,
            payload: user,
          })
        })
        .catch(() => {
          // On fail, just clear token to avoid repeat
          dispatch({ type: AUTH, payload: { token: null } })
        })
        .finally(() => {
          // Reset global loader
          dispatch({ type: SET_GLOBAL, payload: { isLoading: false } })
        })
    }

    if (token && !user._id) {
      isOnline && fetchUser()
    } else {
      // Reset global loader
      dispatch({ type: SET_GLOBAL, payload: { isLoading: false } })
    }
  }, [dispatch, isOnline, token, user._id])
}

export default useAuth
