import React from 'react'

import {
  Badge,
  Box,
  Flex,
  Spinner,
  StackDivider,
  VStack,
} from '@chakra-ui/react'
import { Tabs, TabList, Tab } from '@chakra-ui/tabs'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { ErrorBox, Layout, Header } from 'src/core/components'
import { Panel, PanelHeader, PanelBody } from 'src/core/components/Panel'
import {
  ProductForm,
  SellerProductForm,
  ProductsListProduct,
} from 'src/applets/product/components'

import { sellerStaff } from 'src/bootstrap/permissions/roles'

import { useMounted, usePermission } from 'src/core/hooks'
import { ProductService } from '../product.service'
import { IProduct, ISellerProduct } from '../product.type'
import { IStoreState } from 'src/bootstrap/store/types'

const productService = new ProductService()

enum TabType {
  Update,
  Misc,
}

const Product: React.FC = () => {
  const isMounted = useMounted()
  const history: any = useHistory()
  const { id: product_id, tab }: any = useParams()

  const { userCan } = usePermission()

  const user = useSelector((state: IStoreState) => state.user)

  const [tabIndex, setTabIndex] = React.useState(
    tab === 'update' ? TabType.Update : TabType.Misc
  )

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [product, setProduct] = React.useState<IProduct>()
  const [sellerProduct, setSellerProduct] = React.useState<ISellerProduct>()

  React.useEffect(() => {
    const fetchProduct = (): void => {
      productService
        .fetchById(product_id)
        .then((product) => {
          isMounted.current && setProduct(product)

          if (sellerStaff.includes(user.role)) {
            productService
              .fetchSellerProduct(user.seller_id, product._id)
              .then((sellerProduct) => {
                isMounted.current && setSellerProduct(sellerProduct)
              })
              .finally(() => {
                isMounted.current && setIsLoading(false)
              })
          } else {
            isMounted.current && setIsLoading(false)
          }
        })
        .catch(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('update_product') && fetchProduct()
  }, [userCan, product_id, isMounted, user])

  return (
    <Layout>
      <Header title="View Product" onBack={history.goBack} />
      {!!product && (
        <Panel p="16px" borderTop={0}>
          <ProductsListProduct product={product} isLast={true} />
        </Panel>
      )}

      <Panel mt={2}>
        <PanelHeader pb="1px">
          <Tabs
            defaultIndex={tabIndex}
            onChange={(tabIndex) => {
              setTabIndex(tabIndex)
            }}
          >
            <TabList border={0}>
              <Tab>Update</Tab>
              <Tab>Misc</Tab>
            </TabList>
          </Tabs>
        </PanelHeader>

        {!isLoading && product ? (
          <ProductPageContext.Provider value={{}}>
            <PanelBody>
              {tabIndex === TabType.Update && (
                <>
                  {sellerStaff.includes(user.role) ? (
                    <SellerProductForm
                      product={product}
                      updateProduct={(updated) => {
                        setProduct({ ...product, ...updated })
                      }}
                      seller={user.seller}
                      sellerProduct={sellerProduct}
                      updateSellerProduct={(updated) => {
                        setSellerProduct({ ...sellerProduct, ...updated })
                      }}
                    />
                  ) : (
                    <ProductForm
                      product={product}
                      updateProduct={(updated) => {
                        setProduct({ ...product, ...updated })
                      }}
                    />
                  )}
                </>
              )}

              {tabIndex === TabType.Misc && (
                <VStack
                  divider={<StackDivider borderColor="gray.100" />}
                  spacing={5}
                  align="stretch"
                  my={5}
                >
                  {/* Manufacturer */}
                  {[
                    {
                      label: 'Manufacturer',
                      status: product.manufacturer.status,
                    },
                    { label: 'Brand', status: product.brand.status },
                    { label: 'Product', status: product.status },
                  ].map(({ label, status }) => (
                    <Flex key={label}>
                      <Box flex={2}>{label}</Box>
                      <Flex flex={1} justifyContent="flex-end">
                        <Badge
                          colorScheme={
                            status === 'active' ? 'success' : 'critical'
                          }
                        >
                          {status}
                        </Badge>
                      </Flex>
                    </Flex>
                  ))}

                  {product?.sellers
                    ? Object.values(product.sellers).map((product) => (
                        <Flex key={product.seller_id}>
                          <Box flex={2}>
                            {product?.seller?.name || 'Seller'}
                          </Box>
                          <Flex flex={1} justifyContent="flex-end">
                            <Badge
                              colorScheme={
                                product.status === 'active'
                                  ? 'success'
                                  : 'critical'
                              }
                            >
                              {product.status}
                            </Badge>
                          </Flex>
                        </Flex>
                      ))
                    : null}
                </VStack>
              )}
            </PanelBody>
          </ProductPageContext.Provider>
        ) : null}
      </Panel>

      {!isLoading && !product ? (
        <ErrorBox summary="Product does not exist" my={50} />
      ) : null}

      {isLoading && (
        <Panel as={Flex} justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Panel>
      )}
    </Layout>
  )
}

export const ProductPageContext = React.createContext(null)
export default Product
