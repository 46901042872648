import React from 'react'

import { OrdersListOrder } from 'src/applets/order'

import {
  ErrorBox,
  Pagination,
  PanelBody,
  PanelHeader,
  SearchBar,
} from 'src/core/components'

import useFetch from 'src/core/hooks/useFetch'
import useOrderGroupsFilter from 'src/applets/order/hooks/useFilterOrderGroups'

import { IBuyer } from 'src/applets/buyer/buyer.type'
import { BuyerPageContext } from 'src/applets/buyer/screens/Buyer.screen'

import { orderGroupService } from 'src/applets/order'
import { paginate } from 'src/core/helpers/filters.helper'
import { Box, Flex, StackDivider, VStack } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

interface IProps {
  buyer: IBuyer
}

const BuyerOrders: React.FC<IProps> = ({ buyer }) => {
  const { setOrderCount } = React.useContext(BuyerPageContext)

  const [orderGroups, isLoading] = useFetch(orderGroupService, 'fetchByBuyer', [
    buyer._id,
  ])

  const { filteredOrderGroups, filters, setFilters, setPage } =
    useOrderGroupsFilter(orderGroups, 'buyer', {
      id: buyer._id,
      tab: 'orders',
    })

  React.useEffect(() => {
    filteredOrderGroups?.length && setOrderCount(filteredOrderGroups.length)
  }, [filteredOrderGroups])

  const paginatedOrderGroups = React.useMemo(() => {
    return filteredOrderGroups
      ? paginate(filteredOrderGroups, filters.page, 5)
      : []
  }, [filters.page, filteredOrderGroups])

  return (
    <>
      <PanelHeader>
        <SearchBar
          placeholder="Search for a buyer's order"
          value={filters.search}
          onChange={(search) => setFilters({ search, page: 1 })}
          isDisabled={isLoading || !filteredOrderGroups}
        />
      </PanelHeader>
      <PanelBody>
        {isLoading && (
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        )}

        {!isLoading && !paginatedOrderGroups.length ? (
          <ErrorBox summary="No orders found for this buyer." my={50} />
        ) : null}

        {!isLoading && paginatedOrderGroups.length ? (
          <>
            <VStack
              divider={<StackDivider borderColor="gray.100" />}
              spacing={3}
            >
              {paginatedOrderGroups.map((orderGroup) => (
                <Box key={orderGroup._id} w="100%">
                  <OrdersListOrder orderGroup={orderGroup} page="buyer" />
                </Box>
              ))}
            </VStack>

            <Pagination
              itemsPerPage={5}
              totalItems={filteredOrderGroups.length}
              currentPage={filters.page}
              onPageChange={(page) => setPage(page)}
              my={5}
            />
          </>
        ) : null}
      </PanelBody>
    </>
  )
}

export default BuyerOrders
