import React from 'react'

import useMounted from 'src/core/hooks/useMounted'
import { ProductService } from '../product.service'
import { IProduct } from '../product.type'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { IManufacturer } from 'src/applets/manufacturer'
import { IBrand } from 'src/applets/brand'

const productService = new ProductService()

type IUseAvailableProducts = [
  {
    products: (IProduct & { value: string; label: string })[]
    manufacturers: (IManufacturer & { value: string; label: string })[]
    brands: (IBrand & { value: string; label: string })[]
  },
  boolean
]

const hookActivity = {}

export const useAvailableProducts = (
  locationId,
  filters?: any
): IUseAvailableProducts => {
  const isMounted = useMounted()
  const [products, setProducts] = React.useState<any[]>()
  const [manufacturers, setManufacturers] = React.useState<any[]>()
  const [brands, setBrands] = React.useState<any[]>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    setIsLoading(true)

    const fetchAvailable = (): void => {
      productService
        .fetchAvailableMore({ location_id: locationId, page: 0, limit: 0 })
        .then(({ products, brands, manufacturers }) => {
          if (isMounted.current) {
            setProducts(products.filter(({ status }) => status === 'active'))
            setManufacturers(
              manufacturers.filter(({ status }) => status === 'active')
            )
            setBrands(brands.filter(({ status }) => status === 'active'))
          }
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
          hookActivity[locationId] = false
        })
    }

    if (locationId && !hookActivity?.[locationId]) {
      fetchAvailable()
      hookActivity[locationId] = true
    }
  }, [isMounted, locationId])

  const filteredProducts = React.useMemo(() => {
    if (filters?.brandId && products?.length) {
      let tempProducts = products

      if (filters?.brandId) {
        tempProducts = tempProducts.filter(({ brand_id, seller_product }) => {
          return (
            brand_id === filters?.brandId && seller_product.status === 'active'
          )
        })
      }

      return tempProducts.map(({ seller_product, ...product }) => {
        const price = seller_product?.price || product?.price

        return {
          ...product,
          value: product._id,
          label: `${product.name} (NGN ${formatCurrency(price)})`,
          mpu: seller_product?.mpu || product.mpu,
          status: seller_product?.status || product.status,
          price,
        }
      })
    }

    return []
  }, [filters?.brandId, products])

  const filteredBrands = React.useMemo(() => {
    if (brands?.length) {
      let tempBrands = brands

      if (filters?.manufacturerId) {
        tempBrands = tempBrands.filter((brand) => {
          return brand.manufacturer_id === filters?.manufacturerId
        })
      }

      return tempBrands.map((brand) => ({
        ...brand,
        value: brand._id,
        label: brand.name,
      }))
    }

    return []
  }, [filters?.manufacturerId, brands])

  const filteredManufacturers = React.useMemo(() => {
    if (manufacturers?.length) {
      return manufacturers.map((manufacturers) => ({
        ...manufacturers,
        value: manufacturers._id,
        label: manufacturers.name,
      }))
    }

    return []
  }, [manufacturers])

  return [
    {
      products: filteredProducts,
      manufacturers: filteredManufacturers,
      brands: filteredBrands,
    },
    isLoading,
  ]
}

export default useAvailableProducts
