import cache from '../../core/helpers/cache.helper'
import { http } from '../../core/helpers/http.helper'
import { IAgent } from './agent.type'

export class AgentService {
  fetch(): Promise<IAgent[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('agents')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('agent/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('agents', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchById(agentId: string): Promise<IAgent> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get(`agent_${agentId}`)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`agent/read/?_id=${agentId}`).then(({ data }) => {
            if (data.code === 200 && data.data.length) {
              cache.set(`agent_${agentId}`, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchReferralsByAgent(agentId: string): Promise<any[]> {
    const formData = new FormData()
    formData.append('agent_id', agentId)

    return new Promise((resolve, reject) => {
      const cachedData = cache.get(`agent_referrals_${agentId}`)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`agent_referral/read_by_agent`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(`agent_referrals_${agentId}`, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  createReferral(data): Promise<any> {
    const formData = new FormData()
    formData.append('phone', data.phone)
    formData.append('referral', data.referral)
    formData.append('status', 'unverified')

    return new Promise((resolve, reject) => {
      try {
        http.post('agent_referral/create_phone', formData).then(({ data }) => {
          if (data.code === 200) resolve(data.data)
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  creditReferral(buyerPhone): Promise<any> {
    const formData = new FormData()
    formData.append('buyer_phone', buyerPhone)

    return new Promise((resolve, reject) => {
      try {
        http.post('agent/credit_referral', formData).then(({ data }) => {
          if (data.code === 200) {
            /**
             * In case the user navigates to the dashboard from
             * here, we need to be sure it's refresh for new data
             */
            cache.del('current_user')
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  /**
   * Sign up new agent.
   *
   * @param agent
   */
  signup(agent: IAgent): Promise<IAgent> {
    const formData = new FormData()
    formData.append('hash', 'password') // default

    formData.append('name', agent.name)
    formData.append('phone', agent.phone)
    formData.append('status', agent.status)
    formData.append('source', agent.source)
    formData.append('bank', agent.bank.toString())
    formData.append('account', agent.account)

    // relationships
    formData.append('location_id', agent.location_id)

    return new Promise((resolve, reject) => {
      try {
        http.post('agent/signup', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.del('agents')
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  /**
   * Sign up already existing agent.
   * Agents created before July 1, 2020 had no passwords
   *
   * @param agent
   */
  signupThisUp(agent: IAgent): Promise<IAgent> {
    const formData = new FormData()
    formData.append('hash', 'password') // default
    formData.append('phone', agent.phone)

    // relationships
    formData.append('location_id', agent.location_id)

    return new Promise((resolve, reject) => {
      try {
        http.post('agent/sign_this_up', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.del('agents')
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  /**
   * Update agent.
   *
   * @param agent
   */
  update(agent: IAgent): Promise<IAgent> {
    const formData = new FormData()

    formData.append('_id', agent._id)
    formData.append('user_id', agent.user_id)
    formData.append('name', agent.name)
    formData.append('phone', agent.phone)
    formData.append('status', agent.status)
    formData.append('source', agent.source)
    formData.append('bank', agent.bank.toString())
    formData.append('account', agent.account)

    // relationships
    formData.append('location_id', agent.location_id)

    return new Promise((resolve, reject) => {
      try {
        http.post('agent/update', formData).then(({ data }) => {
          if (data.code === 200) resolve(data.data)
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  // ------- Report Methods --------
  private _sortDates = (data): any[] => {
    const dates = Object.keys(data)
    return dates.sort((a, b) => {
      a = a.padStart(10, '0').split('-').reverse().join('')
      b = b.padStart(10, '0').split('-').reverse().join('')
      return a > b ? 1 : a < b ? -1 : 0
    })
  }

  private _sortReports = (data): any[] => {
    return Object.values(data)
  }

  private _sortSources = (data): any[] => {
    let sources = []
    Object.values(data).forEach((item) => {
      sources = [...sources, ...Object.keys(item)]
    })
    sources = sources.sort()
    return Array.from(new Set(sources))
  }

  fetchReport(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        http.get('agent/report').then(({ data }) => {
          const agents = {
            dates: this._sortDates(data.data.agents),
            reports: this._sortReports(data.data.agents),
            sources: this._sortSources(data.data.agents),
          }

          const retailers = {
            dates: this._sortDates(data.data.retailers),
            reports: this._sortReports(data.data.retailers),
            sources: this._sortSources(data.data.retailers),
          }

          const orders = {
            dates: this._sortDates(data.data.orders),
            reports: this._sortReports(data.data.orders),
            sources: this._sortSources(data.data.orders),
          }

          resolve({ agents, retailers, orders })
        })
      } catch (error) {
        reject(error)
        throw error
      }
    })
  }

  fetchReportBySource(
    startUnix: string,
    endUnix: string,
    period: any,
    source: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('from_timestamp', startUnix)
      formData.append('to_timestamp', endUnix)
      formData.append('period', period)
      formData.append('source', source)

      try {
        http.post('agent/source_summary', formData).then(({ data }) => {
          if (data.code === 200 && data.data.dates) {
            const agents = data.data.agents

            const timestreams_array = Object.keys(data.data.dates) // for table head timestamps
            const agents_array = [] // for main table data {agent, agent timestreams}
            const timestream_totals = {}
            timestreams_array.forEach((timestamp) => {
              timestream_totals[timestamp] = {
                orders: 0,
                buyers: 0,
                referrals: 0,
              }
            })

            // for cumulative table totals
            const cumulative = {
              orders: 0,
              buyers: 0,
              referrals: 0,
            }

            Object.values(agents).forEach((agent: any) => {
              // store cumulative totals
              let orders_total = 0
              let buyers_total = 0
              let referrals_total = 0

              const agent_timestreams = []
              Object.entries(agent.timestream).forEach(
                ([timestamp, referrals_data]: any) => {
                  // agent - timestream totals
                  let orders = 0
                  let buyers = 0
                  let referrals = 0
                  const buyers_array = []
                  const referrals_array = []

                  referrals_data.forEach((referral) => {
                    referrals++
                    referrals_array.push(referral.referral)
                    if (referral.status === 'verified') {
                      buyers++
                      if (referral.buyer[0].order_group_count) {
                        const count = referral.buyer[0].order_group_count.count
                        orders +=
                          count.completed + count.pending + count.cancelled
                      }

                      buyers_array.push(referral.buyer[0])
                    }
                  })

                  // timestamp totals
                  agent_timestreams.push({
                    orders,
                    buyers,
                    referrals,
                    buyersList: buyers_array,
                    referralsList: referrals_array,
                  })

                  // add up agents cumulative totals
                  orders_total += orders
                  buyers_total += buyers
                  referrals_total += referrals

                  timestream_totals[timestamp].orders += orders
                  timestream_totals[timestamp].buyers += buyers
                  timestream_totals[timestamp].referrals += referrals
                }
              )

              agents_array.push({
                name: agent.name,
                phone: agent.phone,
                timestreams: agent_timestreams,
                orders: orders_total,
                buyers: buyers_total,
                referrals: referrals_total,
              })

              cumulative.orders += orders_total
              cumulative.buyers += buyers_total
              cumulative.referrals += referrals_total
            })

            resolve({
              timestreams: timestreams_array,
              agents: agents_array,
              totals: Object.values(timestream_totals), // timestream totals for all agents
              cumulative, // cumulative totals for all agents
            })
          } else reject()
        })
      } catch (error) {
        reject(error)
        throw error
      }
    })
  }
}

export const agentService = new AgentService()
