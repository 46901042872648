import Buyer from 'src/applets/buyer/screens/Buyer.screen'
import Buyers from 'src/applets/buyer/screens/Buyers.screen'
import BuyerSignup from 'src/applets/buyer/screens/BuyerSignup.screen'

import { IRoute } from 'src/bootstrap/routes'

export const buyerRoutes: IRoute[] = [
  {
    id: 'buyers',
    path: '/buyers',
    component: Buyers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'buyer_signup',
    path: '/buyers/signup',
    component: BuyerSignup,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'buyer',
    path: '/buyers/:id/:tab',
    component: Buyer,
    requiresAuth: true,
    exact: true,
  },
]

export default buyerRoutes
