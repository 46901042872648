import React from 'react'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'

export const usePermission = (): any => {
  const { permissions } = useSelector((state: IStoreState) => state.user)

  const userCan = React.useCallback(
    (permission: string): boolean => {
      return permissions?.includes(permission)
    },
    [permissions]
  )

  return { userCan }
}

export default usePermission
