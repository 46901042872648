import React from 'react'

import { Box, Flex, Grid, Heading, IconButton, Text } from '@chakra-ui/react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { FiChevronLeft, FiRotateCw } from 'react-icons/fi'

import useMounted from 'src/core/hooks/useMounted'

export interface HeaderProps {
  title: string
  subtitle?: string
  metaTitle?: string
  hideTitle?: boolean
  onBack?: () => void
}

export const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  metaTitle,
  hideTitle,
  onBack,
}) => {
  const isMounted = useMounted()

  const [scrolling, setScrolling] = React.useState<boolean>(false)

  React.useEffect(() => {
    document.addEventListener(
      'scroll',
      _.debounce(() => {
        isMounted.current && setScrolling(window.scrollY > 0)
      }, 10)
    )
  }, [isMounted])

  return (
    <>
      <Flex
        as="header"
        position="fixed"
        paddingX="16px"
        top="0"
        left="0"
        height="60px"
        width="100%"
        bg="white"
        alignItems="center"
        borderBottom="1px solid"
        borderColor={scrolling ? 'white' : 'gray.100'}
        boxShadow={scrolling ? 'rgba(0, 0, 0, 0.06) 0px 1px 12px' : 'none'}
        zIndex={10}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle ? metaTitle : title} - Suplias Go</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <Grid width="100%" templateColumns="1fr 3fr 1fr" alignItems="center">
          <Box flexBasis="20%">
            {!!onBack && (
              <IconButton
                size="sm"
                variant="ghost"
                icon={<FiChevronLeft size={24} />}
                onClick={onBack}
                aria-label="Go back"
              />
            )}
          </Box>

          <Box flexBasis="60%" textAlign="center">
            {!hideTitle && (
              <>
                <Heading fontWeight="medium" fontSize="15px">
                  {title}
                </Heading>
                {!!subtitle && (
                  <Text fontSize="sm" color="gray.600" noOfLines={1}>
                    {subtitle}
                  </Text>
                )}
              </>
            )}
          </Box>

          <Flex flexBasis="20%" justifyContent="flex-end">
            <IconButton
              aria-label="Refresh"
              size="sm"
              variant="ghost"
              icon={<FiRotateCw size={20} />}
              onClick={(event) => {
                event.preventDefault()
                window.location.reload()
              }}
            />
          </Flex>
        </Grid>
      </Flex>

      <Box height="60px" />
    </>
  )
}

Header.defaultProps = {
  hideTitle: false,
}

export default Header
