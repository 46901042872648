export const formatCurrency = (
  amount: number,
  settings?: { decimals?: number; showCurrency?: boolean; notation?: string }
): any => {
  if (isNaN(amount)) return false

  const options: any = {
    minimumFractionDigits: settings?.decimals ?? 2,
    maximumFractionDigits: 2,
  }

  if (settings?.showCurrency) {
    options.style = 'currency'
    options.currency = 'NGN'
  }

  if (settings?.notation) {
    options.notation = settings.notation
  }

  return new Intl.NumberFormat('en-NG', options).format(amount)
}
