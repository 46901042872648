import React, { useState } from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import PendingOrdersList from './PendingOrdersList'
import { Panel, PanelBody, PanelHeader, SearchBar } from 'src/core/components'

import { Role } from 'src/bootstrap/permissions/roles'
import usePendingOrders from 'src/applets/order/hooks/usePendingOrders'

import { IStoreState } from 'src/bootstrap/store/types'

export const PendingOrders: React.FC = () => {
  const { user } = useSelector((state: IStoreState) => ({
    user: state.user,
  }))

  const [search, setSearch] = useState<string>()

  const [pendingOrders, isLoading] = usePendingOrders(
    user.role === Role.SellerRep ? 'sales_officer' : 'all',
    !!user,
    user._id,
    search
  )

  return (
    <>
      {isLoading ? (
        <Panel borderTop={0}>
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        </Panel>
      ) : (
        <Panel borderTop={0}>
          <PanelHeader>
            <SearchBar
              value={search}
              placeholder="Search by buyer name, phone, order ref..."
              onChange={setSearch}
            />
          </PanelHeader>

          <PanelBody>
            <PendingOrdersList orderGroups={pendingOrders} />
          </PanelBody>
        </Panel>
      )}
    </>
  )
}
