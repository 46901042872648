import React from 'react'

import { VStack, Button } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { FormikField, FormikRadioGroup, FormStack } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { IProduct } from 'src/applets/product/product.type'

import { productService } from '../../product.service'
import styles from './ProductForm.module.scss'

interface IProps {
  product?: IProduct
  updateProduct?: (updatedValues: IProduct) => void
}

export const ProductForm: React.FC<IProps> = ({ product, updateProduct }) => {
  const { addToast } = useToast()

  const formConfig = {
    initialValues: {
      name: product?.name || '',
      description: product?.description || '',
      status: product?.status || 'active',
      sku_id: product?.sku_id || '',
      case_count: product?.case_count || '',
      mpu: product?.mpu || '',
      price: product?.price || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      description: Yup.string()
        .required('Description name field is required.')
        .min(10, 'Description name must be at least 10 characters.'),
      status: Yup.string().required('Status field is required.'),
      sku_id: Yup.string().required('SKU ID field is required.'),
      case_count: Yup.number().required('Case count field is required.'),
      mpu: Yup.number()
        .test(
          'case-count-is-multiple',
          'Case count must be multiple of MPU',
          function (value) {
            return this.parent.case_count % value === 0
          }
        )
        .required('MPU field is required.'),
      price: Yup.number().required('Price field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        _id: product._id,
        name: values.name,
        description: values.description,
        status: values.status,
        sku_id: values.sku_id,
        case_count: values.case_count,
        mpu: values.mpu,
        price: values.price,
        brand_id: product.brand_id,
        weight: 0,
        size: 0,
        upload_id: product.upload_id,
      }

      productService
        .update({ ...finalValues })
        .then(() => {
          setSubmitting(false)

          addToast('Product successfully updated.', { appearance: 'success' })
          updateProduct({ ...finalValues })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form className={styles.form} onSubmit={handleSubmit}>
          <VStack spacing={3} align="stretch">
            {/* Product status */}
            <FormStack label="Status" isRequired>
              <FormikRadioGroup
                name="status"
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
              />
            </FormStack>

            {/* Product name */}
            <FormStack label="Name" isRequired>
              <FormikField name="name" rows={3} />
            </FormStack>

            {/* Product description */}
            <FormStack label="Description" isRequired>
              <FormikField as="textarea" name="description" rows={3} />
            </FormStack>

            {/* SKU ID */}
            <FormStack label="SKU ID" isRequired>
              <FormikField name="sku_id" type="text" />
            </FormStack>

            {/* Product case count */}
            <FormStack label="Case Count" isRequired>
              <FormikField name="case_count" type="number" />
            </FormStack>

            {/* Product MPU */}
            <FormStack label="MPU" isRequired>
              <FormikField name="mpu" type="number" />
            </FormStack>

            {/* Product price */}
            <FormStack label="Price" isRequired>
              <FormikField name="price" type="number" prepend="NGN" />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            my={5}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            width="100%"
            isLoading={formik.isSubmitting}
            loadingText="Updating"
          >
            Update Product
          </Button>
        </Form>
      )}
    </Formik>
  )
}
