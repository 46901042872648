import React, { useState, useEffect } from 'react'
// import useToast from 'src/core/hooks/useToast'
import { useAvailableProducts } from './useAvailableProducts'

interface IUseVisualProductPicker {
  isLoading?: boolean
  available: {
    manufacturers?: any
    brands?: any
    products?: any
  }
  selected: {
    manufacturer: any
    brand: any
    product: any
  }
  setSelected: React.Dispatch<{
    manufacturer?: any
    brand?: any
    product?: any
  }>
  productQuantity: number
  setProductQuantity: React.Dispatch<number>
}

export const useVisualProductPicker = (locationId): IUseVisualProductPicker => {
  // const { addToast } = useToast()

  // Indicators , I guess that's what they are called
  // const [isAdding, setIsAdding] = useState<boolean>(false)

  // Selected options (objects), usually with value, label keys
  const [selected, setSelected] = useState<any>({
    manufacturer: null,
    brand: null,
    product: null,
  })
  const [productQuantity, setProductQuantity] = useState<number>()

  const [available, isLoading] = useAvailableProducts(locationId, {
    manufacturerId: selected?.manufacturer?.value,
    brandId: selected?.brand?.value,
  })

  /**
   * Everytime a product is selected, reset the
   * product quantity, if selection is not from cart
   */
  useEffect(() => {
    if (selected?.product && !selected?.product.in_cart) {
      setProductQuantity(selected.product.mpu)
    }
  }, [selected?.product])

  return {
    isLoading,
    available,
    selected,
    setSelected,
    productQuantity,
    setProductQuantity,
  }
}
