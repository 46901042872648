import cache from '../../core/helpers/cache.helper'
import { http } from '../../core/helpers/http.helper'
import { IStoreType } from './storeType.type'

export class StoreTypeService {
  fetch(): Promise<IStoreType[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('store_types')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('store_type/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('store_types', data.data)
              resolve(data.data)
            } else reject()
          })
        } catch (error) {
          reject(error)
          throw error
        }
      }
    })
  }
}

export const storeTypeService = new StoreTypeService()
