import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { Card } from 'react-bootstrap'

import { OrderContext } from '../contexts'
import { OrderProduct } from 'src/applets/product'

interface IProps {
  /**
   * Are we showing multiple order
   * item groups?
   */
  coalesced?: boolean
}

const OrderItems: React.FC<IProps> = () => {
  const {
    order,
    setOrder,
    orderItems,
    updateOrderItem,
    deleteOrderItem,
    isLoadingItems,
    isUpdatingItems,
  } = React.useContext(OrderContext)

  return (
    <Card>
      <Card.Body
        className={`px-0 py-0 ${
          !isLoadingItems && isUpdatingItems ? 'disabled' : ''
        }`}
      >
        {isLoadingItems && (
          <Flex justifyContent="center" py={50}>
            <Spinner size="sm" color="primary.600" />
          </Flex>
        )}

        {!isLoadingItems && orderItems && orderItems.length ? (
          <>
            {orderItems.map((item, index) => (
              <OrderProduct
                key={index}
                item={item}
                parent={order}
                setParent={setOrder}
                updateItem={updateOrderItem}
                deleteItem={deleteOrderItem}
              />
            ))}
          </>
        ) : null}
      </Card.Body>
    </Card>
  )
}

OrderItems.defaultProps = {
  coalesced: false,
}

export default OrderItems
