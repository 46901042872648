import CoreService from '../../core/services/core.service'

export class SellerService extends CoreService {
  constructor() {
    super('seller')
  }

  fetchManager(sellerId): Promise<any[]> {
    const formData = new FormData()
    formData.append('seller_id', sellerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `seller_manager_${sellerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`seller_manager/read_by_seller`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateManager(type, data): Promise<void> {
    this.model = 'seller_manager'

    return new Promise((resolve, reject) => {
      super
        [type](data)
        .then(() => resolve())
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'seller'
        })
    })
  }

  fetchManufacturers(sellerId): Promise<any[]> {
    const formData = new FormData()
    formData.append('seller_id', sellerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `seller_manufacturers_${sellerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`seller_manufacturer/read_by_seller`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateManufacturer(type, data): Promise<any> {
    this.model = 'seller_manufacturer'

    return new Promise((resolve, reject) => {
      super
        [type](data)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          /** Reset to original model */
          this.model = 'seller'
        })
    })
  }
}

export const sellerService = new SellerService()
