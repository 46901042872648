import CoreService from '../../core/services/core.service'

const { REACT_APP_STATE_API } = process.env

export class LocationService extends CoreService {
  constructor() {
    super('location')
  }

  fetchStates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `states`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http.get(`${REACT_APP_STATE_API}/states`).then((data) => {
            this.cache.set(cacheKey, data.data)
            resolve(data.data)
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchLGAs(state?: string): Promise<any[]> {
    const endpoint = state ? `/states/${state}/lgas` : '/lgas'

    return new Promise((resolve, reject) => {
      const cacheKey = `lgas${state ? `_${state}` : ''}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http.get(`${REACT_APP_STATE_API}${endpoint}`).then((data) => {
            this.cache.set(cacheKey, data.data)
            resolve(data.data)
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const locationService = new LocationService()
