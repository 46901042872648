import React from 'react'

import { VStack, Button } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import { useFetch, useToast } from 'src/core/hooks'
import { IAgent } from 'src/applets/agent/agent.type'
import { IBuyer } from 'src/applets/buyer/buyer.type'
import { agentService } from 'src/applets/agent'
import { buyerService } from 'src/applets/buyer'
import { locationService } from 'src/applets/location'
import { storeTypeService } from 'src/applets/storeType'
import { IStoreState } from 'src/bootstrap/store/types'
import { route } from 'src/core/helpers/route.helper'
import { refactorObjects } from 'src/core/helpers'

export interface IProps {
  type?: string
  buyer?: IBuyer
  onUpdate?: (updatedValues: IBuyer) => void
}

const BuyerForm: React.FC<IProps> = ({ type, buyer, onUpdate }) => {
  const history = useHistory()
  const { addToast } = useToast()

  const agent = useSelector((state: IStoreState) => state.user as IAgent)

  const [locations] = useFetch(locationService, 'fetch')
  const [storeTypes] = useFetch(storeTypeService, 'fetch')

  const formConfig = {
    initialValues: {
      name: buyer?.name || '',
      person: buyer?.person || '',
      phone: buyer?.phone || '',
      store_type: buyer
        ? {
            value: buyer.store_type._id,
            label: buyer.store_type.name,
          }
        : '',
      location: buyer
        ? {
            value: buyer.location._id,
            label: buyer.location.name,
          }
        : '',
      address: buyer?.address || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Store name field is required.')
        .min(3, 'Store name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(
          /^[0][7-9][0-1][0-9]{8}$/,
          'Phone number must be valid. Ex. 08074381261'
        ),
      store_type: Yup.string().required('Store type field is required.'),
      location: Yup.string().required('Location field is required.'),
      address: Yup.string().required('Address field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      if (buyer) {
        finalValues._id = buyer._id
        finalValues.user_id = buyer.user_id
      }

      finalValues = {
        ...finalValues,
        name: values.name,
        person: values.person,
        phone: values.phone,
        location_id: values.location.value,
        store_type_id: values.store_type.value,
        address: values.address,
      }

      buyerService[type](finalValues)
        .then((data: any) => {
          if (type === 'update') {
            addToast('Buyer successfully updated.', { appearance: 'success' })
            onUpdate({ ...finalValues })
            setSubmitting(false)
          } else {
            /** Create referral record and credit agent */
            agentService
              .createReferral({
                phone: agent.phone,
                referral: values.phone,
              })
              .finally(() => {
                // agentService.creditReferral(values.phone).finally(() => {})
                addToast('Buyer successfully created.', {
                  appearance: 'success',
                })
                history.push(route('buyer', { id: data._id, tab: 'orders' }))
                setSubmitting(false)
              })
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <VStack spacing={3} align="stretch">
            {/* Buyer phone */}
            <FormStack label="Phone" isRequired>
              <FormikField
                name="phone"
                type="text"
                prepend="+234"
                readOnly={type === 'update'}
              />
            </FormStack>

            {/* Store name */}
            <FormStack label="Store Name" isRequired>
              <FormikField name="name" type="text" />
            </FormStack>

            {/* Buyer name */}
            {type === 'update' && (
              <FormStack label="Buyer Name" isRequired>
                <FormikField name="person" type="text" />
              </FormStack>
            )}

            {/* Store type */}
            <FormStack label="Store Type" isRequired>
              <FormikField
                as="autocomplete"
                name="store_type"
                options={refactorObjects(
                  storeTypes?.filter(({ status }) => status === 'active')
                )}
                placeholder="Select store type"
                isDisabled={!storeTypes?.length}
              />
            </FormStack>

            {/* Location */}
            <FormStack label="Store Location" isRequired>
              <FormikField
                as="autocomplete"
                name="location"
                options={refactorObjects(
                  locations?.filter(({ status }) => status === 'active')
                )}
                placeholder="Select location"
                isDisabled={!locations?.length}
              />
            </FormStack>

            {/* Store address */}
            <FormStack label="Store Address" isRequired>
              <FormikField as="textarea" name="address" rows={3} />
            </FormStack>
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            width="100%"
            mt={5}
            isLoading={formik.isSubmitting}
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {type === 'signup' ? 'Create Buyer' : 'Update Buyer'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

BuyerForm.defaultProps = {
  type: 'signup',
}

export default BuyerForm
