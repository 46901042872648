import pluralize from 'pluralize'
import CoreService, { CoreServiceProps } from 'src/core/services/core.service'
import { IProduct, IProductTag } from 'src/applets/product/product.type'
import { IBrand } from '../brand'
import { IManufacturer } from '../manufacturer'

export class ProductService extends CoreService<IProduct> {
  constructor() {
    super('product')
  }

  fetchAvailableMore(payload: any): Promise<{
    products: IProduct[]
    brands: IBrand[]
    manufacturers: IManufacturer[]
  }> {
    const formData = new FormData()

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key])
    })

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_available_${
        payload.location_id
      }`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`${this.model}/read_available_more`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByBrand(brandId: string): Promise<IProduct[]> {
    const formData = new FormData()
    formData.append('brand_id', brandId)

    return new Promise((resolve, reject) => {
      const cacheKey = `products_${brandId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http.post(`product/read_by_brand`, formData).then(({ data }) => {
            if (data.code === 200) {
              this.cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IProduct[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `products_${mftrId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`product/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateStatus(product): Promise<any> {
    const formData = new FormData()
    formData.append('_id', product._id)
    formData.append('status', product.status)

    return new Promise((resolve, reject) => {
      try {
        this.http.post('product/set_status', formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchTags(productId: string): Promise<IProductTag[]> {
    const formData = new FormData()
    formData.append('product_id', productId)

    return new Promise((resolve, reject) => {
      const cacheKey = `product_tags_${productId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`product_tag/read_by_product`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateTag(type, productTag: IProductTag): Promise<any> {
    this.model = 'product_tag'
    return new Promise((resolve, reject) => {
      super
        [type](productTag)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          this.model = 'product' // Reset model
        })
    })
  }

  deleteTag(productTagId: string): Promise<any> {
    this.model = 'product_tag'
    return new Promise((resolve, reject) => {
      super
        .delete(productTagId)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          this.model = 'product' // Reset model
        })
    })
  }

  fetchSellerProduct(sellerId, productId): Promise<any> {
    const formData = new FormData()
    formData.append('seller_id', sellerId)
    formData.append('product_id', productId)

    return new Promise((resolve, reject) => {
      const cacheKey = `seller_product_${sellerId}_${productId}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`seller_product/read_by_seller_product`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                this.cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateSellerProduct(type, productTag: IProductTag): Promise<any> {
    this.model = 'seller_product'
    return new Promise((resolve, reject) => {
      super
        [type](productTag)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          this.model = 'product' // Reset model
        })
    })
  }

  deleteSellerProduct(sellerProductId: string): Promise<any> {
    this.model = 'seller_product'
    return new Promise((resolve, reject) => {
      super
        .delete(sellerProductId)
        .then((data) => resolve(data))
        .catch((error) => reject(error))
        .finally(() => {
          this.model = 'product' // Reset model
        })
    })
  }

  fetchHistory(): Promise<any> {
    const formData = new FormData()
    formData.append('as_csv', 'false')

    return new Promise((resolve, reject) => {
      const cacheKey = `products_history`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        return cachedData
      } else {
        try {
          this.http.post(`product/history`, formData).then(({ data }) => {
            if (data.code === 200) {
              const products = Object.values(data.data)
              this.cache.set(cacheKey, products)
              resolve(products)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const productService = new ProductService()
export type ProductServiceProps = CoreServiceProps | keyof ProductService
