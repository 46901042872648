import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import useQueryParams from 'src/core/hooks/useQueryParams'

import { timestampToDate } from 'src/core/helpers/date.helper'
import { sortByKey } from 'src/core/helpers/filters.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import { route } from 'src/core/helpers/route.helper'

import { IBuyer } from 'src/applets/buyer/buyer.type'

const useBuyersFilter = (buyers: IBuyer[], routeId): any => {
  const history = useHistory()

  const [filters, setFilters] = useState<any>({
    page: Number(useQueryParams('page', 1)),
    search: useQueryParams('search'),
    route_plan_day: Number(useQueryParams('route_plan_day', 0)),
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      setFilters({
        page: Number(query.get('page')) || 1,
        search: query.get('search') || null,
        route_plan_day: Number(query.get('route_plan_day')) || 0,
      })
    })

    return () => {
      stopListening()
    }
  }, [history])

  const updateQueryParams = React.useCallback(
    ({ ...params }) => {
      history.push(
        route(routeId, null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          route_plan_day: params.route_plan_day || null,
        })
      )
    },
    [history]
  )

  const filteredBuyers = React.useMemo(() => {
    let filteredBuyers: IBuyer[] = buyers || []

    if (filteredBuyers.length) {
      const { search, route_plan_day } = filters

      if (search) {
        filteredBuyers = filteredBuyers.filter((buyer) => {
          return (
            basicSearch(buyer?.name, search) ||
            basicSearch(buyer?.phone, search) ||
            basicSearch(buyer?.location?.name, search) ||
            (buyer?.agent && basicSearch(buyer?.agent?.name, search)) ||
            basicSearch(timestampToDate(buyer?.created), search)
          )
        })
      }

      if (route_plan_day) {
        filteredBuyers = filteredBuyers.filter((buyer: any) => {
          return (
            !buyer?.route_plan_days ||
            buyer.route_plan_days.includes(route_plan_day)
          )
        })
      }
    }

    return sortByKey(filteredBuyers, 'name', 'asc')
  }, [buyers, filters.search, filters.route_plan_day])

  return {
    filters,
    filteredBuyers,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useBuyersFilter
