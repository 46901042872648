import React from 'react'
import { Box, Button, Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'

import { ErrorBox, FancyQuantityInput, ImageBox } from 'src/core/components'
import { IOrderItem } from 'src/applets/order'
import { asset, formatCurrency } from 'src/core/helpers'

interface ParentGridProps {
  label?: 'manufacturer' | 'brand'
  items?: any[]
  onSelect?: (item: any) => void
}
export const ParentGrid: React.FC<ParentGridProps> = (props) => {
  const { items, onSelect } = props

  return (
    <SimpleGrid padding="16px" columns={items?.length ? 3 : 1} spacingX="10px">
      {items?.length ? (
        items?.map((item) => (
          <Box key={item._id} padding="10px 0" onClick={() => onSelect(item)}>
            <ImageBox
              backgroundColor="gray.50"
              width="60px"
              margin="0 auto"
              borderRadius="15px"
              src={item?.upload ? asset(item.upload.path) : ''}
            />
            <Text fontSize="sm" textAlign="center" noOfLines={2}>
              {item.name}
            </Text>
          </Box>
        ))
      ) : (
        <ErrorBox summary={`No ${pluralize(props.label)} available`} my={50} />
      )}
    </SimpleGrid>
  )
}

interface ProductListProps {
  products?: any[]
  cartItems?: IOrderItem[]
  onSelect?: (product: any) => void
}
export const ProductList: React.FC<ProductListProps> = (props) => {
  const { products, cartItems, onSelect } = props

  const cartItemsProductIdList = cartItems.map((item) => item?.product?._id)

  return (
    <Box>
      {products?.length ? (
        products?.map((product) => (
          <Flex
            key={product._id}
            cursor="default"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.100"
            transition="background 400ms"
            _hover={{ bg: 'gray.50' }}
            onClick={() => onSelect(product)}
          >
            <Box padding="10px 5px 10px 16px">
              <ImageBox
                width="60px"
                margin="0 auto"
                backgroundColor="gray.50"
                src={product?.upload ? asset(product.upload.path) : ''}
              />
            </Box>

            <Box padding="10px 16px 10px 10px">
              <Text fontWeight="medium">{product.name}</Text>
              <Text fontSize="sm" color="gray.600">
                {product.case_count} {pluralize('Unit', product.case_count)} /
                Case
              </Text>
              {cartItemsProductIdList.includes(product._id) && (
                <Text fontSize="sm" color="gray.600">
                  Product in Cart
                </Text>
              )}

              <Text fontWeight="medium" mt="15px">
                NGN {formatCurrency(product.price)}
              </Text>
            </Box>
          </Flex>
        ))
      ) : (
        <ErrorBox summary={`No products available`} my={50} />
      )}
    </Box>
  )
}

interface ProductViewProps {
  product: any
  quantity: number
  setQuantity: React.Dispatch<number>
  addProduct: (item: any) => Promise<void>
  closePicker: VoidFunction
}
export const ProductView: React.FC<ProductViewProps> = (props) => {
  const { product, quantity, setQuantity } = props

  const [isAdding, setIsAdding] = React.useState<boolean>(false)

  const addProduct = (): void => {
    setIsAdding(true)

    props
      .addProduct({
        product,
        quantity,
        amount: quantity * (product.price / product.case_count),
      })
      .then(() => {
        setIsAdding(false)
        props.closePicker()
      })
      .catch(() => {
        setIsAdding(false)
      })
  }

  return (
    <>
      <ImageBox
        width="100%"
        height="170px"
        margin="0 auto"
        borderBottomWidth="1px"
        borderRadius={0}
        src={product?.upload ? asset(product.upload.path) : ''}
      />
      <Box padding="16px">
        <Text fontSize="lg">{product.name}</Text>
        <Text fontSize="sm" color="gray.600">
          {product.case_count} {pluralize('Unit', product.case_count)} / Case
        </Text>

        <Text fontWeight="medium" mt="15px" color="gray.700">
          NGN {formatCurrency(product.price)} for 1 case
        </Text>

        <Divider my={2} borderColor="gray.200" />

        <Box>
          <Text fontSize="sm" fontWeight="medium" color="gray.600" mb={1}>
            Quantity
          </Text>
          <FancyQuantityInput
            mpu={product.mpu}
            caseCount={product.case_count}
            value={quantity || product.mpu}
            onChange={(value: number) => setQuantity(value)}
          />
        </Box>

        <Flex alignItems="center" justifyContent="space-between" mt={2}>
          <Text fontSize="sm" fontWeight="medium" color="gray.600">
            You Pay
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            NGN{' '}
            {formatCurrency(quantity * (product.price / product.case_count))}
          </Text>
        </Flex>

        <Divider my={2} borderColor="gray.200" />

        <Button
          size="lg"
          onClick={addProduct}
          colorScheme="primary"
          isDisabled={isAdding}
          isLoading={isAdding}
          loadingText="Adding Product"
          isFullWidth
        >
          Add Product
        </Button>

        {product.in_cart && <Text fontSize="sm">In Cart</Text>}
      </Box>
    </>
  )
}
