import Balance from './screens/Balance.screen'
import Settings from './screens/Settings.screen'

import { IRoute } from 'src/bootstrap/routes'

export const settingsRoutes: IRoute[] = [
  {
    id: 'settings',
    path: '/settings',
    component: Settings,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'balance',
    path: '/settings/balance',
    component: Balance,
    requiresAuth: true,
    exact: true,
  },
]

export type ISettingsRouteId = 'settings' | 'balance'
