import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    bg: '#ffffff',
    fontWeight: 'medium',
  },
  sizes: {
    sm: {
      fontSize: 'md',
      height: '34px',
    },
  },
}
