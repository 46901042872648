import { Role, supliasStaff, sellerStaff } from './roles'

interface IPermission {
  /** Unique permission identifier */
  id: string
  /** Roles tied to permission */
  roles: Role[]
}

export const permissions: IPermission[] = [
  {
    id: 'view_buyers',
    roles: [...supliasStaff, ...sellerStaff],
  },
  {
    id: 'view_buyer_cart',
    roles: [...supliasStaff, ...sellerStaff],
  },
  {
    id: 'signup_buyer',
    roles: [...supliasStaff, ...sellerStaff],
  },
  {
    id: 'update_buyer',
    roles: [...supliasStaff, Role.SellerRep, Role.SellerCS],
  },

  { id: 'view_Role.SellerRep_orders', roles: [Role.SellerRep] },

  {
    id: 'view_orders',
    roles: [...supliasStaff, ...sellerStaff],
  },
  {
    id: 'update_order',
    roles: [...supliasStaff, Role.SellerManager, Role.SellerRep],
  },
  { id: 'complete_order', roles: [...supliasStaff, Role.SellerRep] },

  {
    id: 'view_products',
    roles: [...supliasStaff, ...sellerStaff],
  },
  { id: 'update_product', roles: [...supliasStaff, Role.SellerManager] },

  { id: 'view_route_plans', roles: [Role.SellerRep, Role.SellerManager] },
]
