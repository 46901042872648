import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'

import { ErrorBox, Layout } from 'src/core/components'

import OrderGroupDetails from '../components/OrderGroupDetails'
import OrderGroupOrders from '../components/OrderGroupOrders'
import { OrderGroupContext } from '../contexts'

import useOrderGroup from 'src/applets/order/hooks/useOrderGroup'
import { timestampToDate } from 'src/core/helpers/date.helper'

const OrderGroup: React.FC<any> = () => {
  const history = useHistory()
  const { id: order_id }: any = useParams()

  const { orderGroup, ...orderGroupHook } = useOrderGroup(order_id)

  const date = React.useMemo(() => {
    return orderGroup ? timestampToDate(orderGroup?.modified) : null
  }, [orderGroup])

  return (
    <Layout
      headerProps={{
        title: orderGroupHook?.isLoading
          ? 'Order'
          : orderGroup
          ? `Order #${orderGroup.ref}`
          : 'Order not found',
        subtitle: date || '...',
        onBack: history.goBack,
      }}
      bottomNav={false}
    >
      {orderGroupHook.isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      ) : (
        <>
          {orderGroup ? (
            <OrderGroupContext.Provider
              value={{
                orderGroup,
                ...orderGroupHook,
                setOrderGroup: (values) => {
                  orderGroupHook.setOrderGroup(values)
                  orderGroupHook.triggerRefresh()
                },
              }}
            >
              <OrderGroupOrders />
              <OrderGroupDetails />
            </OrderGroupContext.Provider>
          ) : (
            <ErrorBox
              summary="Order group not found: please check the URL and try again."
              my={50}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default OrderGroup
