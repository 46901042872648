import React from 'react'

import { Box, BoxProps, Flex, Spinner, Text } from '@chakra-ui/react'
import { useClickOutside } from 'src/core/hooks'

interface FancyOptionsProps extends BoxProps {
  isOpen: boolean
  onClose: () => void
}

const Wrapper: React.FC<FancyOptionsProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  const optionsRef = React.useRef()

  useClickOutside(optionsRef, () => {
    onClose && onClose()
  })

  return (
    <Box
      css={{
        '&:after': {
          content: '" "',
          display: 'block',
          position: 'absolute',
          backgroundColor: '#fff',
          opacity: isOpen ? '0.4' : '0',
          visibility: isOpen ? 'visible' : 'hidden',
          top: '0',
          right: '0',
          width: '100%',
          height: '100%',
          transition: 'opcaity 450ms',
        },
      }}
    >
      <Flex
        ref={optionsRef}
        position="absolute"
        top={0}
        right={0}
        width="50%"
        height="100%"
        justifyContent="stretch"
        transform={isOpen ? 'translateX(0)' : 'translateX(200%)'}
        transition="transform 450ms ease-in-out"
        zIndex={2}
        css={{
          '&:after': {
            content: '" "',
            display: 'block',
            boxShadow: 'inset 4px 0px 4px rgba(0, 0, 0, 0.15)',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '8px',
            height: '100%',
            opacity: isOpen ? '1' : '0',
            transition: 'opacity 300ms',
            transitionDelay: '700ms',
          },
        }}
      >
        {children}
      </Flex>
    </Box>
  )
}

interface FancyOptionsOptionProps extends BoxProps {
  icon: JSX.Element
  label?: string
  isLoading?: boolean
}
const Option: React.FC<FancyOptionsOptionProps> = (props) => {
  const { icon, label, isLoading, ...rest } = props

  return (
    <Flex
      color="white"
      flexDir="column"
      alignItems="center"
      cursor="pointer"
      justifyContent="center"
      width="100%"
      heignt="100%"
      {...rest}
    >
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center">
          <Spinner size="sm" variant="white" />
        </Flex>
      ) : (
        <>
          {icon}
          {!!label && <Text fontSize="sm">{label}</Text>}
        </>
      )}
    </Flex>
  )
}

export class FancyOptions extends React.Component<FancyOptionsProps> {
  static Option = Option

  render(): React.ReactNode {
    return <Wrapper {...this.props} />
  }
}

export default FancyOptions
