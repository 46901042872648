import { ComponentClass, FC } from 'react'

import Login from 'src/applets/user/screens/Login.screen'
import Error404 from 'src/core/screens/404.screen'

import { dashboardRoutes } from 'src/applets/dashboard'
import { buyerRoutes } from 'src/applets/buyer'
import { orderRoutes } from 'src/applets/order'
import { productRoutes } from 'src/applets/product'
import { settingsRoutes, ISettingsRouteId } from 'src/applets/settings'

export interface IRoute {
  id: string
  path: string
  component: ComponentClass<any, any> | FC<any>
  requiresAuth?: boolean
  exact?: boolean
}

export const routes: IRoute[] = [
  {
    id: 'auth_login',
    path: '/login',
    component: Login,
    exact: true,
  },
  ...buyerRoutes,
  ...dashboardRoutes,
  ...orderRoutes,
  ...productRoutes,
  ...settingsRoutes,
  {
    id: 'error_404',
    path: '*',
    component: Error404,
  },
]

export type IRouteId = ISettingsRouteId | string
