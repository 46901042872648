import React, { useState } from 'react'

import { Flex } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { useSelector } from 'react-redux'

import { sellerStaff } from 'src/bootstrap/permissions/roles'
import { IStoreState } from 'src/bootstrap/store/types'
import { SearchBar, Layout } from 'src/core/components'
import { Panel, PanelBody, PanelHeader } from 'src/core/components/Panel'
import { useMounted, usePermission } from 'src/core/hooks'

import { sellerService } from 'src/applets/seller'

import { ProductsList } from '../components'
import useFilterProducts from '../hooks/useFilterProducts'
import { productService } from '../product.service'
import { IProduct } from '../product.type'

const Products: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const user = useSelector((state: IStoreState) => state.user)

  const tableRef = React.useRef()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [products, setProducts] = useState<IProduct[]>()
  const { filters, filteredProducts, setFilters, setPage } = useFilterProducts(
    products,
    user
  )

  React.useEffect(() => {
    const fetchProducts = (): void => {
      try {
        productService.fetch().then((products) => {
          if (sellerStaff.includes(user.role)) {
            sellerService
              .fetchManufacturers(user.seller_id)
              .then((sellerManufacturers) => {
                const mftrs = sellerManufacturers
                  .filter((mftr) => mftr.status === 'active')
                  .map((mftr) => mftr.manufacturer_id)

                products = products.filter((product) => {
                  return mftrs.includes(product.manufacturer._id)
                })

                isMounted.current && setProducts(products)
                isMounted.current && setIsLoading(false)
              })
          } else {
            isMounted.current && setProducts(products)
            isMounted.current && setIsLoading(false)
          }
        })
      } catch (error) {
        isMounted.current && setIsLoading(false)
      }
    }

    userCan('view_products') && fetchProducts()
  }, [userCan, isMounted, user])

  return (
    <Layout
      headerProps={{ title: `Products (${filteredProducts?.length || 0})` }}
      permission="view_products"
    >
      <Panel borderTop={0}>
        <PanelHeader>
          <SearchBar
            placeholder="Search products"
            value={filters.search || ''}
            onChange={(search) => setFilters({ ...filters, search, page: 1 })}
            isDisabled={isLoading || !filteredProducts}
          />
        </PanelHeader>

        <PanelBody>
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && products?.length ? (
            <ProductsPageContext.Provider
              value={{
                products,
                setProducts,
                filteredProducts,
                filters,
                setFilters,
                page: filters.page,
                setPage,
                tableRef,
              }}
            >
              <ProductsList />
            </ProductsPageContext.Provider>
          ) : null}
        </PanelBody>
      </Panel>
    </Layout>
  )
}

export const ProductsPageContext = React.createContext(null)
export default Products
