import React from 'react'

import usePermission from 'src/core/hooks/usePermission'

import { ErrorBox } from '../ErrorBox'
import BottomNav from './BottomNav/BottomNav'
import Header, { HeaderProps } from './Header/Header'

interface LayoutProps {
  /* __deprecated__ (Use headerProps) */
  header?: HeaderProps
  headerProps?: HeaderProps
  bottomNav?: boolean
  showBottomNav?: boolean
  permission?: string
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { userCan } = usePermission()

  const { bottomNav, permission, showBottomNav } = props
  const headerProps = props?.headerProps || props?.header

  return (
    <>
      {!!headerProps && <Header {...headerProps} />}

      {!permission || (permission && userCan(permission)) ? (
        props.children
      ) : (
        <ErrorBox type="authorization" my={150} reference={permission} />
      )}

      {(bottomNav || showBottomNav) && <BottomNav />}
    </>
  )
}

Layout.defaultProps = {
  bottomNav: true,
}

export default Layout
