import { IManufacturer } from '../manufacturer/manufacturer.type'
import { IBrand } from '../brand/brand.type'
import { IUpload } from '../upload/upload.type'

export interface IProduct {
  _id?: string

  name: string
  description: string
  status: string
  /**
   * manufacturer_status && brand_status &&
   * product_status && seller_product_status
   */
  compound_status?: string

  sku_id: string
  case_count: number
  mpu: number
  price: number
  weight?: number
  size?: number
  quantity?: number

  upload_id: string
  upload?: IUpload

  sellers?: any[]
  manufacturer_id?: string
  manufacturer?: IManufacturer

  brand_id: string
  brand?: IBrand
}

export interface ISellerProduct {
  _id?: string
  mpu: number
  price: number
  quantity: number
  status: 'active' | 'inactive'
  /** Stored as JSON {discount, market_price} */
  meta: string
}

export enum ProductTag {
  LowerUnit = 'LOWER_UNIT',
  HigherUnit = 'HIGHER_UNIT',
  // MoreImage = 'MORE_IMAGE',
}

export interface IProductTag {
  _id?: string
  tag: ProductTag
  value: string
  product_id: string
}
