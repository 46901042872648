import React from 'react'

import { Flex, Spinner } from '@chakra-ui/react'

import { Panel } from 'src/core/components'

import OrderItems from './OrderItems'
import OrderGroupTotal from './OrderGroupTotal'
import { OrderContext, OrderGroupContext } from '../contexts'
import { IOrder } from '../order.type'
import useOrder from '../hooks/useOrder'

const OrderGroupOrders: React.FC = () => {
  const {
    orderGroup,
    setOrderGroup,
    updateAmount,
    isUpdating,
    triggerRefresh: triggerOrderGroupRefresh,
  } = React.useContext(OrderGroupContext)

  return (
    <>
      <Panel mt="8px" opacity={isUpdating ? 0.5 : 1}>
        {orderGroup?.orders
          ? orderGroup.orders.list.map((order) => (
              <SingleOrder
                key={order._id}
                order={order}
                triggerOrderGroupRefresh={triggerOrderGroupRefresh}
              />
            ))
          : null}

        <OrderGroupTotal
          orderGroup={orderGroup}
          setOrderGroup={setOrderGroup}
          updateAmount={updateAmount}
        />
      </Panel>
    </>
  )
}

interface SingleOrderProps {
  order: IOrder
  triggerOrderGroupRefresh: () => void
}
const SingleOrder: React.FC<SingleOrderProps> = (props) => {
  const orderHook = useOrder(props.order._id)

  return (
    <>
      {orderHook.isLoading ? (
        <Flex justifyContent="center" py={50}>
          <Spinner size="sm" color="primary.600" />
        </Flex>
      ) : (
        <OrderContext.Provider
          value={{
            ...orderHook,
            setOrder: (order) => {
              orderHook.setOrder(order)
              props.triggerOrderGroupRefresh()
            },
          }}
        >
          <OrderItems />
        </OrderContext.Provider>
      )}
    </>
  )
}

export default OrderGroupOrders
