import React from 'react'
import { Box, Flex, StackDivider, Text, VStack } from '@chakra-ui/layout'
import { Button, Spinner } from '@chakra-ui/react'
import { FiCopy } from 'react-icons/fi'

import { ErrorBox } from 'src/core/components'
import { useToast, useFetch } from 'src/core/hooks'

import { ILedgerTag, ILedgerTagsDict, LedgerHead } from '../ledger.type'
import { ledgerService, LedgerServiceProps } from '../ledger.service'
import { parseVirtualAccount } from '../ledger.helper'

interface IProps {
  parentId: string
  parentType: LedgerHead
}
export const VirtualAccountDetails: React.FC<IProps> = (props) => {
  const { toast } = useToast()

  const fetchTagsPayload = {
    parent_id: props?.parentId,
    parent_type: props?.parentType,
  }

  const [ledgerTags, isLoading] = useFetch<ILedgerTag[], LedgerServiceProps>(
    ledgerService,
    'fetchTags',
    [fetchTagsPayload],
    !!props?.parentId && !!props?.parentType
  )

  const ledgerTagsDict: ILedgerTagsDict = React.useMemo(() => {
    const ledger_tags = {}
    if (ledgerTags?.length) {
      ledgerTags.forEach((ledger_tag) => {
        ledger_tags[ledger_tag.tag] = ledger_tag
      })
    }
    return ledger_tags
  }, [ledgerTags])

  const virtualAccount = React.useMemo(() => {
    return parseVirtualAccount(ledgerTagsDict?.virtual_account)
  }, [ledgerTags])

  return (
    <>
      {isLoading && (
        <Flex justifyContent="center" py={10}>
          <Spinner color="primary.600" size="sm" />
        </Flex>
      )}

      {!isLoading && !virtualAccount ? (
        <Box textAlign="center" py={5}>
          <ErrorBox summary="No virtual account found" />
        </Box>
      ) : null}

      {!isLoading && virtualAccount ? (
        <VStack
          divider={<StackDivider borderColor="gray.50" />}
          alignItems="flex-start"
        >
          <Box>
            <Text variant="tinyTitle">Bank Name</Text>
            <Text fontWeight="medium">{virtualAccount?.bank?.name}</Text>
          </Box>

          <Box w="100%">
            <Text variant="tinyTitle">Account No.</Text>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="2xl" fontWeight="bold">
                {virtualAccount?.account_number}
              </Text>

              <Button
                size="sm"
                colorScheme="primary"
                variant="ghost"
                leftIcon={<FiCopy size={16} />}
                onClick={() => {
                  navigator.clipboard.writeText(virtualAccount?.account_number)
                  toast({
                    status: 'info',
                    description: 'Account number copied!',
                  })
                }}
              >
                Copy
              </Button>
            </Flex>
          </Box>

          <Box>
            <Text variant="tinyTitle">Account Name</Text>
            <Text fontWeight="medium">{virtualAccount?.account_name}</Text>
          </Box>
        </VStack>
      ) : null}
    </>
  )
}
