import React from 'react'

import OrdersListOrder from '../OrdersList/OrdersListOrder'
import { ErrorBox } from 'src/core/components'
import { IOrderGroup } from 'src/applets/order/order.type'
import { Box, StackDivider, VStack } from '@chakra-ui/layout'

interface IProps {
  orderGroups: IOrderGroup[]
}

const PendingOrdersList: React.FC<IProps> = ({ orderGroups }) => {
  return (
    <>
      {!orderGroups.length ? (
        <ErrorBox summary="No pending orders found." my={50} />
      ) : (
        <VStack divider={<StackDivider borderColor="gray.100" />} spacing={2}>
          {orderGroups.map((orderGroup) => (
            <Box key={orderGroup._id} w="100%">
              <OrdersListOrder orderGroup={orderGroup} showStatus={false} />
            </Box>
          ))}
        </VStack>
      )}
    </>
  )
}

export default PendingOrdersList
