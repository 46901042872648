import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'

import { Alert } from './components/Alert'
import { Badge } from './components/Badge'
import { Button } from './components/Button'
import * as Form from './components/Form'
import { Modal } from './components/Modal'
import { Panel } from './components/Panel'
import { Tabs } from './components/Tabs'
import { Text } from './components/Text'
import { Spinner } from './components/Spinner'

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'gray.50',
        color: 'body',
      },
      button: {
        _focus: {
          outline: 'none',
        },
      },
      'h1, h2, h3, h4, h5, h6': {
        color: 'body',
      },
    },
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'Inter, Georgia, serif',
    mono: 'Menlo, monospace',
  },
  colors,
  sizes: {
    gutter: {
      sm: '8px',
      md: '16px',
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.8rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '3.75rem',
    '8xl': '4.5rem',
    '9xl': '6rem',
    '10xl': '8rem',
  },
  components: {
    Alert,
    Badge,
    Button: Button as any,
    Modal,
    Panel,
    Spinner: Spinner as any,
    Tabs,
    Text,
    ...Form,
  },
})
