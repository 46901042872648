import React from 'react'

import { Box, Button, Flex, Spinner, Stack, Text } from '@chakra-ui/react'
import { FiClock, FiMap } from 'react-icons/fi'

import { useMounted, useToast } from 'src/core/hooks'
import { CoordinateService, ICoordinate } from 'src/applets/coordinate'

const coordinateService = new CoordinateService()

interface IProps {
  type?: 'buyer'
  userId: string
}

export const UserGeoFinder: React.FC<IProps> = ({ type, userId }) => {
  const isMounted = useMounted()
  const { addToast } = useToast()

  const [show, setShow] = React.useState<boolean>(true)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [isFetching, setIsFetching] = React.useState<boolean>(false)

  const [coordinate, setCoordinate] = React.useState<ICoordinate>({
    meta: '-',
    parent_type: type,
    parent_id: userId,
  })

  /**
   * Fetch coordinate, if it exists
   */
  React.useEffect(() => {
    setLoading(true)

    const fetchCoordinate = (): void => {
      coordinateService
        .fetchByTypeParent({ parent_type: type, parent_id: userId })
        .then((coordinate) => {
          coordinate && setCoordinate(coordinate)
        })
        .finally(() => {
          isMounted.current && setLoading(false)
        })
    }

    fetchCoordinate()
  }, [])

  const updateCoordinates = (latitude, longitude): void => {
    const method = coordinate?._id ? 'update' : 'create'
    delete coordinate.created

    coordinateService[method]({ ...coordinate, latitude, longitude })
      .then((_id) => {
        _id && setCoordinate({ ...coordinate, _id })

        addToast(
          `Buyer location ${
            coordinate._id ? 'updated' : 'stored'
          } successfully`,
          {
            appearance: 'success',
          }
        )
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const onSuccess = (position: GeolocationPosition): void => {
    const { latitude, longitude } = position.coords
    setCoordinate({
      ...coordinate,
      latitude,
      longitude,
    })
    updateCoordinates(latitude, longitude)
  }

  const onError = (): void => {
    addToast('Unable to retrieve location', { appearance: 'error' })
  }

  return (
    <Box textAlign="center">
      {loading ? (
        <Flex justifyContent="center">
          <Spinner size="sm" color="primary.600" />
        </Flex>
      ) : navigator.geolocation && show ? (
        <Box>
          <Text fontSize="sm" color="gray.600" mb={2}>
            {`You should only set a buyer's location when you're at their store. You know, for accuracy.`}
          </Text>

          <Stack spacing={2}>
            <Button
              variant="outline"
              leftIcon={<FiClock size={16} />}
              isDisabled={isFetching}
              onClick={() => setShow(false)}
            >
              {coordinate._id ? 'Update later' : 'Set later'}
            </Button>

            <Button
              colorScheme="success"
              leftIcon={<FiMap size={16} />}
              isDisabled={isFetching}
              onClick={() => {
                setIsFetching(true)
                navigator.geolocation.getCurrentPosition(onSuccess, onError)
              }}
              isLoading={isFetching}
              loadingText="Working"
            >
              {coordinate._id ? 'Update location now' : 'Set location now'}
            </Button>
          </Stack>
        </Box>
      ) : !show ? null : (
        <Text color="gray.600">
          Geolocation is not supported by your browser
        </Text>
      )}
    </Box>
  )
}

UserGeoFinder.defaultProps = {
  type: 'buyer',
}

export default UserGeoFinder
