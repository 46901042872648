import React from 'react'

import { VStack, Button } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { FormikField, FormikRadioGroup, FormStack } from 'src/core/components'
import { Role } from 'src/bootstrap/permissions/roles'
import useToast from 'src/core/hooks/useToast'
import { IProduct, ISellerProduct } from 'src/applets/product/product.type'
import { IStoreState } from 'src/bootstrap/store/types'
import { ISeller } from 'src/applets/seller/seller.type'

import { productService } from '../../product.service'

import styles from './ProductForm.module.scss'

interface IProps {
  product: IProduct
  updateProduct: (updated: IProduct) => void
  seller: ISeller
  sellerProduct: ISellerProduct
  updateSellerProduct: (updated: ISellerProduct) => void
}

export const SellerProductForm: React.FC<IProps> = ({
  product,
  updateProduct,
  seller,
  sellerProduct,
  updateSellerProduct,
}) => {
  const { addToast } = useToast()

  const user = useSelector((state: IStoreState) => state.user)

  React.useEffect(() => {
    /**
     * Seller product meta is stored as
     * JSON, sometimes
     */
    const unserializeMeta = (): void => {
      let meta
      try {
        meta = JSON.parse(sellerProduct?.meta)
      } catch (error) {
        meta = null
      }

      setMeta(meta)
    }

    unserializeMeta()
  }, [sellerProduct])

  const type = React.useMemo(() => {
    return sellerProduct ? 'update' : 'create'
  }, [sellerProduct])

  const [meta, setMeta] = React.useState<any>()

  const formConfig = {
    initialValues: {
      status: sellerProduct?.status || 'active',
      mpu: sellerProduct?.mpu || product.mpu,
      price: sellerProduct?.price || product.price,
      quantity: sellerProduct?.quantity || -1,
      discount: meta?.discount || '',
      market_price: meta?.market_price || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Status field is required.'),
      mpu: Yup.number()
        .test(
          'case-count-is-multiple',
          `Case count (${product.case_count}) must be multiple of MPU`,
          function (value) {
            return product.case_count % value === 0
          }
        )
        .required('MPU field is required.'),
      price: Yup.number().required('Price field is required.'),
      quantity: Yup.number().required('Quantity field is required.'),
      discount: Yup.number(),
      market_price: Yup.number(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      /**
       * Meta needs to be store as JSON
       */
      const meta: any = {}
      if (values.discount) meta.discount = values.discount
      if (values.market_price) meta.market_price = values.market_price

      const finalValues: any = {
        status: values.status,
        mpu: values.mpu,
        price: values.price,
        quantity: values.quantity,
        seller_id: seller._id,
        product_id: product._id,
        meta: JSON.stringify(meta),
      }

      if (type === 'update') {
        finalValues._id = sellerProduct._id
      }

      productService
        .updateSellerProduct(type, finalValues)
        .then((sellerProductId) => {
          setSubmitting(false)

          let updatedSellers

          if (type === 'create') {
            updatedSellers = {
              ...product.sellers,
              [seller._id]: { _id: sellerProductId, ...finalValues },
            }
          } else {
            updatedSellers = {
              ...product.sellers,
              [seller._id]: { ...product.sellers[seller._id], ...finalValues },
            }
          }

          updateProduct({ sellers: updatedSellers } as IProduct)
          updateSellerProduct(finalValues)

          addToast(`Product successfully updated.`, { appearance: 'success' })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  const calculateDiscount = React.useCallback(
    (discount: string, price: number) => {
      /**
       * Discount string will look like "original=2000"
       * so we'll need to match just the numbers
       */
      const matches = discount.match(/[\d]+$/)
      if (matches?.length) {
        const originalPrice = Number(matches[0])
        return ((originalPrice - price) / originalPrice) * 100
      }

      return false
    },
    []
  )

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ values, handleSubmit, ...formik }) => (
        <Form className={styles.form} onSubmit={handleSubmit}>
          <VStack spacing={3} align="stretch">
            {/* Product status */}
            {type === 'update' && (
              <FormStack label="Status" isRequired>
                <FormikRadioGroup
                  name="status"
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </FormStack>
            )}

            {/* Product MPU */}
            <FormStack label="MPU" isRequired>
              <FormikField name="mpu" type="number" placeholder={product.mpu} />
            </FormStack>

            {/* Product price */}
            <FormStack label="Price" isRequired>
              <FormikField
                name="price"
                type="number"
                prepend="NGN"
                placeholder={product.price}
              />
            </FormStack>

            {/* Product MPU */}
            <FormStack label="Quantity" isRequired>
              <FormikField name="quantity" type="number" />
            </FormStack>

            {user.role !== Role.SellerRep && (
              <>
                {/* Discount */}
                <FormStack label="Discount" isRequired>
                  <FormikField
                    name="discount"
                    type="text"
                    append="%"
                    placeholder={`Calculate with "original=15000"`}
                    onBlur={() => {
                      if (/^original[=][\d]+$/.test(values.discount)) {
                        const discount = calculateDiscount(
                          values.discount,
                          values.price
                        )
                        if (discount) {
                          formik.setFieldValue(
                            'discount',
                            Number(discount).toFixed()
                          )
                        }
                      }
                    }}
                  />
                </FormStack>

                {/* Market Price */}
                <FormStack label="Market Price" isRequired>
                  <FormikField
                    name="market_price"
                    type="number"
                    prepend="NGN"
                    placeholder={product.price}
                  />
                </FormStack>
              </>
            )}
          </VStack>

          <Button
            type="submit"
            colorScheme="primary"
            isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            my={5}
            width="100%"
            isLoading={formik.isSubmitting}
            loadingText="Updating"
          >
            Update Product
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default SellerProductForm
