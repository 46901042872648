import React from 'react'

import { Button, Flex, IconButton } from '@chakra-ui/react'
import { FiTrash2 } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { FormField } from 'src/core/components'
import { useMounted, useToast } from 'src/core/hooks'
import { routePlanService } from '../routePlan.service'
import { IBuyer } from 'src/applets/buyer/buyer.type'
import {
  IRoutePlan,
  DayOfWeek as Day,
  WeekOfMonth as Week,
} from 'src/applets/routePlan/routePlan.type'
import { IStoreState } from 'src/bootstrap/store/types'
import {
  renderDayText,
  renderWeekText,
} from 'src/core/helpers/routePlan.helper'

interface IProps {
  type?: 'create' | 'update'
  buyer: IBuyer
  routePlan?: IRoutePlan
  /** Tags that have already been added */
  routePlans?: IRoutePlan[]
  onUpdate: () => void
}

const ProductTagForm: React.FC<IProps> = ({
  type,
  buyer,
  routePlan,
  routePlans,
  onUpdate,
}) => {
  const isMounted = useMounted()
  const { addToast } = useToast()

  const user = useSelector((state: IStoreState) => state.user)

  const [isLoading, setIsLoading] = React.useState<any>(false)

  const [formValues, setFormValues] = React.useState<any>({
    day: routePlan ? routePlan.day : '',
    index: routePlan ? routePlan.index : Week.Every,
    buyer_id: buyer._id,
    sales_officer_id: user._id,
  })

  /**
   * Create or update route plan
   *
   *  @param values
   */
  const handleSubmit = React.useCallback(
    (values: IRoutePlan): void => {
      setIsLoading(true)
      let finalValues: any = {}

      if (type === 'update') {
        finalValues._id = routePlan._id
      }

      finalValues = {
        ...finalValues,
        ...values,
      }

      routePlanService[type](finalValues)
        .then(() => {
          onUpdate()

          if (type === 'create' && isMounted.current) {
            /** Reset form */
            setFormValues({
              day: '',
              index: Week.Every,
              buyer_id: buyer._id,
              sales_officer_id: user._id,
            })
          }

          addToast(`Route plan successfully ${type}d.`, {
            appearance: 'success',
          })
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
        })
        .finally(() => isMounted.current && setIsLoading(false))
    },
    [addToast, onUpdate, isMounted, user, buyer, routePlan, type]
  )

  /**
   * Delete route plan
   *
   * @param routePlanId
   */
  const handleDelete = React.useCallback(
    (routePlanId: string): void => {
      setIsLoading(true)

      routePlanService
        .delete(routePlanId)
        .then(() => {
          onUpdate()
          addToast(`Route plan successfully deleted.`, {
            appearance: 'success',
          })
        })
        .catch((error) => {
          addToast(error.message, { appearance: 'error' })
        })
        .finally(() => isMounted.current && setIsLoading(false))
    },
    [addToast, onUpdate, isMounted]
  )

  /**
   * Route plans should be unique across day/index.
   * Unset days are days that have not been set. **grin!
   */
  const unsetDays = React.useMemo(() => {
    const setDays: any[] = routePlans.map((routePlan) => String(routePlan.day))

    /** All unset excluding current selection */
    const unsetDays: Day[] = Object.values(Day).filter((day) => {
      return (
        (type === 'create' && day === formValues.day) ||
        (type === 'update' && day === String(routePlan.day)) ||
        !setDays.includes(day)
      )
    })

    return unsetDays
  }, [type, formValues, routePlan, routePlans])

  /**
   * Checks if form values are valid.
   */
  const validForm = React.useMemo(() => {
    let isValid: boolean

    // Check if values exist
    isValid = formValues.day && formValues.index !== ''

    // Updating? Check if values have changed
    if (
      type === 'update' &&
      String(formValues.day) === String(routePlan.day) &&
      formValues.index === routePlan.index
    ) {
      isValid = false
    }

    return isValid
  }, [type, formValues, routePlan])

  return (
    <form>
      <Flex alignItems="center">
        {/* Day */}
        <FormField
          as="select"
          name="day"
          value={formValues.day}
          onChange={({ target }) =>
            setFormValues({ ...formValues, day: target.value })
          }
        >
          <option value="">Select day</option>
          {unsetDays.map((day, index) => (
            <option key={index} value={day}>
              {renderDayText(day)}
            </option>
          ))}
        </FormField>

        {/* Index: Week */}
        <FormField
          as="select"
          name="index"
          value={formValues.index}
          onChange={({ target }) =>
            setFormValues({ ...formValues, index: target.value })
          }
          isDisabled
        >
          {Object.values(Week).map((week: Week, index) => (
            <option key={index} value={week}>
              {renderWeekText(week)}
            </option>
          ))}
        </FormField>

        {type === 'update' && (
          <IconButton
            aria-label="Delete"
            size="sm"
            variant="ghost"
            ml={2}
            icon={<FiTrash2 size={20} />}
            isDisabled={isLoading}
            onClick={() => handleDelete(routePlan._id)}
            isLoading={isLoading}
          />
        )}
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mt={2}>
        {type === 'create' ? (
          <Button
            variant="solid"
            colorScheme="primary"
            onClick={() => validForm && handleSubmit(formValues)}
            isDisabled={!validForm || isLoading}
            width="100%"
            isLoading={isLoading}
          >
            Add Plan
          </Button>
        ) : type === 'update' && validForm ? (
          <Button
            variant="solid"
            colorScheme="primary"
            onClick={() => validForm && handleSubmit(formValues)}
            isDisabled={!validForm}
            width="100%"
            isLoading={isLoading}
          >
            Update Plan
          </Button>
        ) : null}
      </Flex>
    </form>
  )
}

ProductTagForm.defaultProps = {
  type: 'create',
}

export default ProductTagForm
