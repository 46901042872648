import React from 'react'

/**
 * Handle click outside event
 *
 * @param {HTMLElement} element - React Ref Elemet
 * @param {Function} callback - Callback function on click outside event
 */
export const useClickOutside = (element: any, callback: () => void): void => {
  const callbackRef = React.useRef<any>()
  callbackRef.current = callback

  React.useEffect(() => {
    const handleClickOutside = (event): void => {
      if (!element?.current?.contains(event.target) && callbackRef.current) {
        callbackRef.current()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [callbackRef, element])
}

export default useClickOutside
