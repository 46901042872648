import React, { useState } from 'react'

import {
  Flex,
  Box,
  Badge,
  Button,
  Heading,
  Text,
  IconButton,
} from '@chakra-ui/react'
import classNames from 'classnames/bind'
import { FiMapPin, FiPhoneCall, FiUser, FiCreditCard } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { AlertDialog } from 'src/core/components'
import { usePermission } from 'src/core/hooks'
import { route } from 'src/core/helpers/route.helper'
import { LedgerBalanceModal } from 'src/applets/ledger'

import styles from './OrderGroupDetails.module.scss'
import OrderGroupUpdateModal from '../OrderGroupUpdateModal'
import { OrderGroupContext } from '../../contexts'
import { OrderTag } from '../../order.type'

const cn = classNames.bind(styles)

const OrderGroupDetails: React.FC<any> = () => {
  const wrapperRef = React.useRef<any>()
  const [wrapperHeight, setWrapperHeight] = React.useState<number>(0)
  React.useEffect(() => {
    setWrapperHeight(wrapperRef?.current?.offsetHeight || 0)
  }, [])

  const { userCan } = usePermission()

  const [showBalance, setShowBalance] = useState<boolean>(false)
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false)

  const { orderGroup, setOrderGroup, updateTag, isUpdating } =
    React.useContext(OrderGroupContext)

  return (
    <>
      <Box ref={wrapperRef} className={cn('wrapper', 'open')}>
        {/* Buyer details */}
        <Box mb={4}>
          <Text variant="tinyTitle" textTransform="uppercase">
            Details
          </Text>
          <Flex alignItems="flex-start" mt={1}>
            <Heading as="h3" className={cn('buyer-name')}>
              {orderGroup?.buyer?.name || 'Buyer'}
            </Heading>
            {orderGroup?.buyer_is_new && (
              <Badge ml={2} colorScheme="teal">
                New
              </Badge>
            )}
          </Flex>

          <Flex color="gray.600" my={1}>
            <Box position="relative" top={1}>
              <FiUser size={14} />
            </Box>
            <Text color="gray.600" ml={2}>
              {orderGroup?.sales_officer?.name || '...'}
            </Text>
          </Flex>

          <Flex color="gray.600" my={1}>
            <Box position="relative" top={1}>
              <FiMapPin size={14} />
            </Box>
            <Text color="gray.600" ml={2}>
              Address: {orderGroup?.buyer?.address || '-'} -{' '}
              {orderGroup?.buyer?.location?.name}
            </Text>
          </Flex>
        </Box>

        <Flex justifyContent="space-between" alignItems="center">
          {userCan('view_buyers') && (
            <Box>
              <Link
                to={route('buyer', {
                  id: orderGroup?.buyer._id,
                  tab: 'orders',
                })}
              >
                <IconButton
                  aria-label="Buyer profile"
                  icon={<FiUser size={16} />}
                  variant="outline"
                  borderRadius={99999}
                />
              </Link>

              <a href={`tel:+234${orderGroup?.buyer?.phone.substr(1)}`}>
                <IconButton
                  aria-label="Buyer profile"
                  ml={3}
                  icon={<FiPhoneCall size={16} />}
                  variant="outline"
                  borderRadius={99999}
                />
              </a>

              {userCan('complete_order') && (
                <>
                  <IconButton
                    ml={3}
                    aria-label="Buyer balance"
                    icon={<FiCreditCard size={16} />}
                    variant="outline"
                    borderRadius={99999}
                    onClick={() => setShowBalance(true)}
                  />
                  <LedgerBalanceModal
                    isOpen={showBalance}
                    onClose={() => setShowBalance(false)}
                    user={orderGroup.buyer as any}
                    showTransferDetails={true}
                  />
                </>
              )}
            </Box>
          )}

          {userCan('complete_order') && (
            <Flex>
              <OrderGroupUpdateModal />

              {orderGroup.tag === OrderTag.Pending && (
                <>
                  <Button
                    ml={2}
                    size="sm"
                    variant="outline"
                    colorScheme="critical"
                    onClick={() => setConfirmCancel(true)}
                    isDisabled={isUpdating}
                  >
                    Cancel
                  </Button>

                  <AlertDialog
                    isOpen={confirmCancel}
                    headerText="Cancel Order"
                    bodyText="Are you sure you want to cancel this order?"
                    confirmButton={{
                      text: 'Yes, Cancel',
                      action: () => {
                        updateTag(OrderTag.Cancelled).then(() => {
                          setOrderGroup({ tag: OrderTag.Cancelled })
                          setConfirmCancel(false)
                        })
                      },
                      isLoading: isUpdating,
                    }}
                    cancelButton={{
                      text: 'No',
                      action: () => {
                        setConfirmCancel(false)
                      },
                    }}
                  />
                </>
              )}
            </Flex>
          )}
        </Flex>
      </Box>
      <Box height={`${wrapperHeight + 30}px`} />
    </>
  )
}

export default OrderGroupDetails
