import React from 'react'

import { Flex, Box, Button, Text, Image, useTheme } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'

import { FormikField, FormStack } from 'src/core/components'
import useToast from 'src/core/hooks/useToast'
import { IUser } from '../types/user.type'
import { IStoreState } from 'src/bootstrap/store/types'
import { userService } from '../user.service'
import { AUTH, SET_USER } from 'src/bootstrap/store/actionTypes'
import { route } from 'src/core/helpers/route.helper'

import Logo from 'src/core/assets/images/suplias-go-logo.svg'
import { FiLock, FiUser } from 'react-icons/fi'

const Login: React.FC = () => {
  const { colors } = useTheme()
  const dispatch = useDispatch()
  const { toast } = useToast()

  const { appVersion, isAuthenticated } = useSelector((state: IStoreState) => ({
    appVersion: state.global.version,
    isAuthenticated: !!state.auth.token,
  }))

  const formConfig = {
    initialValues: {
      id: '',
      hash: '',
      remember: true,
    },
    validationSchema: Yup.object({
      id: Yup.string().required('Email or Phone number is required.'),
      hash: Yup.string().required('Enter your password'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      userService
        .doLogin(values)
        .then((user: IUser) => {
          setSubmitting(false)
          dispatch({ type: SET_USER, payload: user })
          dispatch({ type: AUTH, payload: { token: user.token } })
        })
        .catch((error) => {
          setSubmitting(false)
          toast({ description: error.message, status: 'error' })
        })
    },
  }

  if (isAuthenticated) {
    return <Redirect to={route('dashboard')} />
  }

  return (
    <Flex alignItems="center" justifyContent="center" my={50}>
      <Box width="95%" maxW="450px">
        <Card className="bordered contained" style={{ borderRadius: '10px' }}>
          <Card.Body>
            <Box mb={5} textAlign="center">
              <Image
                maxW="150px"
                margin="0 auto"
                alt="Suplias Logo"
                src={Logo}
              />
              <Text fontSize="sm" color="gray.600">
                v{appVersion}
              </Text>
            </Box>

            <Formik
              initialValues={formConfig.initialValues}
              validationSchema={formConfig.validationSchema}
              validateOnMount={true}
              onSubmit={formConfig.onSubmit}
            >
              {({ ...formik }) => (
                <Form onSubmit={formik.handleSubmit}>
                  <FormStack w="100%">
                    <FormikField
                      name="id"
                      type="text"
                      prepend={<FiUser color={colors.gray['600']} size={16} />}
                      placeholder="Your Email or Phone Number"
                    />
                  </FormStack>

                  <FormStack>
                    <FormikField
                      name="hash"
                      type="password"
                      prepend={<FiLock color={colors.gray['600']} size={16} />}
                      placeholder="Your Password"
                    />
                  </FormStack>

                  <Button
                    type="submit"
                    colorScheme="primary"
                    mt={3}
                    width="100%"
                    isDisabled={formik.isSubmitting || !formik.isValid}
                    isLoading={formik.isSubmitting}
                    loadingText="Logging you in"
                  >
                    Log in
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Box>
    </Flex>
  )
}

Login.defaultProps = {
  isAuthenticated: false,
}

export default Login
