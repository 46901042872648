import React from 'react'

import { useSelector } from 'react-redux'

import { useMounted, useToast } from 'src/core/hooks'
import { orderService, orderGroupService } from '../services'
import { OrderTag } from 'src/applets/order/order.type'
import { IStoreState } from 'src/bootstrap/store/types'

export interface IUseUpdateOrder {
  updateTag: (newTag: OrderTag, newMeta?: any) => Promise<void>
  updateMeta: (newMeta: any, newTag?: OrderTag) => Promise<void>
  updateAmount: (newAmount: any) => Promise<any>
  updateSalesOfficer: (newSalesOfficer: any) => Promise<any>
  isUpdating: boolean
}

const useUpdateOrder = (order, isOrderGroup = false): IUseUpdateOrder => {
  const { addToast } = useToast()
  const isMounted = useMounted()
  const user = useSelector((state: IStoreState) => state.user)

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)

  const updateMeta = (newMeta: any, newTag?: OrderTag): Promise<void> => {
    setIsUpdating(true)

    /**
     * Add source to new orders only
     */
    const meta = { ...order?.meta_obj }
    const metaArray = []

    if (newTag === OrderTag.Pending) {
      // created_by
      metaArray.push({
        tag: 'created_by',
        value: meta?.created_by || user?.user_id,
        meta: '-',
      })

      // source
      metaArray.push({
        tag: 'source',
        value: meta?.source || 'suplias-go',
        meta: '-',
      })
    }

    if ([OrderTag.Completed, OrderTag.Cancelled].includes(newTag)) {
      // {completed or cancelled}_by
      metaArray.push({
        tag: `${newTag}_by`,
        value: user?.user_id,
        meta: '-',
      })
    }

    if (newMeta?.payment) {
      // payment_method
      metaArray.push({
        tag: 'payment_method',
        value: newMeta?.payment?.method,
        meta: '-',
      })

      // payment_cash
      metaArray.push({
        tag: 'payment_cash',
        value: newMeta?.payment?.cash,
        meta: '-',
      })

      // payment_transfer
      metaArray.push({
        tag: 'payment_transfer',
        value: newMeta?.payment?.transfer,
        meta: '-',
      })
    }

    return new Promise((resolve, reject) => {
      orderGroupService
        .updateMeta(order._id, metaArray)
        .then(() => {
          addToast('Order successfully updated.', {
            appearance: 'success',
          })
          resolve()
        })
        .catch(({ message }) => {
          addToast(message, { appearance: 'error' })
          reject()
        })
        .finally(() => {
          isMounted.current && setIsUpdating(false)
        })
    })
  }

  const updateTag = (newTag: OrderTag, newMeta?: any): Promise<void> => {
    setIsUpdating(true)

    const service = isOrderGroup ? orderGroupService : orderService

    const details: any = {
      _id: order._id,
      old_tag: order.tag,
      new_tag: newTag,
      meta: order.meta,
    }

    if (!isOrderGroup) {
      details.sales_officer_id = order.sales_officer_id
    }

    return new Promise((resolve, reject) => {
      service
        .updateProp('tag', details)
        .then(() => {
          updateMeta(newMeta, newTag).then(() => {
            resolve()
          })
        })
        .catch(({ message }) => {
          addToast(message, { appearance: 'error' })
          reject()
        })
        .finally(() => {
          !newMeta && isMounted.current && setIsUpdating(false)
        })
    })
  }

  const updateAmount = (newAmount: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      setIsUpdating(true)

      const service = isOrderGroup ? orderGroupService : orderService

      const updatedAmount = {
        _id: order._id,
        amount: newAmount,
      }

      service
        .updateProp('amount', updatedAmount)
        .then(() => {
          resolve(newAmount)
        })
        .catch(({ message }) => {
          addToast(message, { appearance: 'error' })
          reject()
        })
        .finally(() => {
          isMounted.current && setIsUpdating(false)
        })
    })
  }

  const updateSalesOfficer = (salesOfficerId: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      setIsUpdating(true)

      const service = isOrderGroup ? orderGroupService : orderService

      const updatedAmount = {
        _id: order._id,
        sales_officer_id: salesOfficerId,
      }

      service
        .updateProp('sales_officer', updatedAmount)
        .then(() => {
          resolve(salesOfficerId)
        })
        .catch(({ message }) => {
          addToast(message, { appearance: 'error' })
          reject()
        })
        .finally(() => {
          isMounted.current && setIsUpdating(false)
        })
    })
  }

  return { updateTag, updateMeta, updateAmount, updateSalesOfficer, isUpdating }
}

export default useUpdateOrder
