import React, { useMemo } from 'react'

import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import { FiChevronRight } from 'react-icons/fi'
import { useHistory } from 'react-router'

import { IOrderGroup, OrderTag } from 'src/applets/order/order.type'
import { timestampToDate } from 'src/core/helpers/date.helper'
import { formatCurrency } from 'src/core/helpers/money.helper'
import { route } from 'src/core/helpers/route.helper'

interface IProps {
  orderGroup: IOrderGroup
  page?: 'buyer' | 'orders'
  showStatus?: boolean
}

export const OrdersListOrder: React.FC<IProps> = ({
  orderGroup,
  page,
  showStatus,
}) => {
  const history = useHistory()

  // Date of earliest order
  const date = useMemo(() => {
    return timestampToDate(orderGroup.modified)
  }, [orderGroup])

  // Sum of all orders
  const amount = React.useMemo(() => {
    return (
      (orderGroup?.orders?.value?.completed || 0) +
      (orderGroup?.charges?.DELIVERY_FEE?.value || 0)
    )
  }, [orderGroup])

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      cursor="default"
      onClick={() => {
        history.push(route('order', { id: orderGroup._id }))
      }}
    >
      <Box>
        {page === 'orders' ? (
          <>
            <Text fontWeight="medium">
              {`${orderGroup?.buyer?.name || orderGroup?.buyer?.phone}`}
            </Text>
          </>
        ) : (
          <>
            <Text fontWeight="medium">Order #{orderGroup?.ref}</Text>
          </>
        )}

        {page === 'orders' ? (
          <>
            <Text variant="tinyTitle">
              {date}
              &nbsp;&middot; #{orderGroup.ref}
            </Text>
            <Text fontSize="sm" color="gray.600" noOfLines={1} mr={1}>
              {orderGroup?.buyer?.location?.name} &middot;{' '}
              {orderGroup?.sales_officer?.name}
            </Text>
          </>
        ) : (
          <>
            <Text fontSize="sm" color="gray.600" noOfLines={1} mr={1}>
              {date} &middot; {orderGroup?.sales_officer?.name}
            </Text>
          </>
        )}

        <Text mt={2}>NGN {formatCurrency(amount)}</Text>
      </Box>

      <Flex alignItems="center">
        {showStatus && (
          <Badge
            mr={2}
            colorScheme={
              orderGroup.tag === OrderTag.Completed
                ? 'success'
                : [OrderTag.Pending, OrderTag.Delivered].includes(
                    orderGroup.tag
                  )
                ? 'warning'
                : 'critical'
            }
          >
            {orderGroup.tag.charAt(0)}
          </Badge>
        )}

        {orderGroup?.buyer_is_new && (
          <Badge mr={2} colorScheme="teal">
            New
          </Badge>
        )}

        <Box color="gray.500">
          <FiChevronRight size={18} />
        </Box>
      </Flex>
    </Flex>
  )
}

OrdersListOrder.defaultProps = {
  page: 'orders',
  showStatus: true,
}

export default OrdersListOrder
