import React from 'react'

import { Avatar, Flex, IconButton, Text, Heading, Box } from '@chakra-ui/react'
import { FiRotateCw } from 'react-icons/fi'
import { useHistory } from 'react-router'

import { route } from 'src/core/helpers'
import { IUser } from 'src/applets/user'

const date = new Date()
const time = date.getHours()
const greeting =
  time < 12 ? 'Good morning' : time < 16 ? 'Good afternoon' : 'Good evening'

const DashboardHeader: React.FC<{ user: IUser }> = ({ user }) => {
  const history = useHistory()

  return (
    <Flex bg="white" p="12px 16px" justifyContent="space-between">
      <Box>
        <Text fontSize="lg" color="gray.600">
          {greeting},
        </Text>
        <Heading as="h3" fontSize="xl">
          {user.name}
        </Heading>
      </Box>

      <Flex alignItems="center">
        <IconButton
          aria-label="Refresh"
          size="sm"
          icon={<FiRotateCw size={20} />}
          variant="ghost"
          onClick={(event) => {
            event.preventDefault()
            window.location.reload()
          }}
        />

        <Avatar
          ml={4}
          size="sm"
          name={user.name.replaceAll(/[^a-zA-Z0-9\s]+/g, '')}
          cursor="default"
          onClick={() => history.push(route('settings'))}
        />
      </Flex>
    </Flex>
  )
}

export default DashboardHeader
