import React from 'react'

import { Tabs, TabList, Tab } from '@chakra-ui/tabs'
import { Box, Text, Flex, IconButton, Spinner } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { IStoreState } from 'src/bootstrap/store/types'
import { Panel, PanelBody, Table, Tr, Td, Tbody } from 'src/core/components'
import { PendingOrders, OrderSummary } from 'src/applets/order'
import { LedgerBalanceCard } from 'src/applets/ledger'

import DashboardHeader from './DashboardHeader'
import { useFetch } from 'src/core/hooks'
import {
  salesOfficerService,
  SalesOfficerServiceProps,
} from 'src/applets/salesOfficer'
import { FiChevronRight } from 'react-icons/fi'
import { formatCurrency, route } from 'src/core/helpers'
import { useHistory } from 'react-router'

enum TabType {
  PendingOrders,
  OrderSummary,
}

const SalesOfficerDashboard: React.FC = () => {
  const history = useHistory()
  const { user } = useSelector((state: IStoreState) => ({
    user: state.user,
    pendingOrders: state.global.pendingOrders,
  }))

  const [details, isLoading] = useFetch<any, SalesOfficerServiceProps>(
    salesOfficerService,
    'fetchDetails',
    [user?._id]
  )

  const [tabIndex, setTabIndex] = React.useState<number>(TabType.PendingOrders)
  const [showDetails, setShowDetails] = React.useState({
    mtd: false,
    today: true,
  })

  const stats = React.useMemo(() => {
    let today = []
    let mtd = []

    if (details) {
      const ordersDay = details?.orders_day?.summary
      const ordersMtd = details?.orders_mtd.summary

      today = Object.entries(ordersDay)
        .map(([id, values]: any) => {
          return {
            id,
            getColor: () => 'gray.600',
            count: values.count,
            value: formatCurrency(values.value, { notation: 'compact' }),
          }
        })
        .filter((stat) => stat.id !== 'success')

      const pendingToday = {
        id: 'pending',
        getColor: () => 'warning.600',
        getBgColor: () => 'warning.50',
        count: ordersDay?.assigned?.count - ordersDay?.delivered?.count,
        value: formatCurrency(
          ordersDay?.assigned?.value - ordersDay?.delivered?.value,
          { notation: 'compact' }
        ),
        onClick: () => {
          history.push(route('pending_orders'))
        },
      }

      pendingToday?.count && today.unshift(pendingToday)

      mtd = Object.entries(ordersMtd).map(([id, values]: any) => {
        if (id === 'success') {
          return {
            id,
            label: 'Success Rate',
            getColor: (value): string => {
              return value > 79
                ? 'success.600'
                : value > 50
                ? 'warning.600'
                : 'critical.600'
            },
            count: `${values.count.toFixed()}%`,
            value: `${values.value.toFixed()}%`,
          }
        } else {
          return {
            id,
            count: values.count,
            value: formatCurrency(values.value, {
              notation: 'compact',
            }),
          }
        }
      })
    }

    return { today, mtd }
  }, [details])

  return (
    <>
      <DashboardHeader user={user} />

      <Panel border={0} mb="8px">
        <PanelBody>
          <LedgerBalanceCard user={user} />

          <Panel padding="8px 16px" mt="16px" borderRadius="8px">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="medium">Month-to-date</Text>
              <StatToggleButton
                isOpen={showDetails.mtd}
                onClick={() => {
                  setShowDetails({ ...showDetails, mtd: !showDetails.mtd })
                }}
              />
            </Flex>
            {stats?.mtd?.length ? (
              <Table display={showDetails.mtd ? 'table' : 'none'} isFit>
                <Tbody>
                  {stats?.mtd.map((stat, index) => {
                    const tdBorderWidth =
                      index === stats.mtd.length - 1 ? 0 : null

                    return (
                      <Tr key={stat.id}>
                        <Td
                          pl={0}
                          py={2}
                          color="gray.600"
                          textTransform="capitalize"
                          borderWidth={tdBorderWidth}
                        >
                          {stat?.label || `${stat.id} Orders`}
                        </Td>
                        <Td
                          py={2}
                          color={stat?.getColor?.(stat.count)}
                          borderWidth={tdBorderWidth}
                          isNumeric
                        >
                          {stat.count}
                        </Td>
                        <Td
                          pr={3}
                          py={2}
                          color={stat?.getColor?.(stat.value)}
                          borderWidth={tdBorderWidth}
                          isNumeric
                        >
                          {stat.value}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            ) : isLoading && showDetails.mtd ? (
              <Flex justifyContent="center" py={3}>
                <Spinner color="gray.400" size="sm" />
              </Flex>
            ) : showDetails.mtd ? (
              <Text fontSize="sm" textAlign="center" color="gray.600" py={3}>
                Stats not found
              </Text>
            ) : null}
          </Panel>

          <Panel padding="8px 16px" mt="16px" borderRadius="8px">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="medium">Today</Text>
              <StatToggleButton
                isOpen={showDetails.today}
                onClick={() => {
                  setShowDetails({ ...showDetails, today: !showDetails.today })
                }}
              />
            </Flex>
            {stats?.today?.length ? (
              <Table display={showDetails.today ? 'table' : 'none'} isFit>
                <Tbody>
                  {stats?.today.map((stat, index) => {
                    const tdBorderWidth =
                      index === stats.today.length - 1 ? 0 : null
                    return (
                      <Tr key={stat.id} onClick={stat?.onClick}>
                        <Td
                          pl={0}
                          py={2}
                          color={stat?.getColor?.()}
                          textTransform="capitalize"
                          borderWidth={tdBorderWidth}
                        >
                          {`${stat.id} Orders`}
                        </Td>
                        <Td
                          py={2}
                          color={stat?.getColor?.()}
                          borderWidth={tdBorderWidth}
                          isNumeric
                        >
                          {stat.count}
                        </Td>
                        <Td
                          pr={3}
                          py={2}
                          color={stat?.getColor?.()}
                          borderWidth={tdBorderWidth}
                          isNumeric
                        >
                          {stat.value}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            ) : isLoading ? (
              <Flex justifyContent="center" py={3}>
                <Spinner color="gray.400" size="sm" />
              </Flex>
            ) : (
              <Text fontSize="sm" textAlign="center" color="gray.600" py={3}>
                Stats not found
              </Text>
            )}
          </Panel>
        </PanelBody>
      </Panel>

      <Panel
        bg="#ffffff"
        top="-1px"
        position="sticky"
        paddingTop="10px"
        zIndex={10}
      >
        <Box px="16px">
          <Tabs defaultIndex={tabIndex} onChange={setTabIndex}>
            <TabList border={0}>
              <Tab>Pending Orders</Tab>
              <Tab>Order Summary</Tab>
            </TabList>
          </Tabs>
        </Box>
      </Panel>

      {tabIndex === TabType.PendingOrders && <PendingOrders />}
      {tabIndex === TabType.OrderSummary && <OrderSummary />}
    </>
  )
}

const StatToggleButton: React.FC<{ isOpen: boolean; onClick: VoidFunction }> = (
  props
) => {
  return (
    <IconButton
      px="4px"
      size="sm"
      color="gray.400"
      variant="ghost"
      icon={<FiChevronRight size={18} />}
      aria-label="Toggle today"
      _focus={{ outline: 'none', boxShadow: 'none' }}
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
      transform={props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
      transition="transform 300ms"
      onClick={props.onClick}
    />
  )
}

export default SalesOfficerDashboard
