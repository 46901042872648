import cache from '../../core/helpers/cache.helper'
import { http, setToken } from '../../core/helpers/http.helper'
import { permissions } from '../../bootstrap/permissions'

import { IUser } from './types/user.type'

export class UserService {
  /**
   * Filter out user permissions, map to retain only permisison ID
   */
  _setPermissions = (userRole: string): string[] => {
    const userPermissions = permissions
      .filter((permission: any) => {
        return permission.roles.includes(userRole)
      })
      .map((permission: any) => permission.id)

    return userPermissions
  }

  doLogin = (credentials: {
    id: string
    hash: string
    remember: string
  }): Promise<IUser> => {
    const formData = new FormData()
    formData.append('phone', credentials.id)
    formData.append('hash', credentials.hash)
    formData.append('remember', credentials.remember)

    return new Promise((resolve, reject) => {
      try {
        http.post('administrator/login_delegate', formData).then(({ data }) => {
          if (data.code === 200) {
            const { token } = data.data
            localStorage.setItem('token', JSON.stringify(token))
            const user = data.data.profile
            const role = data.data.role
            const permissions = this._setPermissions(role)

            setToken(token)
            resolve({ ...user, role, permissions, token })
          } else {
            reject({ message: data.message })
          }
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  /**
   * Revoke user auth token and logout
   */
  doLogout = (): Promise<void> => {
    return new Promise((resolve) => {
      http.get(`auth/revoke`).finally(() => {
        cache.reset()
        resolve()
      })
    })
  }

  /**
   * Fetch user (with token) and assign user permissions
   */
  fetchUser = (token: string): Promise<IUser> => {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('current_user')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`administrator/read_delegate/?token=${token}`)
            .then(({ data }) => {
              if (data.code === 200) {
                const user = data.data.profile
                const role = data.data.role
                const permissions = this._setPermissions(role)

                cache.set('current_user', { ...user, role, permissions })
                resolve({ ...user, role, permissions })
              } else {
                reject({ message: data.message })
              }
            })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  /**
   * Change user password.
   * Type 'update' doese not require old password,
   * 'change' requires ir
   *
   * @param type
   * @param credentials
   */
  updatePassword(type: 'change' | 'update', credentials: any): Promise<void> {
    const formData = new FormData()
    formData.append('_id', credentials.user_id)

    if (type === 'update') {
      formData.append('hash', credentials.hash_new)
    } else if (type === 'change') {
      formData.append('hash_old', credentials.hash_old)
      formData.append('hash_new', credentials.hash_new)
    }

    return new Promise((resolve, reject) => {
      try {
        http.post(`user/${type}_auth`, formData).then(({ data }) => {
          if (data.code === 200) resolve()
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unepected error occured!' })
        throw error
      }
    })
  }
}

export const userService = new UserService()
