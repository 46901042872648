import React from 'react'

import { Flex, Button, Input } from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'

interface IProps {
  size?: string
  value: number
  mpu: number
  onChange: (value: number) => void
  isValueEditable?: boolean
}

export const QuantityInput: React.FC<IProps> = (props) => {
  const { size, value, mpu, onChange, isValueEditable } = props

  const increment = (event): void => {
    event.preventDefault()
    const newValue = Number(value) + mpu
    onChange(newValue)
  }

  const decrement = (event): void => {
    event.preventDefault()
    if (Number(value) !== mpu && Number(value) > mpu) {
      const newValue = Number(value) - mpu
      onChange(newValue)
    }
  }

  return (
    <Flex>
      <Button
        size="sm"
        variant="outline"
        borderRadius="6px 0 0 6px"
        mr="-1px"
        onClick={decrement}
        isDisabled={value === mpu || value < mpu}
      >
        <FiMinus size={size === 'sm' ? 18 : 20} />
      </Button>
      <Input
        px="3px"
        size="sm"
        borderRadius={0}
        value={value || 0}
        width={size === 'sm' ? '6ch' : '8ch'}
        textAlign="center"
        background="#fff"
        fontWeight="bold"
        onChange={({ target }: any) => onChange(target.value)}
        readOnly={!isValueEditable}
      />
      <Button
        size="sm"
        variant="outline"
        borderRadius="0 6px 6px 0"
        ml="-1px"
        onClick={increment}
      >
        <FiPlus size={size === 'sm' ? 18 : 20} />
      </Button>
    </Flex>
  )
}

QuantityInput.defaultProps = {
  size: 'sm',
}

export default QuantityInput
