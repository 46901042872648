import React from 'react'

import { useHistory } from 'react-router-dom'

import { Layout } from 'src/core/components'
import { PendingOrders } from '../components/PendingOrders'

const PendingOrdersScreen: React.FC = () => {
  const history = useHistory()

  return (
    <Layout
      headerProps={{ title: 'Pending Orders', onBack: history.goBack }}
      permission="view_orders"
    >
      <PendingOrders />
    </Layout>
  )
}

export default PendingOrdersScreen
