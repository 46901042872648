import { ComponentStyleConfig } from '@chakra-ui/theme'

export const Panel: ComponentStyleConfig = {
  parts: ['panel', 'header', 'body'],
  baseStyle: {
    panel: {
      bg: 'white',
      border: '1px solid',
      borderColor: 'gray.100',
    },
    header: {
      borderBottom: '1px solid',
      borderColor: 'gray.100',
      padding: '16px',
    },
    body: {
      padding: '16px',
    },
  },
}
