import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { useQueryParams } from 'src/core/hooks/useQueryParams'

import { timestampToDate } from 'src/core/helpers/date.helper'
import { route } from 'src/core/helpers/route.helper'
import { basicSearch } from 'src/core/helpers/search.helper'
import { IOrderGroup } from 'src/applets/order/order.type'
import useMounted from 'src/core/hooks/useMounted'

const useOrdersFilter = (
  orderGroups: any,
  routeId: string,
  routeParams?: any
): any => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = useState<any>({
    tag: useQueryParams('tag', '*'),
    page: Number(useQueryParams('page', 1)),
    search: useQueryParams('search'),
  })

  const updateQueryParams = React.useCallback(
    (params) => {
      // console.log('it ran first', params)
      history.push(
        route(routeId, routeParams, {
          ...params,
          page: params.page === 1 ? null : params.page,
        })
      )
    },
    [history, routeId, routeParams]
  )

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      // console.log('it ran second', query.get('start_date'))

      setFilters({
        tag: query.get('tag') || '*',
        page: Number(query.get('page')) || 1,
        search: query.get('search') || null,
      })
    })

    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const filteredOrderGroups = React.useMemo(() => {
    let filtered: IOrderGroup[] = orderGroups || []

    if (filtered.length) {
      if (filters.tag && filters.tag !== '*') {
        const { tag } = filters
        if (tag === 'pencom') {
          filtered = filtered.filter((order) => {
            return order.tag === 'pending' || order.tag === 'completed'
          })
        } else {
          filtered = filtered.filter((order) => order.tag === tag)
        }
      } else {
        filtered = filtered.filter((order) => {
          return order.tag !== 'deleted' && order.tag !== 'draft'
        })
      }

      if (filters.search) {
        const query = filters.search
        filtered = filtered.filter((orderGroup) => {
          return (
            basicSearch(orderGroup.ref, query) ||
            basicSearch(orderGroup.buyer.name, query) ||
            basicSearch(orderGroup.buyer.phone, query) ||
            basicSearch(timestampToDate(orderGroup.created), query)
          )
        })
      }
    }

    return filtered
  }, [orderGroups, filters, routeId])

  return {
    filters,
    filteredOrderGroups,
    setFilters: () => {
      // console.log('why?')
      // updateQueryParams({ ...filters, ...updated, page: 1 })
    },
    setPage: (page) => {
      updateQueryParams({ ...filters, page })
    },
  }
}

export default useOrdersFilter
