import React from 'react'
import { SET_CONFIG } from '../actionTypes'
import { IStoreState } from '../types'

interface IAction {
  type: string
  payload: IStoreState['config']
}

const initialState: IStoreState['config'] = {
  theme: 'light',
  menuOpen: false,
}

export const configReducer: React.Reducer<IStoreState['config'], IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
