export const colors = {
  body: '#171C34',
  black: '#262626',
  white: '#ffffff',
  primary: {
    50: '#F2F4FF',
    100: '#CFD6FF',
    500: '#3b54ec',
    600: '#3b54ec',
  },
  gray: {
    50: '#F7F8F9',
    100: '#F2F3F5',
    200: '#ECEDEF',
    300: '#E0E3E6',
    400: '#CCD0D5',
    500: '#99A1AC',
    600: '#808A97',
    700: '#666E79',
    800: '#4D535B',
    900: '#33373C',
  },
  success: {
    50: '#F0FFF5',
    100: '#C6F7D5',
    200: '#9AE6B4',
    500: '#38A169',
    600: '#2F855A',
  },
  critical: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    500: '#E53E3E',
    600: '#C53030',
  },
  warning: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    500: '#DD6B20',
    600: '#C05621',
  },
  info: {
    50: '#EBF8FF',
    100: '#BEE3F8',
    200: '#90CDF4',
    500: '#3182CE',
    600: '#2B6CB0',
  },
  secondary: {
    dodgerBlue: '#23C9FB',
    mirage: '#171C34',
    ebony: '#0C1326',
  },
}

export type ColorProps = typeof colors
