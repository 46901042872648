import React, { useContext } from 'react'

import { ProductsListProduct } from '..'
import { ErrorBox, Pagination } from 'src/core/components'

import { ProductsPageContext } from 'src/applets/product/screens/Products.screen'

import { paginate } from 'src/core/helpers/filters.helper'
import { Box, StackDivider, VStack } from '@chakra-ui/layout'

export const ProductsList: React.FC = () => {
  const {
    filteredProducts: products,
    filters,
    setPage,
  } = useContext(ProductsPageContext)

  const paginatedProducts = React.useMemo(() => {
    return products ? paginate(products, filters.page, filters.per_page) : []
  }, [filters.page, filters.per_page, products])

  return (
    <>
      {!paginatedProducts.length ? (
        <ErrorBox summary="No products found" my={50} />
      ) : (
        <>
          <VStack divider={<StackDivider borderColor="gray.100" />} spacing={3}>
            {paginatedProducts.map((product, index) => (
              <Box key={index} w="100%">
                <ProductsListProduct
                  product={product}
                  isLast={index === paginatedProducts.length - 1}
                />
              </Box>
            ))}
          </VStack>

          <Pagination
            itemsPerPage={filters.per_page}
            totalItems={products?.length}
            currentPage={filters.page}
            onPageChange={setPage}
            my={5}
          />
        </>
      )}
    </>
  )
}
