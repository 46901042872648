import Product from './screens/Product.screen'
import Products from './screens/Products.screen'

import { IRoute } from 'src/bootstrap/routes'

export const productRoutes: IRoute[] = [
  {
    id: 'products',
    path: '/products',
    component: Products,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'product',
    path: '/products/:id/:tab',
    component: Product,
    requiresAuth: true,
    exact: true,
  },
]

export default productRoutes
