import React, { FC } from 'react'
import { Avatar } from '@chakra-ui/avatar'
import { Flex, Text } from '@chakra-ui/layout'
import { useTheme } from '@chakra-ui/system'
import { useSelector } from 'react-redux'
import { IStoreState } from 'src/bootstrap/store/types'
import { coreLang } from 'src/core/lang'

export const UserInfoCard: FC = () => {
  const { user } = useSelector((state: IStoreState) => ({
    user: state.user,
    salesOfficer: state.salesOfficer,
  }))
  const { sizes } = useTheme()

  return (
    <>
      <Flex
        flexDirection="column"
        px={sizes.gutter.md}
        py="20px"
        alignItems="center"
      >
        <Avatar name={user.name.replaceAll(/[^a-zA-Z0-9\s]+/g, '')} mb="10px" />
        <Text fontSize="lg" fontWeight="semibold">
          {user.name}
        </Text>
        <Text fontSize="md" color="gray.600">
          {coreLang[user.role]}
        </Text>
      </Flex>
    </>
  )
}
