import React, { FC } from 'react'

import { Flex, Spinner } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { IStoreState } from 'src/bootstrap/store/types'
import { Header, Layout, Panel, ErrorBox, PanelBody } from 'src/core/components'
import {
  LedgerBalanceCard,
  LedgerHead,
  LedgerLogs,
  LedgerSubhead,
  useLedger,
} from 'src/applets/ledger'

const Balance: FC = () => {
  const history = useHistory()
  const { user } = useSelector((state: IStoreState) => ({
    user: state.user,
    salesOfficer: state.salesOfficer,
  }))

  const ledgerPayload = {
    head: LedgerHead.SalesOfficer,
    subhead: LedgerSubhead.Wallet,
    owner: user?._id,
  }

  const [ledger, isLoading] = useLedger(ledgerPayload)

  return (
    <>
      <Layout>
        <Header title="Balance" onBack={history.goBack} />

        <Panel borderTop={0}>
          {isLoading && (
            <Flex justifyContent="center" py={50}>
              <Spinner color="primary.600" />
            </Flex>
          )}

          {!isLoading && !ledger ? (
            <ErrorBox summary={`No logs found`} my={50} />
          ) : null}

          {!isLoading && ledger ? (
            <>
              <PanelBody mb="10px">
                <LedgerBalanceCard ledger={ledger} showTransferDetails={true} />
              </PanelBody>
              <LedgerLogs ledger={ledger} />
            </>
          ) : null}
        </Panel>
      </Layout>
    </>
  )
}

export default Balance
