import React from 'react'

import { Flex, Text } from '@chakra-ui/react'
import classNames from 'classnames/bind'
import { Card } from 'react-bootstrap'

import { Pagination, ErrorBox, Panel, PanelBody } from 'src/core/components'
import { paginate, formatCurrency } from 'src/core/helpers'

import styles from './OrderSummary.module.scss'

const cn = classNames.bind(styles)

interface IProps {
  items: any
  grandTotal: number
  params: any
}

export const OrderSummaryList: React.FC<IProps> = (props) => {
  const { items, params, grandTotal } = props

  const PER_PAGE = items?.length
  const [page, setPage] = React.useState<number>(1)
  const paginatedItems = React.useMemo(() => {
    return items ? paginate(items, page, PER_PAGE) : []
  }, [page, items, PER_PAGE])

  return (
    <>
      {!paginatedItems.length ? (
        <Panel>
          <ErrorBox summary="Set filters to fetch order summary" my={50} />
        </Panel>
      ) : null}

      {paginatedItems.length ? (
        <>
          <Panel>
            {paginatedItems.map((item, index) => {
              return item.type === 'item' ? (
                <React.Fragment key={index}>
                  {/* <td>{index + 1 + (page - 1) * PER_PAGE}</td> */}
                  {item.first_row && (
                    <Card.Header className={cn('distributor', 'odd')}>
                      <span className={cn('font-weight-medium')}>
                        {item[params.group_by].name}
                      </span>
                    </Card.Header>
                  )}

                  <div className={cn('item')}>
                    <Text fontWeight="medium">{item.product.name}</Text>

                    <Text fontSize="sm" color="gray.600">
                      {Math.floor(item.quantity / item.product.case_count)}{' '}
                      Cases, {item.quantity % item.product.case_count} Units
                    </Text>

                    <Text mt={2}>NGN {formatCurrency(item.amount)}</Text>
                  </div>
                </React.Fragment>
              ) : (
                <Flex
                  key={index}
                  justifyContent="space-between"
                  className={cn('item', 'last')}
                >
                  <Text className={cn('subtotal-label')}>
                    {item[params.group_by].name} Total
                  </Text>

                  <Text className={cn('subtotal-amount')}>
                    NGN {formatCurrency(item[params.group_by].total)}
                  </Text>
                </Flex>
              )
            })}
          </Panel>

          <Panel my={2}>
            <PanelBody as={Flex} justifyContent="space-between">
              <Text fontWeight="medium">Grand Total</Text>
              <Text fontWeight="medium" textAlign="right">
                NGN {formatCurrency(grandTotal)}
              </Text>
            </PanelBody>
          </Panel>

          <Pagination
            my={5}
            totalPages={items?.length / PER_PAGE}
            currentPage={page}
            onPageChange={setPage}
          />
        </>
      ) : null}
    </>
  )
}
