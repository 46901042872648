import React from 'react'

import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import RouteSwitcher, { SuspenseLoader } from './RouteSwitcher'
import { ErrorBox } from '../core/components/ErrorBox'
import useAuth from '../applets/user/hooks/useAuth'
import useOnline from '../core/hooks/useOnline'
import { IStoreState } from './store/types'

import '../core/assets/sass/app.scss'
import { IRoute } from './routes'

interface AppProps {
  routes: IRoute[]
}
const App: React.FC<AppProps> = (props) => {
  const isOnline = useOnline()
  useAuth(isOnline)

  const { isLoading } = useSelector((state: IStoreState) => ({
    user: state.user,
    isLoading: state.global.isLoading,
    isAuthenticated: !!state.auth.token,
  }))

  return (
    <BrowserRouter>
      <div id="app">
        {!isOnline ? (
          <ErrorBox type="network" my={150} />
        ) : (
          <main className="page-wrapper">
            {isLoading ? (
              <SuspenseLoader />
            ) : (
              <RouteSwitcher routes={props.routes} />
            )}
          </main>
        )}
      </div>
    </BrowserRouter>
  )
}

export default App
