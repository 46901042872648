import React from 'react'

import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import cn from 'classnames'
import { Card } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'

import RoutePlanForm from './RoutePlanForm'
import { ErrorBox } from '../../../core/components'

import useMounted from 'src/core/hooks/useMounted'

import { RoutePlanService } from '../routePlan.service'
import { IBuyer } from 'src/applets/buyer/buyer.type'
import { IRoutePlan } from 'src/applets/routePlan/routePlan.type'
import { renderDayText } from 'src/core/helpers/routePlan.helper'

const routePlanService = new RoutePlanService()

interface IProps {
  buyer: IBuyer
}

export const RoutePlans: React.FC<IProps> = ({ buyer }) => {
  const isMounted = useMounted()

  const [routePlans, setRoutePlans] = React.useState<IRoutePlan[]>([])
  const [showCreate, setShowCreate] = React.useState<boolean>(false)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(true)

  /** We'll need this ti trigger updates from the form */
  const [lastUpdated, setLastUpdated] = React.useState<Date>()

  /**
   * Fetch route plans for buyer
   */
  React.useEffect(() => {
    setIsUpdating(true)

    const fetchRoutePlans = (): void => {
      routePlanService
        .fetchByBuyer(buyer._id)
        .then((routePlans) => {
          isMounted.current &&
            setRoutePlans(
              routePlans.sort((a, b) =>
                a.day < b.day ? -1 : a.day > b.day ? 1 : 0
              )
            )
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false)
            setIsUpdating(false)
          }
        })
    }

    fetchRoutePlans()
  }, [isMounted, buyer._id, lastUpdated])

  return (
    <Card.Body
      className={cn({
        'px-0': true,
        'py-0': true,
        disabled: !isLoading && isUpdating,
      })}
    >
      {!isLoading && (!routePlans || !routePlans.length) ? (
        <Box mt={5} mb={1}>
          <ErrorBox summary="No route plans found" />
        </Box>
      ) : null}

      {!isLoading && !showCreate && routePlans.length
        ? routePlans.map((routePlan: IRoutePlan, index) => (
            <React.Fragment key={routePlan._id}>
              <Box className="card-section py-3">
                <Box py={2}>
                  <Text as="span" variant="tinyTitle">
                    Plan #{index + 1}
                  </Text>
                  <Text as="span" variant="tinyTitle" color="gray.600">
                    {' '}
                    Every {renderDayText(String(routePlan.day))}
                  </Text>
                </Box>
                <RoutePlanForm
                  type="update"
                  buyer={buyer}
                  routePlan={routePlan}
                  routePlans={routePlans}
                  onUpdate={() => setLastUpdated(new Date())}
                />
              </Box>
            </React.Fragment>
          ))
        : null}

      {/* Add new route plan */}
      {!isLoading && showCreate ? (
        <>
          <Box bg="gray.50" textAlign="center" py={2}>
            <Text variant="tinyTitle">Create Route Plan</Text>
          </Box>
          <div className="card-section">
            <RoutePlanForm
              buyer={buyer}
              routePlans={routePlans}
              onUpdate={() => {
                setLastUpdated(new Date())
                setShowCreate(false)
              }}
            />
            <Button
              variant="outline-light"
              className="mt-2"
              onClick={() => setShowCreate(false)}
              block
            >
              Cancel
            </Button>
          </div>
        </>
      ) : !isLoading && !showCreate && routePlans.length < 5 ? (
        <Box mt={2} className="card-section">
          <Button
            variant="outline"
            leftIcon={<FiPlus size={16} />}
            onClick={() => setShowCreate(true)}
            width="100%"
          >
            Add Route Plan
          </Button>
        </Box>
      ) : null}

      {isLoading && (
        <Flex justifyContent="center" py={50}>
          <Spinner color="primary.600" />
        </Flex>
      )}
    </Card.Body>
  )
}

export default RoutePlans
