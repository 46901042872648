import CoreService, { CoreServiceProps } from '../../core/services/core.service'
import { IRoutePlan } from './routePlan.type'

export class RoutePlanService extends CoreService<IRoutePlan> {
  constructor() {
    super('route_plan')
  }

  fetchByBuyer(buyerId: string): Promise<IRoutePlan[]> {
    const formData = new FormData()
    formData.append('buyer_id', buyerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `route_plans_${buyerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`route_plan/read_by_buyer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchBySalesOfficer(salesOfficerId: string): Promise<IRoutePlan[]> {
    const formData = new FormData()
    formData.append('sales_officer_id', salesOfficerId)

    return new Promise((resolve, reject) => {
      const cacheKey = `route_plans_${salesOfficerId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post(`route_plan/read_by_sales_officer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                this.cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export const routePlanService = new RoutePlanService()
export type RoutePlanServiceProps = CoreServiceProps | keyof RoutePlanService
