import React from 'react'

import { useSelector } from 'react-redux'

import { BuyersList } from 'src/applets/buyer/components/BuyersList'
import DashboardHeader from 'src/applets/dashboard/components/DashboardHeader'
import SalesOfficerDashboard from 'src/applets/dashboard/components/SalesOfficerDashboard'
import BrandAmbassadorDashboard from '../components/BrandAmbassadorDashboard'
import Layout from 'src/core/components/Layout/Layout'

import { Role } from 'src/bootstrap/permissions/roles'

import { IStoreState } from 'src/bootstrap/store/types'

const Dashboard: React.FC = () => {
  const user = useSelector((state: IStoreState) => state.user)

  return (
    <Layout>
      {user.role !== Role.SellerRep ? (
        user.role !== Role.SellerBA ? (
          <>
            <DashboardHeader user={user} />
            <BuyersList routeId="dashboard" />
          </>
        ) : (
          <BrandAmbassadorDashboard />
        )
      ) : (
        <SalesOfficerDashboard />
      )}
    </Layout>
  )
}

export default Dashboard
