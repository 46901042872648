import React from 'react'

import useMounted from 'src/core/hooks/useMounted'
import { Role } from 'src/bootstrap/permissions/roles'

import { AgentService } from 'src/applets/agent/agent.service'
import { BuyerService } from 'src/applets/buyer/buyer.service'
import { RoutePlanService } from 'src/applets/routePlan/routePlan.service'
import { IBuyer } from 'src/applets/buyer/buyer.type'

const agentService = new AgentService()
const buyerService = new BuyerService()
const routePlanService = new RoutePlanService()

const useFetchBuyers = (user): [IBuyer[], IBuyer[], boolean] => {
  const isMounted = useMounted()

  const [loading, setLoading] = React.useState<boolean>(true)
  const [buyers, setBuyers] = React.useState<IBuyer[]>()
  const [myBuyers, setMyBuyers] = React.useState<IBuyer[]>()

  /**
   * Fetch all buyers & my buyers for sales reps
   */
  React.useEffect(() => {
    if (user.role === Role.Agent) {
      agentService
        .fetchReferralsByAgent(user._id)
        .then((referrals) => {
          if (referrals.length) {
            const buyers = []
            referrals.forEach(({ buyer }) => {
              buyer && buyers.push(buyer[0])
            })
            isMounted.current && setBuyers(buyers)
          }
        })
        .finally(() => isMounted.current && setLoading(false))
    } else {
      buyerService
        .fetch()
        .then((buyers) => {
          isMounted.current && setBuyers(buyers)
        })
        .finally(() => isMounted.current && setLoading(false))
    }

    if (user.role === Role.SellerManager || user.role === Role.SellerBA) {
      routePlanService.fetchBySalesOfficer(user._id).then((routePlans) => {
        // let buyers = routePlans.map((routePlan) => {
        //   return { ...routePlan.buyer, route_plan: routePlan }
        // })
        // buyers = buyers.reduce((unique, buyer) => {
        //   if (!unique.some((item) => item._id === buyer._id)) {
        //     unique.push(buyer)
        //   }
        //   return unique
        // }, [])
        const buyers = {}

        routePlans.forEach(({ buyer, ...routePlan }) => {
          if (!buyers[buyer._id]) {
            buyers[buyer._id] = {
              ...buyer,
              route_plan_days: [routePlan.day],
            }
          } else {
            buyers[buyer._id].route_plan_days.push(routePlan.day)
          }
        })

        isMounted.current && setMyBuyers(Object.values(buyers))
      })
    }
  }, [user, isMounted])

  return [buyers, myBuyers, loading]
}

export default useFetchBuyers
