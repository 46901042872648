import React, { useState } from 'react'

import {
  Spinner,
  Tabs,
  TabList,
  Tab,
  Box,
  HStack,
  Flex,
  VStack,
  StackDivider,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import BuyersListBuyer from './BuyersListBuyer'
import {
  ErrorBox,
  SearchBar,
  Pagination,
  Panel,
  PanelHeader,
  PanelBody,
  FloatingButton,
} from 'src/core/components'

import usePermission from 'src/core/hooks/usePermission'
import useFilterBuyers from 'src/applets/buyer/hooks/useFilterBuyers'
import useFetchBuyers from 'src/applets/buyer/hooks/useFetchBuyers'

import { IUser } from 'src/applets/user/types/user.type'
import { IStoreState } from 'src/bootstrap/store/types'

import { paginate } from 'src/core/helpers/filters.helper'
import { route } from 'src/core/helpers/route.helper'

const days = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
const dayOfWeek = new Date().getDay()

enum TabType {
  Buyers,
  MyBuyers,
}

interface IProps {
  routeId?: 'dashboard' | 'buyers'
}
export const BuyersList: React.FC<IProps> = ({ routeId }) => {
  const { userCan } = usePermission()
  const user: IUser = useSelector((state: IStoreState) => state.user)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [buyers, myBuyers, isLoading] = useFetchBuyers(user)

  const { filters, filteredBuyers, setFilters, setPage } = useFilterBuyers(
    tabIndex === TabType.Buyers ? buyers : myBuyers,
    routeId || 'buyers'
  )

  const paginatedBuyers = React.useMemo(() => {
    return filteredBuyers ? paginate(filteredBuyers, filters.page, 50) : []
  }, [filters.page, filteredBuyers])

  return userCan('view_buyers') ? (
    <Panel borderTop={0}>
      <PanelHeader pb="1px">
        <Tabs
          defaultIndex={tabIndex}
          onChange={(tabIndex) => {
            setFilters({ search: '', route_plan_day: 0 })
            setTabIndex(tabIndex)
          }}
        >
          <TabList border={0}>
            <Tab>{`All Retailers`}</Tab>
            {!!myBuyers?.length && (
              <Tab>{`My Retailers (${myBuyers?.length || '0'})`}</Tab>
            )}
          </TabList>
        </Tabs>

        {userCan('signup_buyer') && (
          <Link to={route('buyer_signup')}>
            <FloatingButton
              bottom="80px"
              colorScheme="success"
              right="24px"
              aria-label="Signup retailer"
              label="Signup retailer"
            />
          </Link>
        )}
      </PanelHeader>

      {tabIndex === TabType.MyBuyers && (
        <PanelHeader>
          <HStack my={1} spacing="5px" overflow="auto">
            {[0, 1, 2, 3, 4, 5, 6].map((day: any, index) => {
              const isActive = filters.route_plan_day === day

              return (
                <Box
                  key={index}
                  bg={isActive ? 'primary.50' : 'gray.50'}
                  border="1px solid"
                  borderRadius={99999}
                  borderColor={isActive ? 'primary.100' : 'gray.100'}
                  color={isActive ? 'primary.600' : ''}
                  fontSize="sm"
                  cursor="default"
                  padding="2px 8px"
                  onClick={() =>
                    setFilters({
                      route_plan_day: day,
                    })
                  }
                >
                  {day === 0
                    ? 'All Days'
                    : dayOfWeek === day
                    ? 'Today'
                    : days[day - 1]}
                </Box>
              )
            })}
          </HStack>
        </PanelHeader>
      )}

      <PanelHeader>
        <SearchBar
          placeholder="Search retailers"
          value={filters.search || ''}
          onChange={(search) => setFilters({ ...filters, search })}
          isDisabled={isLoading || !filteredBuyers}
        />
      </PanelHeader>

      <PanelBody pt={0} pb={10} px={0}>
        {isLoading && (
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        )}

        {!isLoading && !paginatedBuyers?.length ? (
          <ErrorBox summary="No retailers found" my={50} />
        ) : null}

        {!isLoading && paginatedBuyers?.length ? (
          <>
            <VStack
              divider={<StackDivider borderColor="gray.100" />}
              spacing={0}
            >
              {paginatedBuyers.map((buyer, index) => (
                <Box key={buyer._id} width="100%">
                  <BuyersListBuyer
                    buyer={buyer}
                    isLast={index === paginatedBuyers.length - 1}
                  />
                </Box>
              ))}
            </VStack>

            <Pagination
              itemsPerPage={50}
              totalItems={filteredBuyers.length}
              currentPage={filters.page}
              onPageChange={(page) => setPage(page)}
              my={5}
            />
          </>
        ) : null}
      </PanelBody>
    </Panel>
  ) : (
    <ErrorBox type="authorization" my={50} />
  )
}

export default BuyersList
