import { Role } from 'src/bootstrap/permissions'

export const coreLang = {
  [Role.Admin]: 'Suplias Admin',
  [Role.Staff]: 'Suplias Account Manager',
  [Role.WarehouseOfficer]: 'Warehouse Officer',
  [Role.SellerManager]: 'Distributor Manager',
  [Role.SellerRetailManager]: 'Retail Manager',
  [Role.SellerRep]: 'Delivery Associate',
  [Role.SellerBA]: 'Brand Ambassador',
  [Role.SellerCS]: 'Customer Support',
}
