import { IModel } from 'src/core/types'

export enum LedgerHead {
  Buyer = 'buyer',
  SalesOfficer = 'sales_officer',
}

export enum LedgerSubhead {
  Wallet = 'wallet',
}

export enum LedgerLogTag {
  Cash = 'cash',
  Transfer = 'transfer',
  Order = 'order',
  Signup = 'signup',
}

export interface ILedger extends IModel {
  head: LedgerHead
  subhead: LedgerSubhead
  owner: string
  balance: number
}

export interface ILedgerTag extends IModel {
  parent_id: string
  parent_type: string
  tag: string
  value: string
}

export interface ILedgerTagsDict {
  external_reference?: ILedgerTag
  virtual_account?: ILedgerTag
}

export interface ILedgerLog extends IModel {
  readonly value: number
  readonly description: string
  readonly tag: LedgerLogTag
  readonly ledger: ILedger
}
