import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { useTheme, Button, Text, Avatar } from '@chakra-ui/react'
import { FiMinimize2, FiMaximize2, FiCreditCard } from 'react-icons/fi'

import { formatCurrency, route } from 'src/core/helpers'
import { IUser } from 'src/applets/user'

import { VirtualAccountDetails } from './VirtualAccountDetails'
import { useLedger } from '../hooks/useLedger'
import { ILedger, LedgerHead, LedgerSubhead } from '../ledger.type'
import { useHistory } from 'react-router'

export interface LedgerBalanceCardProps {
  user?: IUser
  ledger?: ILedger
  showTransferDetails?: boolean
  disableClick?: boolean
}
export const LedgerBalanceCard: React.FC<LedgerBalanceCardProps> = (props) => {
  const history = useHistory()
  const { sizes } = useTheme()

  const ledgerPayload = {
    head: LedgerHead.SalesOfficer,
    subhead: LedgerSubhead.Wallet,
    owner: props?.user?._id,
  }
  const [ledger, isLoading, , setLedger] = useLedger(
    ledgerPayload,
    !props?.ledger?._id
  )

  const [balance, setBalance] = React.useState<number>(0)
  const [showDetails, setShowDetails] = React.useState<boolean>(
    props.showTransferDetails
  )

  // set ledger
  React.useEffect(() => {
    setLedger(props?.ledger)
  }, [props?.ledger])

  // set balance on ledger load
  React.useEffect(() => {
    setBalance(ledger?.balance || 0)
  }, [ledger])

  const colorCode = (value): string => {
    return value < 0 ? 'critical.600' : value > 0 ? 'success.600' : 'body'
  }

  return (
    <Box
      p={0}
      bg="#fff"
      borderRadius="8px"
      border="1px solid #eef4fe"
      filter="drop-shadow(4px 4px 0px rgba(35, 203, 253, 0.1))"
    >
      <Flex p={sizes.gutter.md}>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            !props?.disableClick && history.push(route('balance'))
          }}
        >
          <Flex alignItems="center">
            <Avatar
              size="sm"
              icon={<FiCreditCard color="#3b54ec" />}
              bgColor="#E9FAFF"
            />
            <Text ml={sizes.gutter.sm} fontSize="md" fontWeight="medium">
              Balance
            </Text>
          </Flex>

          <Text
            fontSize="lg"
            fontWeight="medium"
            color={colorCode(balance)}
            textAlign="right"
          >
            {isLoading ? '...' : formatCurrency(ledger?.balance || 0)}
          </Text>
        </Flex>
      </Flex>

      <Box
        bg="#F1FCFF"
        borderRadius="8px"
        overflowWrap="break-word"
        overflow="hidden"
      >
        <Box
          bg="transparent"
          p={showDetails ? '16px' : '0'}
          height={showDetails ? 'auto' : '0'}
          overflow="hidden"
        >
          <VirtualAccountDetails
            parentId={ledger?.owner}
            parentType={LedgerHead.SalesOfficer}
          />
        </Box>

        <Button
          bg="transparent"
          variant="ghost"
          alignItems="center"
          color="primary.600"
          _hover={{ bg: 'transparent' }}
          _focus={{ outline: 'none', boxShadow: 'none' }}
          leftIcon={showDetails ? <FiMinimize2 /> : <FiMaximize2 />}
          onClick={() => setShowDetails(!showDetails)}
          isFullWidth
          isDisabled={!ledger && isLoading}
        >
          {showDetails ? 'Hide' : 'Show'} Transfer Details
        </Button>
      </Box>
    </Box>
  )
}
