import React from 'react'

import { Box, Flex, HStack, StackDivider } from '@chakra-ui/layout'
import { Select, Spinner } from '@chakra-ui/react'
import { addDays, getUnixTime } from 'date-fns'
import { useSelector } from 'react-redux'

import { IStoreState } from 'src/bootstrap/store/types'
import { Panel, PanelBody } from 'src/core/components'
import { useFetch } from 'src/core/hooks'

import { OrderSummaryList } from './OrderSummaryList'
import { orderGroupService, OrderGroupServiceProps } from '../../services'

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
const dayOfWeek = new Date().getDay()
const presetTimestamps = []

days.forEach((label, index) => {
  const today = new Date()
  const diff = index - dayOfWeek
  const from_diff = index === 1 ? diff - 2 : diff - 1
  const to_diff = index === 1 ? diff : diff

  const from = getUnixTime(addDays(today.setHours(0, 0, 0, 0), from_diff))
  const to = getUnixTime(addDays(today.setHours(0, 0, 0, 0), to_diff))

  presetTimestamps.push({ label, from, to })
})

export const OrderSummary: React.FC = () => {
  const { user } = useSelector((state: IStoreState) => ({ user: state.user }))

  const [params, setParams] = React.useState<{
    group_by: 'distributor' | 'buyer'
    seller: { value: string }
    sales_officer: { value: string }
    from_timestamp: number
    to_timestamp: number
  }>({
    group_by: 'distributor',
    seller: { value: user?.seller_id },
    sales_officer: { value: user?._id },
    from_timestamp: presetTimestamps[dayOfWeek].from,
    to_timestamp: presetTimestamps[dayOfWeek].to,
  })

  const [po, isLoading, , setPo] = useFetch<any, OrderGroupServiceProps>(
    orderGroupService,
    'fetchPO',
    [params],
    !!params
  )

  const items = React.useMemo(() => {
    const groupBy = params?.group_by || 'distributor'
    let data = po?.items || []
    let refined = []

    if (data?.length) {
      data = data.map((item) => {
        item.type = 'item'
        item[groupBy] = { _id: '*', ...po?.[`${groupBy}s`][item[groupBy]] }
        return item
      })

      const dictionary = {}

      data.forEach((item) => {
        if (dictionary[item[groupBy]._id]) {
          dictionary[item[groupBy]._id].push(item)
        } else {
          dictionary[item[groupBy]._id] = [{ ...item, first_row: true }]
        }
      })

      Object.entries(dictionary).forEach(([id, itemsArray]: any[]) => {
        refined = [
          ...refined,
          ...itemsArray,
          { type: 'subtotal', [groupBy]: po?.[`${groupBy}s`][id] },
        ]
      })
    }

    return refined
  }, [po, params?.group_by])

  return (
    <>
      <Panel border={0}>
        <PanelBody>
          <HStack
            alignItems="center"
            justifyContent="flex-start"
            divider={
              <StackDivider
                h="24px"
                alignSelf="center"
                borderColor="gray.100"
              />
            }
            spacing={5}
          >
            <Select
              size="sm"
              as="select"
              value={params.group_by}
              border={0}
              width="auto"
              _focus={{ boxShadow: 'none' }}
              onChange={({ target }: any) => {
                setPo(null)
                setParams({ ...params, group_by: target.value })
              }}
            >
              <option value="distributor">Distributors</option>
              <option value="buyer">Retailers</option>
            </Select>

            <HStack spacing={3} overflow="auto">
              {presetTimestamps.map((day: any, index) => {
                const isActive =
                  params.from_timestamp === day.from &&
                  params.to_timestamp === day.to

                return (
                  <Box
                    key={index}
                    bg={isActive ? 'primary.50' : 'gray.50'}
                    border="1px solid"
                    borderRadius={99999}
                    borderColor={isActive ? 'primary.100' : 'gray.100'}
                    color={isActive ? 'primary.600' : ''}
                    cursor="default"
                    fontSize="sm"
                    padding="2px 8px"
                    onClick={() =>
                      setParams({
                        ...params,
                        from_timestamp: day.from,
                        to_timestamp: day.to,
                      })
                    }
                  >
                    {dayOfWeek === index ? 'Today' : day.label}
                  </Box>
                )
              })}
            </HStack>
          </HStack>
        </PanelBody>
      </Panel>

      {isLoading ? (
        <Panel>
          <Flex justifyContent="center" py={50}>
            <Spinner color="primary.600" />
          </Flex>
        </Panel>
      ) : (
        <OrderSummaryList
          items={items}
          grandTotal={po?.grand_total}
          params={params}
        />
      )}
    </>
  )
}
